<template>
  <div style="background-color: #f2f2f2;width: 100vw;height: 100vh">
    <div style="text-align: center;padding-top: 1vh;font-size: 14px;font-weight: 600;">付款</div>
    <div style="margin-top: 6vh;margin-left: 10vw;display: flex;justify-content: space-between;align-items: center;">
      <div style="display: flex;flex-direction: column;align-items: center">
        <div>
          <img src="../../assets/shop.png" style="width: 10vw;">
          <span style="font-weight: 600;font-size: 16px;margin-left: 1vw;">付款给商家</span>
        </div>
        <span style="font-size: 12px;color: #c9c9c9;margin-top: 1vh;">{{ `商家：(${mchName.split("-")[0]})` }}</span>
      </div>
      <div style="margin-right: 10vw">
        <v-image
            width="55"
            :round="true"
            height="55"
            src="https://img1.baidu.com/it/u=534429813,2995452219&fm=253&fmt=auto?w=800&h=800"
        />
      </div>
    </div>
    <div
        style="background-color: #ffffff;border-top-left-radius: 15px;border-top-right-radius: 15px;margin-top: 5vh;height: 45vh">
      <div style="padding-left: 15vw;padding-top: 5vh;display: flex;flex-direction: column;">
        <span style="font-size: 14px;font-weight: 600;">金额</span>
        <div style="margin-top: 2vh;margin-left: -2vw;display: flex;height: 6.3vh;">
          <span style="font-size: 50px;font-weight: 800">￥</span>
          <span style="font-size: 43px;font-weight: 500">{{ price }}</span>
          <div id="fk" class="blink" style="border-left: 1px solid #04c15f;margin-left: 1vw;display: block;"></div>
        </div>
        <div style="border-bottom: 1px solid #efeff5;height: 1px;width: 70vw;margin-top: 1vh;"></div>
        <span style="font-size: 12px;margin-top: 3vh;color: #2b2b2b">添加备注</span>
      </div>
    </div>

    <NumberKeyboard
        v-if="!this.u"
        :show="jpShow"
        theme="custom"
        extra-key="."
        v-model="price"
        close-button-text="完成"
        @close="pay"
    />

  </div>
</template>

<script>

import {aliPayCode} from "@/api/user";
import {Toast, NumberKeyboard, Image} from 'vant';
import {getMchName} from "@/api/mch";
import {alipay, alipay2, wxpay} from "@/api/order";
import {debounce} from '@/utils/common.js'

export default {
  name: "pay",
  components: {
    NumberKeyboard,
    "v-image": Image
  },
  data() {
    return {
      price: '',
      code: '',
      jpShow: true,
      loading: undefined,
      mchName: '***',
      sk: '',
      dialogVisible: false,
      customKey: ['.'],
      u: ''
    }
  },
  async created() {
    let {code, sk, orderNo, notify, price} = this.$route.query;
    this.sk = sk
    this.code = code
    this.u = notify;
    const {userAgent} = window.navigator;
    if (!!/MicroMessenger/i.test(userAgent)) {
      if (notify) {
        let param = {
          content: "订单支付",
          mchNo: sk,
          price: price,
          notify: notify,
          orderNo: orderNo,
          tenantId: 1,
          openId: code
        }
        wxpay(param).then(res => {
          WeixinJSBridge.invoke(
              "getBrandWCPayRequest",
              {
                appId: res.data.appId, //公众号ID，由商户传入
                timeStamp: res.data.timeStamp, //时间戳，自1970年以来的秒数
                nonceStr: res.data.nonceStr, //随机串
                package: res.data.package,
                signType: 'RSA', //微信签名方式：
                paySign: res.data.paySign //微信签名
              },
              function (res) {
                if (res.err_msg == "get_brand_wcpay_request:ok") {

                } else {

                }
              }
          )
        })
      } else {
        getMchName(this.sk, "wx").then(res => {
          if (res.data == null) {
            Toast.fail({
              message: '无可用的商户',
              duration: 1000
            });
          } else {
            this.mchName = res.data
          }
        })
        if (!code) {
          if (this.mchName.split("-")[1] == 3 || this.mchName.split("-")[1] == 5) {
            location.href =
                "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx10fcb6383a94ca54&redirect_uri=" +
                encodeURIComponent(
                    "https://hss.shizhipay.com/v1/v1/mp?sk=" +
                    sk
                ) +
                "&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect";
            return;
          } else {
            location.href =
                "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx9fe9ffac2fc239ec&redirect_uri=" +
                encodeURIComponent(
                    "https://hss.shizhipay.com/v1/v1/mp3?sk=" +
                    sk
                ) +
                "&response_type=code&scope=snsapi_base&state=STATE&connect_redirect=1#wechat_redirect";
            return;
          }

        } else {
          this.code = code;
          getMchName(this.sk, "wx").then(res => {
            if (res.data == null) {
              Toast.fail({
                message: '无可用的商户',
                duration: 1000
              });
            } else {
              this.mchName = res.data
            }
          })
        }
      }
    } else {
      getMchName(this.sk, "alipay").then(res => {
        if (res.data == null) {
          Toast.fail({
            message: '无可用的商户',
            duration: 1000
          });
        } else {
          this.mchName = res.data
        }
      })
    }
  },
  mounted() {
    if (this.$route.query.sk) {
      this.sk = this.$route.query.sk
    }
  },
  methods: {
    ready(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener('AlipayJSBridgeReady', callback, false);
      }
    },
    tradePay(tradeNO) {
      this.ready(function () {
        // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
        AlipayJSBridge.call("tradePay", {
          tradeNO: tradeNO
        }, function (data) {
          if ("9000" == data.resultCode) {
            Toast.loading({
              message: '支付成功',
              duration: 0,
              forbidClick: true,
            });
          }
        });
      });
    },
    radom() {
      let data = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      let result = "";
      for (var i = 0; i < 20; i++) {   //产生20位就使i<20
        let r = Math.floor(Math.random() * 9);     //16为数组里面数据的数量，目的是以此当下标取数组data里的值！
        result += data[r];        //输出20次随机数的同时，让rrr加20次，就是20位的随机字符串了。
      }
      return result;
    },
    pay: debounce(function () {
      {
        let _this = this;
        if (_this.sk) {
          if (_this.price > 0.1) {
            let param = {
              content: "订单支付",
              mchNo: _this.sk,
              price: _this.price,
              notify: "www.baidu.com",
              orderNo: _this.radom(),
              tenantId: 1,
              openId: this.code
            }
            const {userAgent} = window.navigator;
            if (!!/MicroMessenger/i.test(userAgent)) {
              param.price = param.price
              wxpay(param).then(res => {
                if (res.data.msg) {
                  Toast.fail({
                    message: res.data.msg,
                    duration: 2000
                  });
                } else {
                  WeixinJSBridge.invoke(
                      "getBrandWCPayRequest",
                      {
                        appId: res.data.appId, //公众号ID，由商户传入
                        timeStamp: res.data.timeStamp, //时间戳，自1970年以来的秒数
                        nonceStr: res.data.nonceStr, //随机串
                        package: res.data.package,
                        signType: 'RSA', //微信签名方式：
                        paySign: res.data.paySign //微信签名
                      },
                      function (res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {

                        } else {

                        }
                      }
                  )
                }
              })
            } else if (!!/Alipay/i.test(userAgent)) {
              alipay2(param).then(res => {
                if (res.data == null) {
                  Toast.fail({
                    message: '无可用的商户',
                    duration: 1000
                  });
                } else if (!res.data.startsWith("http")) {
                  Toast.fail({
                    message: '无对应的子商户号',
                    duration: 1000
                  })
                } else if (res.data == '支付失败'){
                  Toast.fail({
                    message: '无对应的子商户号',
                    duration: 1000
                  })
                }else {
                  location.href = res.data
                }
              })
            }
          } else {
            Toast.loading({
              message: '支付金额不能低于0.1元',
              duration: 1000
            });
          }
        }
      }
    }, 1000)
  }
}
</script>

<style lang="scss">

.blink {
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>