import http from '@/libs/axios';

let pro = process.env.VUE_APP_BASE_API

/**
 * 查询商户列表
 * @param data
 * @returns {*}
 */
export function list(data){
    return http({
        url: `${pro}/v1/mch/list`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 新增商户
 * @param data
 * @returns {*}
 */
export function addMch(data){
    return http({
        url: `${pro}/v1/mch/addMch`,
        method: 'POST',
        requestBody: true,
        data
    })
}

/**
 * 商户登录
 * @param data
 * @returns {*}
 */
export function login(data){
    return http({
        url: `${pro}/v1/mch/login`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 进件接口
 * @param data
 */
export function addPiece(data){
    return http({
        url: `${pro}/v1/piece/add`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 进件列表
 * @param data
 */
export function pieceList(data){
    return http({
        url: `${pro}/v1/piece/list`,
        method: 'post',
        requestBody: true,
        data
    })
}

export function getMchName(userCode,channel){
    return http({
        url: `${pro}/v1/public/getMch/${userCode}?channel=${channel}`,
        method: 'get'
    })
}

export function batchHfAddPiece(data){
    return http({
        url: `${pro}/v1/piece/batchAddHfMch`,
        method: 'post',
        requestBody: true,
        data
    })
}

export function queryReportStatus(query){
    return http({
        url: `${pro}/v1/piece/queryReportStatus`,
        method: 'get',
        params: query
    })
}

export function separateAdd(query){
    return http({
        url: `${pro}/v1/separate/add`,
        method: 'get',
        params: query
    })
}
export function separateQuery(query){
    return http({
        url: `${pro}/v1/separate/query`,
        method: 'get',
        params: query
    })
}

/**
 * 查询费率模板列表
 * @param query
 * @returns {*}
 */
export function rateTempList(query){
    return http({
        url: `${pro}/v1/temp/list`,
        method: 'get',
        params: query
    })
}

/**
 * 新增费率模板
 * @param data
 * @returns {*}
 */
export function addRateTemplate(data){
    return http({
        url: `${pro}/v1/temp/add`,
        method: 'post',
        requestBody: true,
        data
    })
}