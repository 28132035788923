import http from '@/libs/axios';

let pro = process.env.VUE_APP_BASE_API

/**
 * 查询平台所有渠道
 * @returns {*}
 */
export function getTenantList(){
    return http({
        url: `${pro}/v1/channel/list`,
        method: 'get'
    })
}

/**
 * 代理商创建渠道
 * @param data
 * @returns {*}
 */
export function addAgentTenant(data){
    return http({
        url: `${pro}/v1/agentTenant/add`,
        method: 'POST',
        requestBody: true,
        data
    })
}

/**
 * 查询代理商创建的渠道列表
 * @returns {*}
 */
export function getAgentTenantList(id){
    return http({
        url: `${pro}/v1/agentTenant/list/${id}`,
        method: 'get'
    })
}

/**
 * 删除代理商创建的渠道
 * @param data
 * @returns {*}
 */
export function addAgentTenantDel(id){
    return http({
        url: `${pro}/v1/agentTenant/del/${id}`,
        method: 'POST'
    })
}
