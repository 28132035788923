<template>
  <div class="swipe-main">
    <div class="bg">
      <div :class=" {'header': true, 'scan-header': scanning == 1}">
        <div class="header-bg" >
          <img style="width:100%" src="https://image.shizhipay.com/images/511751134242.jpg">

        </div>
        <div class="header-title-font">
          {{title}}
        </div>
        <div style="position: absolute;bottom: -25px;width: 100%;">
          <el-select class="header-check-input2" filterable @change="handleSelect2"  v-loading="loading2" v-model="mchId" placeholder="请选择商户">
            <el-option
                v-for="item in restaurants2"
                :key="item.mid"
                size="mini"
                :label="item.mid"
                :value="item">
              <span style="float: left">商户名称：{{ item.mname }}</span>
            </el-option>
          </el-select>
          <el-input placeholder="请输入需要进件的汇付或汇聚商户号多个商户用,隔开"
                    @keyup.enter.native="getGoods()"
                    v-model="itemContent"
                    class="header-check-input"/>
        </div>
      </div>
      <div :class="{'check-body': true , 'scan-body': scanning == 1}">

        <div :class="{'check-button': true, 'scan-svm': scanning == 2, 'unchoose': scanning != 0}">
          <div class="check-button-group-unchoose" v-if="scanning == 0" @click="getGoods()">
            <div class="ripple">
              <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E7%BB%84%201.png"
                   alt="">
              <div class="ripple-b">

              </div>
              <div class="ripple-button-choose-font">
                <div>立即</div>
                <div>进件</div>
              </div>
            </div>
            <div class="inner-gray-radio">
              <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E5%9C%862.png"
                   alt="">
            </div>
            <div class="out-blue-radio">
              <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E5%9C%861.png"
                   alt="">
            </div>
          </div>
          <div class="check-button-group-checked" v-if="scanning==2">
            <div class="goods-img">
              <!--扫描图片-->
              <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E5%8D%8A%E9%80%8F%E6%98%8E.png"
                   alt=""
                   style="position: absolute;top: 22px;left: 22px;width: 80%;border-radius: 50%"
                   class="rotation">
              <!--扫描图片-->

              <!--圆圈底图图片-->
              <img src="https://image.mobduos.com/upload/image/manager/2021/01/08/d22ec353-8e20-404e-8142-4a0a1a990033.png"
                   alt="">
              <!--圆圈底图图片-->
            </div>
            <div style="position: absolute;top: 15px;left: 15px;" class="rotation">
              <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E5%9C%862.png.png"
                   alt="">
            </div>
            <div style="position: absolute;top: -37px;left: -38px;" class="rotation">
              <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E5%9C%865.png"
                   alt="">
            </div>
            <div class="x1">
              <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E8%B5%84%E6%BA%90%204.png"
                   alt="">
            </div>
            <div class="x2">
              <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E8%B5%84%E6%BA%90%203.png"
                   alt="">
            </div>
            <div class="x3">
              <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E8%B5%84%E6%BA%90%205.png"
                   alt="">
            </div>
            <div class="x4">
              <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E8%B5%84%E6%BA%90%206.png"
                   alt="">
            </div>
            <div class="t1">
              <div style="position: relative;">
                <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E5%9C%863.png"
                     alt="" class="t1-img">
                <span class="text-c">商户信息获取中</span>
              </div>
            </div>
            <div class="t2">
              <div style="position: relative;">
                <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E5%9C%863.png"
                     alt="" class="t2-img">
                <span class="text-c">商户信息同步中</span>
              </div>
            </div>
            <div class="t3">
              <div style="position: relative;">
                <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E5%9C%863.png"
                     alt="" class="t3-img">
                <span class="text-c">数据处理中</span>
              </div>
            </div>
            <div class="t4">
              <div style="position: relative;">
                <img src="https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E5%9C%863.png"
                     alt="" class="t3-img">
                <span class="text-c">数据正在入库中</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {batchHfAddPiece, list} from "@/api/mch";

export default {
  name: "HfAddPiece",
  data(){
    return{
      itemContent: '',
      mchId: '',
      itemId: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      pageSize: 20,
      scanning: 0,
      resultText: '',
      activityCategoryData: [],
      title: '开始进件',
      isBrushSales: false,
      goodsInfo: null,
      activityDate: [],
      tabsName: '',
      pageNum: 1,
      total: '',
      activityLoading: false,
      activityTabs: "1",
      activityData: [],
      activityData1: [],
      activityData2: [],
      activityData3: [],
      loading2: false,
      restaurants2: []
    }
  },
  mounted() {
    this.initPieceList();
  },
  methods:{
    initPieceList() {
      this.loading2 = true;
      let param = {
        pageNum: 1,
        pageSize: 1000,
        aId: this.$store.getters.userInfo.id
      }
      list(param).then(res => {
        this.restaurants2 = res.data.list
        this.loading2 = false;
      })
    },
    handleSelect2(item) {
      this.mchId = item.mid
    },
    tabClick(val){
      this.tabsName = val.label;
      this.getList();
    },
    getGoods() {
      let _this = this;
      if (_this.itemContent.trim() == "") {
        _this.$message({
          type: 'warning',
          message: '请填需要进件的商户号'
        })
        return false;
      }
      _this.title = '进件商户同步中'
      _this.scanning = 2
      setTimeout(() => {
        let param = {
          mchId: _this.mchId,
          agentId: _this.$store.getters.userInfo.id,
          tenantId: 6,
          mchIds: _this.itemContent.trim().split(",")
        }
        batchHfAddPiece(param).then(res =>{
          _this.scanning = 0
          _this.title = '进件商户同步完成'
          _this.$message({
            type: 'success',
            message: '进件完成，后台正在处理中'
          })
        })
      }, 6000)

    },
    getGoodsBrushSalesCheck(flg){

      // if (this.activityData){
      //   console.log("進來了")
      //   _this.title = 'AI智能数据分析中';
      //   _this.scanning = 2;
      //   setTimeout(() => {
      //     _this.scanning = 1;
      //     _this.isBrushSales = true
      //     _this.title = 'AI智能数据分析完成';
      //     _this.resultText = '已为你找到该商品相关活动信息如下显示：'
      //   }, 4000)
      //
      // }else{
      //   _this.title = 'AI智能数据分析中';
      //   _this.scanning = 2;
      //   setTimeout(() => {
      //     _this.scanning = 1;
      //     _this.isBrushSales = false
      //     _this.title = '该商品暂未参加活动';
      //     _this.resultText = res.checkResult ? res.checkResult : ''
      //   }, 4000)
      // }
    },
    resetTask(){
      this.goodsId = '';
      this.goodsInfo = null;
      this.scanning = 0;
      this.resultText = '';
      this.isBrushSales = false;
      this.title = '开始对商品进行检测';
    }
  }
}
</script>

<style lang="less" scoped>
.bg {
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: url("https://image.mobduos.com/upload/image/manager/2021/01/08/e6bec9e0-430f-4cc0-a25d-726e2be98463.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  background-color: #DFE7FF;

  .header {
    width: 100%;
    position: relative;
    text-align: center;
    max-height: 330px;
    transition:max-height 2s;
    .header-bg {
      overflow: hidden;
      width: 100%;
      max-height: 330px;
      transition:max-height 2s;

    }
    .header-title-font {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 30px;
      color: rgb(255, 255, 255);
      max-width: 340px;
    }

    .header-check-input {
      width: 30%;
      min-width: 350px;
      max-width: 556px;
      padding: 6px 33px;
      height: 70px;
      line-height: 64px;
      box-shadow: 0 13px 27px 0 rgba(206, 212, 244, 0.32);
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      background: #fff;
      /deep/.el-input__inner{
        border: none;
      }
    }
    .header-check-input2 {
      min-width: 220px;
      max-width: 220px;
      padding: 6px 33px;
      height: 70px;
      line-height: 64px;
      box-shadow: 0 13px 27px 0 rgba(206, 212, 244, 0.32);
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background: #fff;
      /deep/.el-input__inner{
        border: none;
      }
    }
  }
  .header.scan-header {
    max-height: 80px;
    .header-bg {
      max-height: 80px;
    }
  }

  .check-body {
    min-height: 400px;

    .check-button {
      position: relative;
      margin: 0 auto;
      text-align: center;
      padding-top: 85px;
      transition:padding-top 1s;
      .check-button-group-unchoose, .check-button-group-checked {
        display: inline-block;
        position: relative;

        .inner-gray-radio {
          position: absolute;
          top: 11px;
          left: 13px;

          img {
            width: 191px;
          }
        }


        .out-blue-radio {
          position: absolute;
          top: -37px;
          left: -38px;

          img {
            width: 296px;
            height: 296px;
          }
        }
      }
      .sacnel-button-title {
        margin: 75px 0px;
        margin-bottom: 40px;
        .goods-name {
          color: #384178;
          font-size: 16px;
          margin-bottom: 39px;
        }
      }
    }
    .unchoose{
      padding-top: 130px;
    }
  }


  .check-body.scan-body {
    .check-button {
      // padding-top: 60px;
      transition:padding-top 1s;
      .sacnel-button-title {

        margin-top: 55px;
        transition:margin-top 2s;
        .goods-name {
          margin-bottom: 39px;
          transition:margin-bottom 1s;
        }
      }
    }


  }

  .scan-result{
    margin: 0 auto;
    min-height: 100px;
    border-width: 2px 30px;
    border-style: solid;
    border-image-source: url(https://blog-1253935418.cos.ap-guangzhou.myqcloud.com/zhixia/%E6%A1%86.png);
    border-image-slice: 2 33;
    border-image-width: 2px 33px;
    width: 640px;
    .result-context{
      margin: 25px auto 0;
      min-height: 35px;
      color: #575A6D;
      font-weight: 400;
      font-size: 14px;
      text-align: left;
    }
  }

  .echarts-body {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    .echarts-box {
      width: 580px;
      height: 500px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }

      &:first-child {
        margin-right: 40px;
      }

      .echarts-box-inner {
        position: absolute;
        width: 100%;
        top: 25px;
        padding: 0 70px;
      }
    }
  }

  .ripple {
    box-sizing: border-box;
    position: relative;
    border-radius: 50%;
    cursor: pointer;

    img {
      border-radius: 50%;
    }

    .ripple-b {
      animation: ripple3 1.5s ease-out infinite;
      border: solid 1px #A4D0F6;
      border-radius: 50%;
      bottom: 0;
      box-sizing: border-box;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    .ripple-button-choose-font {
      position: absolute;
      top: 75px;
      left: 82px;
      font-size: 28px;
      color: #ffff;
      z-index: 11
    }

    .ripple-inner-img {
      border-radius: 50%;
      box-sizing: border-box;
      position: absolute;
      width: 172px;
      overflow: hidden;
      top: 24px;
      left: 24px;

      img {
        width: 100%
      }
    }
  }

  .ripple:before {
    animation: ripple 1.5s ease-out infinite;
    border: solid 1px #A4D0F6;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .ripple:after {
    animation: ripple1 1.5s ease-out infinite;
    border: solid 1px #A4D0F6;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  @keyframes ripple {
    0% {
      opacity: .55;
    }
    100% {
      border-radius: 50%;
      opacity: 0;
      transform: scale(1.6);
    }
  }
  @keyframes ripple1 {
    0% {
      opacity: .55;
      transform: scale(1.1);
    }
    100% {
      border-radius: 50%;
      opacity: 0;
      transform: scale(1.6);
    }
  }
  @keyframes ripple3 {
    0% {
      opacity: .55;
      transform: scale(1.2);
    }
    100% {
      border-radius: 50%;
      opacity: 0;
      transform: scale(1.6);
    }
  }
  .goods-img {
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;
    width: 219px;
    height: 219px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .rotation {
    animation: turn 2.8s linear infinite;
  }

  @keyframes turn {
    0% {
      -webkit-transform: rotate(0deg);
    }
    25% {
      -webkit-transform: rotate(90deg);
    }
    50% {
      -webkit-transform: rotate(180deg);
    }
    75% {
      -webkit-transform: rotate(270deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  .x1 {
    position: absolute;
    top: 8px;
    left: -80px;
    animation: fade 1.4s 1.4s ease infinite;
    opacity: 0;
  }

  .x2 {
    position: absolute;
    top: 0;
    right: -75px;
    opacity: 0;
    animation: fade 1.8s 1.8s ease infinite;
  }

  .x3 {
    position: absolute;
    bottom: -20px;
    opacity: 0;
    left: -136px;
    animation: fade 2.2s 2.2s ease infinite;
  }

  .x4 {
    position: absolute;
    bottom: -20px;
    right: -136px;
    opacity: 0;
    animation: fade 2.6s 2.6s ease infinite;
  }

  .t1 {
    position: absolute;
    top: -86px;
    left: -220px;
    opacity: 0;
    animation: fade 1.6s 1.6s ease infinite;
  }

  .t2 {
    position: absolute;
    top: -86px;
    right: -220px;
    opacity: 0;
    animation: fade 2s 2s ease infinite;
  }

  .t3 {
    position: absolute;
    left: -280px;
    bottom: -90px;
    opacity: 0;
    animation: fade 2.4s 2.4s ease infinite;
  }

  .t4 {
    position: absolute;
    right: -280px;
    bottom: -90px;
    opacity: 0;
    animation: fade 2.8s 2.8s ease infinite;
  }

  .text-c {
    color: #333333;
    font-size: 14px;
    position: absolute;
    width: 80px;
    top: 60px;
    left: 30px;
  }

  .t1-img {
    animation: turn 2.8s linear infinite;
  }

  .t2-img {
    animation: turn 2.8s linear infinite;
  }

  .t3-img {
    animation: turn 2.8s linear infinite;
  }

  .t4-img {
    animation: turn 2.8s linear infinite;
  }

  @keyframes fade {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }

}
</style>
