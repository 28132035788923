var chartOption1 = {
    grid: {
        bottom: 40
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        },
        position: function(point, params, dom, rect, size) {
            dom.style.transform = "translateZ(0)";
        }
    },
    legend: {
        data: ['新用户', '老用户'],
        right: 0,
        textStyle: {
            color: "#ffffff82",
            fontSize: 10
        },
        top: 10,
        itemWidth: 15,  // 设置宽度
        itemHeight: 10, // 设置高度
    },
    xAxis: {
        type: 'category',
        data: ['12-01',
            '12-02',
            '12-03',
            '12-04',
            '12-05',
            '12-06',
            '12-07',
            '12-08',
            '12-09',
            '12-10',
            '12-11',
            '12-12',
            '12-13',
            '12-14',
            '12-15',
            '12-16',
            '12-17',
            '12-18',
            '12-19',
            '12-20',
            '12-21',
            '12-22',
            '12-23',
            '12-24',
            '12-25',
            '12-26',
            '12-27',
            '12-28',
            '12-29',
            '12-30'
        ],
        name: '日期',
        axisLine: {
            lineStyle: '#141E6CFF'
        },
        axisLabel: {
            color: '#ffffff82',
        },

    },
    yAxis: [
        {
            type: 'value',
            namep: '新用户',
            min: 0,
            axisLabel: {
                color: '#ffffff82',
                formatter: '{value}',
                fontStyle: 'oblique'
            },
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#B443F5'
                }
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: ['#141E6C'],
                    width: 1,
                    type: 'solid'
                }
            }
        },
        {
            type: 'value',
            namep: '老用户',
            min: 0,
            axisLabel: {
                color: '#ffffff82',
                formatter: '{value}',
                fontStyle: 'oblique'
            },
            axisTick: {
                show: false
            },
            splitLine: {
                //横分隔线不展示
                show: false
            }
        }
    ],
    series: [

        {
            name: '新用户',
            data: [
                1522, 100, 412, 459, 125, 446,
                1522, 100, 412, 459, 125, 446,
                1522, 100, 412, 459, 125, 446,
                1522, 100, 412, 459, 125, 446,
                1522, 100, 412, 459, 412, 459
            ],
            type: 'bar',
            areaStyle: {},
            smooth: true,
            color: '#B443F5',
            yAxisIndex: 0,
            barWidth: 6,
            itemStyle: {
                color: "#B443F5"
            }
        },
        {
            name: '老用户',
            data: [
                1, 5, 3, 4, 5, 6,
                1, 5, 3, 4, 5, 6,
                1, 5, 3, 4, 5, 6,
                1, 5, 3, 4, 5, 6,
                1, 5, 3, 4, 50, 15
            ],
            areaStyle: {},
            type: 'bar',
            smooth: true,
            color: '#5cc0e3',
            yAxisIndex: 1,
            barWidth: 6,
            itemStyle: {
                color: "#3C6EFF"
            }
        }
    ]
}
export default {
    data() {
        return {
            userOption1: {},
        }
    },
    methods: {
        loadUser() {
            let newOldUserDayOrderInfoList = JSON.parse(JSON.stringify(this.newOldUserDayOrderInfoList || []))
            let _newOldUserDayOrderInfoList = []
            for (let item of this.timeList.slice(1)) {
                let obj = {
                    time: item,
                    newUser: 0,
                    oldUser: 0,
                }
                for (let i in newOldUserDayOrderInfoList) {
                    if (newOldUserDayOrderInfoList[i].time == item) {
                        obj.newUser =  newOldUserDayOrderInfoList[i].newUser 
                        obj.oldUser =  newOldUserDayOrderInfoList[i].oldUser
                        newOldUserDayOrderInfoList.splice(i,1)
                    }
                }
                _newOldUserDayOrderInfoList.push(obj)
            }
            newOldUserDayOrderInfoList = _newOldUserDayOrderInfoList
            chartOption1.xAxis.data = newOldUserDayOrderInfoList.map(v => v.time.replace(/^\d{4}-/, ''));
            chartOption1.series[0].data = newOldUserDayOrderInfoList.map(v => v.newUser);
            chartOption1.series[1].data = newOldUserDayOrderInfoList.map(v => v.oldUser);
            if (newOldUserDayOrderInfoList.length > 15) {
                chartOption1.dataZoom = {
                    type: 'slider',
                    realtime: false, //拖动滚动条时是否动态的更新图表数据
                    height: 6,//滚动条高度
                    // start: 70,//滚动条开始位置（共100等份）
                    // end: 100,//结束位置（共100等份）
                    startValue: newOldUserDayOrderInfoList.length - 15,
                    endValue: newOldUserDayOrderInfoList.length - 1,
                    bottom: 0,
                    // minSpan: 30,
                    // maxSpan: 30,
                    fillerColor: '#314B79FF',
                    backgroundColor: '#091D6FFF',
                    borderColor: 'rgba(255, 255, 255, 0)'
                }
            }
            this.userOption1 = chartOption1;
        }
    }
}