const OSS = require('ali-oss')
export default {
    bucketUpload(file, successCallback = new Function(), errCallBack = new Function(), bucketName = 'zzl-filerepostorypublic', dir = 'image') {
        try {
            let fileName = file.name// 先获取上传要的资料签名
            // 实例化一个上传客户端
            const client = new OSS({
                region: 'oss-cn-beijing',
                secure:true,
                cname: true,
                accessKeyId: 'LTAI5t9RDa6YNdveT7CjZhpA',
                accessKeySecret: '5B1JfvLrJSHUsROT1j0tunUKpUsrEu',
                bucket: 'shizhi-image',
                endpoint: "https://image.shizhipay.com"
            })
            if (dir.substring(dir.length - 1, 1) !== '/') {
                dir += '/'
            }
            const result = client.put(dir + Math.round(Math.random()*899999999999-100000000000) + ".jpg", file)
            result.then(res => {
                let size = file.size > 1000000 ? parseFloat(file.size / 1000000).toFixed(2) + 'M' : parseFloat(file.size / 1000).toFixed(2) + 'KB'
                successCallback({
                    attachment: fileName,
                    aliyunAddress: res.url,
                    size: size
                })
            }).catch(err => {
                errCallBack(err)
            })
        }catch (e) {

        }
    },
    bucketUpload2(file, successCallback = new Function(), errCallBack = new Function(), bucketName = 'zzl-filerepostorypublic', dir = 'image') {
        try {
            let fileName = file.name// 先获取上传要的资料签名
            // 实例化一个上传客户端
            const client = new OSS({
                region: 'oss-cn-beijing',
                secure:true,
                cname: true,
                accessKeyId: 'LTAI5t9RDa6YNdveT7CjZhpA',
                accessKeySecret: '5B1JfvLrJSHUsROT1j0tunUKpUsrEu',
                bucket: 'shizhi-image',
                endpoint: "https://image.shizhipay.com"
            })
            if (dir.substring(dir.length - 1, 1) !== '/') {
                dir += '/'
            }
            let blob = this.base64toBlob(file.content);
            const result = client.put(dir + Math.round(Math.random()*899999999999-100000000000) + ".jpg", blob)
            result.then(res => {
                let size = file.size > 1000000 ? parseFloat(file.size / 1000000).toFixed(2) + 'M' : parseFloat(file.size / 1000).toFixed(2) + 'KB'
                successCallback({
                    attachment: fileName,
                    aliyunAddress: res.url,
                    size: size
                })
            }).catch(err => {
                errCallBack(err)
            })
        }catch (e) {

        }
    },
    base64toBlob(dataurl) {
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }
}