var option = {
    title: {
        show: false,
        text: '新老用户占比',
        left: 'center'
    },
    tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)',
        position: function(point, params, dom, rect, size) {
            dom.style.transform = "translateZ(0)";
        },
    },
    legend: {
        left: 'center',
        top: 'bottom',
        data: ['新用户金额', '老用户金额'],
        show: false
    },
    series: [
        {
            name: '半径模式',
            type: 'pie',
            radius: ['45%', '55%'],
            avoidLabelOverlap: true,
            hoverAnimation: false,
            itemStyle: {
                borderRadius: 0,
                normal: {
                    color: function (params) {
                        //自定义颜色
                        var colorList = [
                            '#B443F5', '#3C6EFF', '#FFFF00', '#FF8C00', '#FF0000', '#FE8463',
                        ];
                        return colorList[params.dataIndex]
                    }
                }
            },
            label: {
                show: true,
                color: "#FFF",
                fontSize: 12,
                position: 'outside',
                showAbove: true,
                formatter: '{b}\n {c}',
                padding: [-80, -53, -50, -53],
                align: 'left',
                rich: {
                    a: {
                        color: "#333",
                        fontSize: 20,
                        lineHeight: 30
                    },
                }
            },
            labelLine: {
                showAbove: true,
                length2: 50,
                minTurnAngle: 120,
                maxSurfaceAngle: 150
            },
            selectedOffset: 1,
            labelLayout(params) {
                return {
                    x: params.labelRect.x - 10,
                    y: -10,
                    verticalAlign: 'middle',
                    align: 'top'
                }
            },

            emphasis: {
                label: {
                    show: true
                }
            },
            data: [
                { value: 0, name: '新用户金额', selected: true },
                { value: 0, name: '老用户金额' },
            ]
        }
    ]
};
export default {
    data() {
        return {
            gmvRatioOption: {}
        }
    },
    watch: {

    },
    methods: {
        loadGmvRatioOption() {
            option.series[0].data[0].value = this.newUserGmv;
            option.series[0].data[1].value = this.oldUserGmv;
            this.gmvRatioOption = option;
        }
    }
}