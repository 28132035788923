/**
 * 函数防抖
 */
export function debounce(fn, delay) {
    // 记录上一次的延时器
    let timer = null;
    var delay = delay || 200;
    return function() {
        var args = arguments;
        var that = this;
        // 清除上一次延时器
        clearTimeout(timer)
        timer = setTimeout(function() {
            fn.apply(that,args)
        }, delay);
    }
}