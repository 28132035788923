<template>
  <div>
    <Tabs v-model="pieceForm.incomType" type="card" color="#5157f7">
      <Badge style="position: relative;top: -28px;left: 23px;">
        <template #content>
          推荐
        </template>
      </Badge>
      <Tab :name="0" title="营业执照进件 ">
        <div style="background-color: #f7f8fa;">
          <Form ref="form">
            <div style="padding-top: 5px;">
              <Divider :style="{ color: '#5157f7', borderColor: '#5157f7', padding: '5px 10px'}"><span
                  style="font-weight: bold;font-size: 18px;">资质信息</span></Divider>
              <Field name="radio" label="进件平台">
                <template #input>
                  <RadioGroup v-model="pieceForm.tenantId" direction="horizontal">
                    <Radio :name="1">银盛进件</Radio>
<!--                    <Radio :name="2">汇聚进件</Radio>-->
<!--                    <Radio style="margin-top: 2vh" :name="4">贵州汇聚</Radio>-->
                    <Radio style="margin-top: 2vh" :name="3">优胜专用进件</Radio>
                    <Radio style="margin-top: 2vh" :name="5">文惠付</Radio>
                  </RadioGroup>
                </template>
              </Field>
              <Field name="license" label="营业执照" :rules="[{ required: true, message: '营业执照' }]">
                <template #input>
                  <Uploader :before-read="beforeRead2" :max-size="2048 * 1024" @oversize="onOversize" v-model="fileList"
                            :max-count='1' :after-read="afterRead"/>
                </template>
              </Field>
              <Field
                  readonly
                  clickable
                  name="picker"
                  :value="aptitude"
                  label="资质类型"
                  :rules="[{ required: true, message: '资质类型' }]"
                  placeholder="请选择资质类型"
                  @click="showPicker = true"
              />
              <Popup v-model="showPicker" position="bottom">
                <Picker
                    show-toolbar
                    :columns="columns"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
                />
              </Popup>
              <Field
                  v-model="pieceForm.registerUser"
                  name="registerUser"
                  label="注册名称"
                  placeholder="注册名称"
                  :rules="[{ required: true, message: '注册名称' }]"
              />
              <Field
                  v-model="pieceForm.identifier"
                  name="identifier"
                  label="社会信用代码"
                  placeholder="注册等级/社会统一信用代码"
                  :rules="[{ required: true, message: '社会统一信用代码' }]"
              />
              <Field
                  v-model="dateTwoDate2"
                  name="licenseEndDate"
                  label="执照有效期"
                  placeholder="执照有效期"
                  :rules="[{ required: true, message: '执照有效期' }]"
              />
              <Field
                  v-model="pieceForm.registerAddress"
                  type="textarea"
                  name="registerAddress"
                  label="注册地址"
                  placeholder="注册地址"
                  :rules="[{ required: true, message: '注册地址' }]"
              />

              <Field name="idCardStart" label="身份证正面照">
                <template #input>
                  <Uploader :before-read="beforeRead2" :max-size="2048 * 1024" @oversize="onOversize"
                            v-model="fileList2" :max-count='1' :after-read="afterRead2"/>
                </template>
              </Field>
              <Field name="idCardEnd" label="身份证反面照">
                <template #input>
                  <Uploader :before-read="beforeRead2" :max-size="2048 * 1024" @oversize="onOversize"
                            v-model="fileList3" :max-count='1' :after-read="afterRead3"/>
                </template>
              </Field>
              <Field
                  v-model="pieceForm.legalPerson"
                  name="legalPerson"
                  label="法人姓名"
                  placeholder="法人姓名"
                  :rules="[{ required: true, message: '法人姓名' }]"
              />
              <Field
                  v-model="pieceForm.idCard"
                  name="idCard"
                  label="法人证件号"
                  placeholder="法人证件号"
                  :rules="[{ required: true, message: '法人证件号' }]"
              />
              <Field
                  v-model="dateTwoDate"
                  name="用户名"
                  label="证件有效期"
                  placeholder="证件有效期"
                  :rules="[{ required: true, message: '证件有效期' }]"
              />
              <Divider :style="{ color: '#5157f7', borderColor: '#5157f7', padding: '5px 10px'}"><span
                  style="font-weight: bold;font-size: 18px;">基础信息 </span></Divider>
              <Field
                  v-model="pieceForm.mchFor"
                  name="用户名"
                  label="商户简称"
                  placeholder="用于付款页向客户"
                  :rules="[{ required: true, message: '商户简称' }]"
              />
              <Field
                  v-model="pieceForm.userName"
                  name="用户名"
                  label="联系人姓名"
                  placeholder="联系人姓名"
                  :rules="[{ required: true, message: '联系人姓名' }]"
              />
              <Field
                  v-model="pieceForm.phone"
                  name="用户名"
                  :maxlength="11"
                  label="联系人手机号"
                  type="number"
                  placeholder="联系人手机号"
                  :rules="[{ required: true, message: '联系人手机号' }]"
              />
              <Field
                  is-link
                  readonly
                  v-model="fieldValue"
                  label="商户类别"
                  placeholder="商户类别"
                  @click="showPicker2 = true"
              />
              <Popup v-model="showPicker2" round position="bottom">
                <Cascader
                    title="请选择商户类别"
                    :options="options"
                    @finish="onFinish"
                    :field-names="{text: 'label',value: 'value',children: 'children',}"
                    @close="showPicker2 = false"
                />
              </Popup>
              <Field
                  is-link
                  readonly
                  v-model="fieldValue2"
                  label="经营区域"
                  placeholder="经营区域"
                  @click="showPicker3 = true"
              />
              <Popup v-model="showPicker3" round position="bottom">
                <Cascader
                    title="请选择所在地区"
                    :options="options3"
                    @finish="onFinish2"
                    :field-names="{text: 'label',value: 'value',children: 'children',}"
                    @close="showPicker3 = false"
                />
              </Popup>
              <Field
                  v-model="pieceForm.manageCityAddress"
                  type="textarea"
                  label="经营地址"
                  placeholder="经营地址"
                  :rules="[{ required: true, message: '经营地址' }]"
              />
              <Field name="uploader" label="门头照">
                <template #input>
                  <Uploader :before-read="beforeRead2" :max-size="2048 * 1024" @oversize="onOversize" :max-count='1'
                            :after-read="afterRead4" v-model="fileList4"/>
                </template>
              </Field>
              <Field name="uploader" label="内景照">
                <template #input>
                  <Uploader :before-read="beforeRead2" :max-size="2048 * 1024" @oversize="onOversize" :max-count='1'
                            :after-read="afterRead5" v-model="fileList5"/>
                </template>
              </Field>
              <Field name="uploader" label="收银台照">
                <template #input>
                  <Uploader :before-read="beforeRead2" :max-size="2048 * 1024" @oversize="onOversize" :max-count='1'
                            :after-read="afterRead6" v-model="fileList6"/>
                </template>
              </Field>
              <Divider :style="{ color: '#5157f7', borderColor: '#5157f7', padding: '5px 10px'}"><span
                  style="font-weight: bold;font-size: 18px;">结算信息 </span></Divider>
              <Field name="uploader" label="银行卡照">
                <template #input>
                  <Uploader :before-read="beforeRead2" :max-size="2048 * 1024" @oversize="onOversize" :max-count='1'
                            :after-read="afterRead7" v-model="fileList7"/>
                </template>
              </Field>
              <Field
                  v-model="pieceForm.settlementName"
                  label="结算人姓名"
                  placeholder="结算人姓名"
                  :rules="[{ required: true, message: '请输入结算人姓名' }]"
              />
              <Field
                  v-model="pieceForm.bankCard"
                  label="银行卡卡号"
                  placeholder="银行卡卡号"
                  type="number"
                  :rules="[{ required: true, message: '请输入银行卡卡号' }]"
              />
              <Field
                  v-model="pieceForm.settlementPhone"
                  name="用户名"
                  :maxlength="11"
                  label="预留手机号"
                  type="number"
                  placeholder="预留手机号"
                  :rules="[{ required: true, message: '请输入银行预留手机号' }]"
              />
              <Field
                  is-link
                  readonly
                  v-model="fieldValue4"
                  label="开户城市"
                  placeholder="开户城市"
                  @click="showPicker5 = true"
              />
              <Popup v-model="showPicker5" round position="bottom">
                <Cascader
                    title="请选择开户城市"
                    :options="options2"
                    @finish="onFinish4"
                    :field-names="{text: 'label',value: 'value'}"
                    @close="showPicker4 = false"
                />
              </Popup>
              <Field
                  is-link
                  readonly
                  v-model="fieldValue3"
                  label="开户行"
                  placeholder="开户行"
                  @click="showPicker4 = true"
              />
              <Popup v-model="showPicker4" round position="bottom">
                <Cascader
                    title="请选择开户行"
                    :options="options4"
                    @finish="onFinish3"
                    :field-names="{text: 'bankName',value: 'bankName'}"
                    @close="showPicker4 = false"
                />
              </Popup>
              <Field
                  is-link
                  readonly
                  v-model="pieceForm.subBranch"
                  label="开户支行"
                  placeholder="开户支行"
                  @click="showPicker6 = true"
              />
              <Popup v-model="showPicker6" round position="bottom">
                <Cascader
                    title="请选择开户支行"
                    :options="newOp"
                    @finish="onFinish5"
                    :field-names="{text: 'openBankName',value: 'openBankName'}"
                    @close="showPicker6 = false"

                >
                  <template slot="title">
                    <Search @input="changeZh" v-model="notText" placeholder="请输入搜索关键词"/>
                  </template>
                </Cascader>
              </Popup>
            </div>
            <div v-if="$route.query.agentId == 'A1525587344'">
              <Divider :style="{ color: '#5157f7', borderColor: '#5157f7', padding: '5px 10px'}"><span
                  style="font-weight: bold;font-size: 18px;">支付通道开通 </span></Divider>
              <div style="display:flex;background-color: #ffffff;justify-items: center;padding-top: 10px;padding-bottom: 10px;">
                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-content: center;margin-left: 14vw">
                  <div style="display:flex;">
                    <img src="../../assets/icon/wxzf.png" style="width: 35px;height: 35px;">
                    <RadioGroup v-model="xz">
                      <Radio checked-color="#22ac38" name="1" style="margin-left: 5px;" icon-size="18px"></Radio>
                    </RadioGroup>
                  </div>
                  <div>
                    <span style="font-size: 12px;">微信支付</span>
                  </div>
                </div>
                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-content: center;margin-left: 15vw">
                  <div style="display:flex;">
                    <img src="../../assets/icon/zfb.png" style="width: 35px;height: 35px;">
                    <RadioGroup v-model="xz">
                      <Radio checked-color="#22ac38" name="1" style="margin-left: 5px;" icon-size="18px"></Radio>
                    </RadioGroup>
                  </div>
                  <div>
                    <span style="font-size: 12px;">支付宝</span>
                  </div>
                </div>
                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-content: center;margin-left: 14vw">
                  <div style="display:flex;">
                    <img src="../../assets/icon/ysf.png" style="width: 35px;height: 35px;">
                    <RadioGroup v-model="xz">
                      <Radio checked-color="#22ac38" name="1" style="margin-left: 5px;" icon-size="18px"></Radio>
                    </RadioGroup>
                  </div>
                  <div>
                    <span style="font-size: 12px;">云闪付</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin: 16px;">
              <Button round block type="info" @click="ok">提交</Button>
            </div>
          </Form>
        </div>
      </Tab>
      <Tab :name="1" title="个人进件">
        <div style="background-color: #f7f8fa;">
          <Form ref="form">
            <div>
              <Divider :style="{ color: '#5157f7', borderColor: '#5157f7', padding: '5px 10px'}"><span
                  style="font-weight: bold;font-size: 18px;">资质信息 </span></Divider>
              <Field name="radio" label="进件平台">
                <template #input>
                  <RadioGroup v-model="pieceForm.tenantId" direction="horizontal">
                    <Radio :name="1">银盛进件</Radio>
<!--                    <Radio :name="2">汇聚进件</Radio>-->
<!--                    <Radio style="margin-top: 2vh" :name="4">贵州汇聚</Radio>-->
                    <Radio style="margin-top: 2vh" :name="3">优胜专用进件</Radio>
                    <Radio style="margin-top: 2vh" :name="5">文惠付</Radio>
                  </RadioGroup>
                </template>
              </Field>
              <Field name="idCardStart" label="身份证正面照">
                <template #input>
                  <Uploader :before-read="beforeRead2" :max-size="2048 * 1024" @oversize="onOversize"
                            v-model="fileList2" :max-count='1' :after-read="afterRead2"/>
                </template>
              </Field>
              <Field name="idCardEnd" label="身份证反面照">
                <template #input>
                  <Uploader :before-read="beforeRead2" :max-size="2048 * 1024" @oversize="onOversize"
                            v-model="fileList3" :max-count='1' :after-read="afterRead3"/>
                </template>
              </Field>
              <Field
                  v-model="pieceForm.legalPerson"
                  name="legalPerson"
                  label="法人姓名"
                  placeholder="法人姓名"
                  :rules="[{ required: true, message: '法人姓名' }]"
              />
              <Field
                  v-model="pieceForm.idCard"
                  name="idCard"
                  label="法人证件号"
                  placeholder="法人证件号"
                  :rules="[{ required: true, message: '法人证件号' }]"
              />
              <Field
                  v-model="dateTwoDate"
                  name="用户名"
                  label="证件有效期"
                  placeholder="证件有效期"
                  :rules="[{ required: true, message: '证件有效期' }]"
              />
              <Divider :style="{ color: '#5157f7', borderColor: '#5157f7', padding: '5px 10px'}"><span
                  style="font-weight: bold;font-size: 18px;">基础信息 </span></Divider>
              <Field
                  v-model="pieceForm.mchFor"
                  name="用户名"
                  label="商户简称"
                  placeholder="用于付款页向客户"
                  :rules="[{ required: true, message: '商户简称' }]"
              />
              <Field
                  v-model="pieceForm.userName"
                  name="用户名"
                  label="联系人姓名"
                  placeholder="联系人姓名"
                  :rules="[{ required: true, message: '联系人姓名' }]"
              />
              <Field
                  v-model="pieceForm.phone"
                  name="用户名"
                  :maxlength="11"
                  label="联系人手机号"
                  type="number"
                  placeholder="联系人手机号"
                  :rules="[{ required: true, message: '联系人手机号' }]"
              />
              <Field
                  is-link
                  readonly
                  v-model="fieldValue"
                  label="商户类别"
                  placeholder="商户类别"
                  @click="showPicker2 = true"
              />
              <Popup v-model="showPicker2" round position="bottom">
                <Cascader
                    title="请选择商户类别"
                    :options="options"
                    @finish="onFinish"
                    :field-names="{text: 'label',value: 'value',children: 'children',}"
                    @close="showPicker2 = false"
                />
              </Popup>
              <Field
                  is-link
                  readonly
                  label="经营区域"
                  v-model="fieldValue2"
                  placeholder="经营区域"
                  @click="showPicker3 = true"
              />
              <Popup v-model="showPicker3" round position="bottom">
                <Cascader
                    title="请选择所在地区"
                    :options="options3"
                    @finish="onFinish2"
                    :field-names="{text: 'label',value: 'value',children: 'children',}"
                    @close="showPicker3 = false"
                />
              </Popup>
              <Field
                  v-model="pieceForm.manageCityAddress"
                  type="textarea"
                  name="用户名"
                  label="经营地址"
                  placeholder="经营地址"
                  :rules="[{ required: true, message: '经营地址' }]"
              />
              <Field name="uploader" label="门头照">
                <template #input>
                  <Uploader :before-read="beforeRead2" :max-size="2048 * 1024" @oversize="onOversize" :max-count='1'
                            :after-read="afterRead4" v-model="fileList4"/>
                </template>
              </Field>
              <Field name="uploader" label="内景照">
                <template #input>
                  <Uploader :before-read="beforeRead2" :max-size="2048 * 1024" @oversize="onOversize" :max-count='1'
                            :after-read="afterRead5" v-model="fileList5"/>
                </template>
              </Field>
              <Field name="uploader" label="收银台照">
                <template #input>
                  <Uploader :before-read="beforeRead2" :max-size="2048 * 1024" @oversize="onOversize" :max-count='1'
                            :after-read="afterRead6" v-model="fileList6"/>
                </template>
              </Field>
              <Divider :style="{ color: '#5157f7', borderColor: '#5157f7', padding: '5px 10px'}"><span
                  style="font-weight: bold;font-size: 18px;">结算信息 </span></Divider>
              <Field name="uploader" label="银行卡照">
                <template #input>
                  <Uploader :before-read="beforeRead2" :max-size="2048 * 1024" @oversize="onOversize" :max-count='1'
                            :after-read="afterRead7" v-model="fileList7"/>
                </template>
              </Field>
              <Field
                  v-model="pieceForm.settlementName"
                  label="结算人姓名"
                  placeholder="结算人姓名"
                  :rules="[{ required: true, message: '请输入结算人姓名' }]"
              />
              <Field
                  v-model="pieceForm.bankCard"
                  label="银行卡卡号"
                  type="number"
                  placeholder="银行卡卡号"
                  :rules="[{ required: true, message: '请输入银行卡卡号' }]"
              />
              <Field
                  v-model="pieceForm.settlementPhone"
                  name="用户名"
                  :maxlength="11"
                  label="预留手机号"
                  type="number"
                  placeholder="预留手机号"
                  :rules="[{ required: true, message: '请输入银行预留手机号' }]"
              />
              <Field
                  is-link
                  v-model="fieldValue4"
                  readonly
                  label="开户城市"
                  placeholder="开户城市"
                  @click="showPicker5 = true"
              />
              <Popup v-model="showPicker5" round position="bottom">
                <Cascader
                    title="请选择开户城市"
                    :options="options2"
                    @finish="onFinish4"
                    :field-names="{text: 'label',value: 'value'}"
                    @close="showPicker4 = false"
                />
              </Popup>
              <Field
                  is-link
                  v-model="fieldValue3"
                  readonly
                  label="开户行"
                  placeholder="开户行"
                  @click="showPicker4 = true"
              />
              <Popup v-model="showPicker4" round position="bottom">
                <Cascader
                    title="请选择开户行"
                    :options="options4"
                    @finish="onFinish3"
                    :field-names="{text: 'bankName',value: 'bankName'}"
                    @close="showPicker4 = false"
                />
              </Popup>
              <Field
                  is-link
                  readonly
                  v-model="pieceForm.subBranch"
                  label="开户支行"
                  placeholder="开户支行"
                  @click="showPicker6 = true"
              />
              <Popup v-model="showPicker6" round position="bottom">
                <Cascader
                    title="请选择开户支行"
                    :options="newOp"
                    @finish="onFinish5"
                    :field-names="{text: 'openBankName',value: 'openBankName'}"
                    @close="showPicker6 = false"

                >
                  <template slot="title">
                    <Search @input="changeZh" v-model="notText" placeholder="请输入搜索关键词"/>
                  </template>
                </Cascader>
              </Popup>
            </div>
            <div v-if="$route.query.agentId == 'A1525587344'">
              <Divider :style="{ color: '#5157f7', borderColor: '#5157f7', padding: '5px 10px'}"><span
                  style="font-weight: bold;font-size: 18px;">支付通道开通 </span></Divider>
              <div style="display:flex;background-color: #ffffff;justify-items: center;padding-top: 10px;padding-bottom: 10px;">
                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-content: center;margin-left: 14vw">
                  <div style="display:flex;">
                    <img src="../../assets/icon/wxzf.png" style="width: 35px;height: 35px;">
                    <RadioGroup v-model="xz">
                      <Radio checked-color="#22ac38" name="1" style="margin-left: 5px;" icon-size="18px"></Radio>
                    </RadioGroup>
                  </div>
                  <div>
                    <span style="font-size: 12px;">微信支付</span>
                  </div>
                </div>
                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-content: center;margin-left: 15vw">
                  <div style="display:flex;">
                    <img src="../../assets/icon/zfb.png" style="width: 35px;height: 35px;">
                    <RadioGroup v-model="xz">
                      <Radio checked-color="#22ac38" name="1" style="margin-left: 5px;" icon-size="18px"></Radio>
                    </RadioGroup>
                  </div>
                  <div>
                    <span style="font-size: 12px;">支付宝</span>
                  </div>
                </div>
                <div
                    style="display: flex;flex-direction: column;justify-content: center;align-content: center;margin-left: 14vw">
                  <div style="display:flex;">
                    <img src="../../assets/icon/ysf.png" style="width: 35px;height: 35px;">
                    <RadioGroup v-model="xz">
                      <Radio checked-color="#22ac38" name="1" style="margin-left: 5px;" icon-size="18px"></Radio>
                    </RadioGroup>
                  </div>
                  <div>
                    <span style="font-size: 12px;">云闪付</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="margin: 16px;">
              <Button round block type="info" @click="ok">提交</Button>
            </div>
          </Form>
        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import {
  Tab,
  Tabs,
  Form,
  Field,
  Button,
  Divider,
  Uploader,
  Picker,
  Popup,
  DatetimePicker,
  Calendar,
  Cascader,
  Radio,
  RadioGroup, Dialog, Search, Badge, Tag
} from 'vant';
import common from '@/utils/oss'
import {initData} from "@/api/app";
import {getBrankName, getMcc, getSubBrankList, uploadFileOcr} from "@/api/user";
import {addPiece} from "@/api/mch";
import {compressAccurately} from 'image-conversion'

export default {
  name: "h5-piece",
  components: {
    Tab, Tabs, Form, Field, Button, Divider, Uploader, Picker, Popup, DatetimePicker, Calendar, Cascader, Radio, Badge,
    RadioGroup, Search, Tag
  },
  data() {
    return {
      xz: '1',
      active: "2",
      username: '',
      fieldValue: '',
      fieldValue2: '',
      fieldValue3: '',
      fieldValue4: '',
      aptitude: '',
      cascaderValue: '',
      showPicker: false,
      showPicker2: false,
      showPicker3: false,
      showPicker4: false,
      showPicker5: false,
      showPicker6: false,
      options: [],
      options2: [],
      options3: [],
      options4: [],
      options5: [],
      columns: ['个体户', '企业'],
      password: '',
      value: '个体户',
      notText: '',
      value2: '',
      fileList: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      fileList5: [],
      fileList6: [],
      fileList7: [],
      dateTwoDate: '',
      dateTwoDate2: '',
      newOp: [],
      pieceForm: {
        license: '',
        incomType: 0,
        aptitude: '',
        identifier: '',
        registerAddress: '',
        legalPerson: '',
        registerUser: '',
        idCardStart: '',
        idCardEnd: '',
        licenseStartDate: '',
        licenseEndDate: '',
        idCardDateStart: '',
        idCardDateEnd: '',
        idCard: '',
        mchFor: '',
        userName: '',
        phone: '',
        mccId: '',
        agentId: '',
        manageCity: '',
        manageCityAddress: '',
        doorPhoto: '',
        interiorPhoto: '',
        checkstandPhoto: '',
        bankCardPhoto: '',
        settlementName: '',
        bankCard: '',
        settlementPhone: '',
        incomStatus: '',
        brank: '',
        openingCity: '',
        subBranch: '',
        mchId: '',
        remark: '',
        tenantId: 1
      }
    }
  },
  created() {
    this.initData();
  },
  watch: {
    'pieceForm.incomType'() {
      this.getMcc()
    }
  },
  mounted() {
    if (this.$route.query.agentId && this.$route.query.mchId) {
      this.pieceForm.agentId = this.$route.query.agentId
      this.pieceForm.mchId = this.$route.query.mchId
    } else {
      Dialog.alert({
        title: "失败提示",
        message: '无法操作，缺少重要参数',
        theme: 'round-button',
      }).then(() => {
      });
    }
    this.getMcc();
  },
  methods: {
    changeZh(val) {
      this.newOp = JSON.parse(JSON.stringify(this.options5))
      this.newOp = this.newOp.filter(item => {
        return item.openBankName.indexOf(val) != -1
      })
      console.log(this.newOp)
    },
    filter(inputValue, path) {
      return path.some(option => option.openBankName.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    },
    getBrankName() {
      getBrankName(this.pieceForm.openingCity).then(res => {
        this.options4 = res.data
      })
    },
    getMcc() {
      getMcc(this.pieceForm.incomType == 1 ? 3 : 1).then(res => {
        this.options = res.data
      })
    },
    onOversize() {
      Dialog.alert({
        title: "上传失败",
        message: '文件不能超过2M',
        theme: 'round-button',
      }).then(() => {
      });
    },
    removeEmptyValues(obj) {
      for (const key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
          delete obj[key];
        } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
      return obj;
    },
    ok() {
      this.$refs.form.validate().then(() => {
        this.pieceForm.incomStatus = 0;
        let removeEmptyValues1 = this.removeEmptyValues(JSON.parse(JSON.stringify(this.pieceForm)));
        removeEmptyValues1.remark = ''
        addPiece(removeEmptyValues1).then(res => {
          if (res.success) {
            if (this.$route.query.agentId == 'A1525587344'){
              Dialog.alert({
                title: "重要提示",
                message: '提交成功，默认配置5个商户号，请支付相应配置费，由管理员进行审核！',
                theme: 'round-button'
              }).then(() => {
              });
            }else{
              Dialog.alert({
                message: '进件成功，等待管理员审核',
                theme: 'round-button',
              }).then(() => {
              });
            }
            this.pieceForm = {
              license: '',
              incomType: 1,
              aptitude: '',
              identifier: '',
              registerAddress: '',
              legalPerson: '',
              registerUser: '',
              idCardStart: '',
              idCardEnd: '',
              licenseStartDate: '',
              licenseEndDate: '',
              idCardDateStart: '',
              idCardDateEnd: '',
              idCard: '',
              mchFor: '',
              userName: '',
              phone: '',
              mccId: '',
              manageCity: '',
              manageCityAddress: '',
              doorPhoto: '',
              interiorPhoto: '',
              checkstandPhoto: '',
              bankCardPhoto: '',
              settlementName: '',
              bankCard: '',
              settlementPhone: '',
              incomStatus: '',
              brank: '',
              openingCity: '',
              subBranch: '',
              mchId: '',
              remark: '',
              tenantId: 1
            }
            this.fileList = []
            this.fileList2 = []
            this.fileList3 = []
            this.fileList4 = []
            this.fileList5 = []
            this.fileList6 = []
            this.fileList7 = []
            this.fieldValue = ''
            this.fieldValue2 = ''
            this.fieldValue3 = ''
            this.fieldValue4 = ''
            this.dateTwoDate = ''
            this.dateTwoDate2 = ''
          }
        })
      }).catch(() => {
        Dialog.alert({
          title: "数据校验",
          message: '表单信息不全，请仔细填写。',
          theme: 'round-button',
        }).then(() => {
        });
      })
    },
    onFinish({selectedOptions}) {
      this.pieceForm.mccId = selectedOptions[1].value
      this.showPicker2 = false;
      this.fieldValue = selectedOptions.map((option) => option.label).join('/');
    },
    onFinish2({selectedOptions}) {
      this.pieceForm.manageCity = selectedOptions[0].label + "/" + selectedOptions[1].label + "/" + selectedOptions[2].label
      this.showPicker3 = false;
      this.fieldValue2 = selectedOptions.map((option) => option.label).join('/');
    },
    onFinish3({selectedOptions}) {
      this.pieceForm.brank = selectedOptions[0].bankName
      getSubBrankList(this.pieceForm.openingCity, this.pieceForm.brank).then(res => {
        this.options5 = res.data
        this.newOp = res.data
      })
      this.showPicker4 = false;
      this.fieldValue3 = selectedOptions.map((option) => option.bankName).join('/');
    },
    onFinish4({selectedOptions}) {
      this.pieceForm.openingCity = selectedOptions[0].label + "/" + selectedOptions[1].label
      this.getBrankName()
      this.showPicker5 = false;
      this.fieldValue4 = selectedOptions.map((option) => option.label).join('/');
    },
    onFinish5({selectedOptions}) {
      this.pieceForm.subBranch = selectedOptions[0].openBankName
      this.showPicker6 = false;
    },
    initData() {
      if (!localStorage.getItem("initData")) {
        initData().then(res => {
          if (res.success) {
            // this.options = res.data.mcc
            this.options2 = res.data.area.city
            this.options3 = res.data.area.area
            // this.options4 = res.data.bank
            localStorage.setItem("initData", JSON.stringify(res.data))
          }
        })
      } else {
        // this.options= JSON.parse(localStorage.getItem("initData")).mcc
        this.options2 = JSON.parse(localStorage.getItem("initData")).area.city
        this.options3 = JSON.parse(localStorage.getItem("initData")).area.area
        // this.options4= JSON.parse(localStorage.getItem("initData")).bank
      }
    },
    onConfirm(value) {
      this.pieceForm.aptitude = value === '个体户' ? 0 : 1;
      this.showPicker = false;
    },
    afterRead(file) {
      let bucketName = 'shizhi-image'; // 桶名
      let dir = `images`;
      common.bucketUpload2(
          file,
          (res) => {
            let temp = {
              name: res.attachment,
              url: res.aliyunAddress
            };
            this.host = res.host;
            temp.url = temp.url.replaceAll("http://shizhi-image.oss-cn-beijing.aliyuncs.com/", "http://image.shizhipay.com/")
            this.pieceForm.license = temp.url
            uploadFileOcr(temp.url, 1).then(res2 => {
              this.pieceForm.registerUser = res2.data.name
              this.pieceForm.identifier = res2.data.reg_num
              this.pieceForm.licenseStartDate = this.transDate(res2.data.establish_date)
              this.pieceForm.licenseEndDate = this.transDate(res2.data.valid_period)
              this.dateTwoDate2 = this.pieceForm.licenseStartDate + "/" + this.pieceForm.licenseEndDate
              this.pieceForm.registerAddress = res2.data.address
              this.pieceForm.aptitude = res2.data.type == '个体工商户' ? 0 : 1
              this.aptitude = res2.data.type == '个体户' ? '个体户' : '企业'
              if (res2.data.valid_period == "29991231") {
                this.cq = true;
              }


            })
          },
          (err) => {
          },
          bucketName,
          dir
      );
    },
    async beforeRead2(file) {
      return new Promise((resolve) => {
        compressAccurately(file, 1024).then(res => {
          resolve(res)
        })
      })
    },
    afterRead2(file) {
      let bucketName = 'shizhi-image'; // 桶名
      let dir = `images`;
      common.bucketUpload2(
          file,
          (res) => {
            let temp = {
              name: res.attachment,
              url: res.aliyunAddress
            };
            this.host = res.host;
            temp.url = temp.url.replaceAll("http://shizhi-image.oss-cn-beijing.aliyuncs.com/", "http://image.shizhipay.com/")
            this.pieceForm.idCardStart = temp.url
            uploadFileOcr(temp.url, 2).then(res2 => {
              this.pieceForm.legalPerson = res2.data.name;
              this.pieceForm.idCard = res2.data.num;
            })
          },
          (err) => {
          },
          bucketName,
          dir
      );
    },
    afterRead3(file) {
      let bucketName = 'shizhi-image'; // 桶名
      let dir = `images`;
      common.bucketUpload2(
          file,
          (res) => {
            let temp = {
              name: res.attachment,
              url: res.aliyunAddress
            };
            this.host = res.host;
            temp.url = temp.url.replaceAll("http://shizhi-image.oss-cn-beijing.aliyuncs.com/", "http://image.shizhipay.com/")
            this.pieceForm.idCardEnd = temp.url
            uploadFileOcr(temp.url, 3).then(res2 => {
              this.pieceForm.idCardEnd = temp.url;
              this.pieceForm.idCardDateStart = this.transDate(res2.data.start_date)
              this.pieceForm.idCardDateEnd = this.transDate(res2.data.end_date)
              this.dateTwoDate = this.pieceForm.idCardDateStart + "/" + this.pieceForm.idCardDateEnd
            })
          },
          (err) => {
          },
          bucketName,
          dir
      );
    },
    afterRead4(file) {
      let bucketName = 'shizhi-image'; // 桶名
      let dir = `images`;
      common.bucketUpload2(
          file,
          (res) => {
            let temp = {
              name: res.attachment,
              url: res.aliyunAddress
            };
            this.host = res.host;
            temp.url = temp.url.replaceAll("http://shizhi-image.oss-cn-beijing.aliyuncs.com/", "http://image.shizhipay.com/")
            this.pieceForm.doorPhoto = temp.url
          },
          (err) => {
          },
          bucketName,
          dir
      );
    },
    afterRead5(file) {
      let bucketName = 'shizhi-image'; // 桶名
      let dir = `images`;
      common.bucketUpload2(
          file,
          (res) => {
            let temp = {
              name: res.attachment,
              url: res.aliyunAddress
            };
            this.host = res.host;
            temp.url = temp.url.replaceAll("http://shizhi-image.oss-cn-beijing.aliyuncs.com/", "http://image.shizhipay.com/")
            this.pieceForm.interiorPhoto = temp.url
          },
          (err) => {
          },
          bucketName,
          dir
      );
    },
    afterRead6(file) {
      let bucketName = 'shizhi-image'; // 桶名
      let dir = `images`;
      common.bucketUpload2(
          file,
          (res) => {
            let temp = {
              name: res.attachment,
              url: res.aliyunAddress
            };
            this.host = res.host;
            temp.url = temp.url.replaceAll("http://shizhi-image.oss-cn-beijing.aliyuncs.com/", "http://image.shizhipay.com/")
            this.pieceForm.checkstandPhoto = temp.url
          },
          (err) => {
          },
          bucketName,
          dir
      );
    },
    afterRead7(file) {
      let bucketName = 'shizhi-image'; // 桶名
      let dir = `images`;
      common.bucketUpload2(
          file,
          (res) => {
            let temp = {
              name: res.attachment,
              url: res.aliyunAddress
            };
            this.host = res.host;
            temp.url = temp.url.replaceAll("http://shizhi-image.oss-cn-beijing.aliyuncs.com/", "http://image.shizhipay.com/")
            this.pieceForm.bankCardPhoto = temp.url
            uploadFileOcr(temp.url, 7).then(res2 => {
              this.pieceForm.bankCard = res2.data.card_num;
            })
          },
          (err) => {
          },
          bucketName,
          dir
      );
    },
    transDate(date) {
      let a = '';
      a = date.substring(0, 4) + "-" + date.substring(4, 6) + "-" + date.substring(6, 8)
      return a;
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner {
  border: 0px;
}
</style>