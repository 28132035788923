var chartOption1 = {
    grid: {
        bottom: 40
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        },
        position: function(point, params, dom, rect, size) {
            dom.style.transform = "translateZ(0)";
        },
        formatter: function(params) {
            let str = params[0].name + '<br/>';
            params.forEach(item => {
                if (item.seriesName == '每日注册量') {
                    str += item.marker + item.seriesName + ': ' + item.data + '<br/>';
                } else if (item.seriesName == "每日转化率") {
                    str += item.marker + item.seriesName + ': ' + item.data + '%';
                }
            });
            return str;
        }
    },
    legend: {
        data: ['每日注册量', '每日转化率'],
        right: 0,
        top: 10,
        itemWidth: 15,  // 设置宽度
        itemHeight: 10, // 设置高度
        textStyle: {
            color: "#ffffff82",
            fontSize: 8
        }
    },
    xAxis: {
        type: 'category',
        data: [],
        name: '日期',
        axisLine: {
            lineStyle: '#141E6CFF'
        },
        axisLabel: {
            color: '#ffffff82',
        },

    },
    yAxis: [
        {
            type: 'value',
            namep: '每日注册人数',
            min: 0,
            axisLabel: {
                color: '#ffffff82',
                formatter: '{value}',
                fontStyle: 'oblique'
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: ['#141E6C'],
                    width: 1,
                    type: 'solid'
                }
            }
        },
        {
            type: 'value',
            namep: '每日转化率',
            min: 'dataMin',
            axisLabel: {
                color: '#ffffff82',
                formatter: '{value}%',
                fontStyle: 'oblique'
            },
            axisTick: {
                show: false
            },
            splitLine: {
                //横分隔线不展示
                show: false
            }
        }
    ],
    series: [

        {
            name: '每日注册量',
            data: [],
            type: 'bar',
            areaStyle: {},
            smooth: true,
            color: '#5ee1c6',
            yAxisIndex: 0,
            barWidth: 8,
            itemStyle: {
                color: "#37E8FA"
            }
        },
        {
            name: '每日转化率',
            data: [],
            type: 'line',
            smooth: true,
            color: '#5cc0e3',
            yAxisIndex: 1,
            areaStyle: {
                opacity: 0.8,
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#058BF3' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'transparent' // 100% 处的颜色
                    }],
                    globalCoord: false // 缺省为 false
                }

            },
        }
    ]
}

export default {
    data() {
        return {
            registerOption: {},
            registerOptionUrl: '/business-screen/registerPanelDataStatis',
            monthRegNum: 0,
            curDayRegNum: 0,
            monthCovertRatio: 0.0,
            curDayConvertRatio: 0.0,
        }
    },
    watch: {

    },
    methods: {
        loadRegisterData(res) {
            if (res.success || res.code == 200) {
                let registerList = JSON.parse(JSON.stringify(res.registerList || []))
                let _registerList = []
                for (let item of this.timeList.slice(1)) {
                    let obj = {
                        time: item,
                        userCount: 0,
                        orderCount: 0,
                    }
                    for (let i in registerList) {
                        if (registerList[i].time == item) {
                            obj.userCount =  registerList[i].userCount 
                            obj.orderCount =  registerList[i].orderCount
                            registerList.splice(i,1)
                        }
                    }
                    _registerList.push(obj)
                }
                registerList = _registerList
                this.monthRegNum = registerList.reduce((s,v) => s + Number(v.userCount || 0), 0);
                let curOrderCount = registerList.slice(-1)[0] ? (registerList.slice(-1)[0].orderCount || 0) : 0;
                this.curDayRegNum = registerList.slice(-1)[0] ? (registerList.slice(-1)[0].userCount || 0) : 0;
                this.monthCovertRatio = res.monthOrderCount && this.monthRegNum ? (res.monthOrderCount / this.monthRegNum * 100).toFixed(2) : 0
                this.curDayConvertRatio = curOrderCount && this.curDayRegNum ? (curOrderCount / this.curDayRegNum * 100).toFixed(2) : 0
                if (registerList.length) {
                    chartOption1.xAxis.data = registerList.map(a => a.time.replace(/^\d{4}-/, ''));
                    chartOption1.series[0].data = registerList.map(a => a.userCount);
                    chartOption1.series[1].data = registerList.map(a => a.userCount && a.orderCount ? (a.orderCount / a.userCount * 100).toFixed(2) : 0);
                    if (registerList.length > 15) {
                        chartOption1.dataZoom = {
                            type: 'slider',
                            realtime: false, //拖动滚动条时是否动态的更新图表数据
                            height: 6,//滚动条高度
                            // start: 70,//滚动条开始位置（共100等份）
                            // end: 100,//结束位置（共100等份）
                            startValue: registerList.length - 15,
                            endValue: registerList.length - 1,
                            bottom: 0,
                            // minSpan: 30,
                            // maxSpan: 30,
                            fillerColor: '#314B79FF',
                            backgroundColor: '#091D6FFF',
                            borderColor: 'rgba(255, 255, 255, 0)'

                        }
                    }
                    this.registerOption = chartOption1;
                }
            }
        }
    }
}
