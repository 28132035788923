<template>
  <div>
    <div style="text-align: left;font-weight: bold;font-size: 20px">
      数据看板
    </div>
    <div style="display: flex;margin-top: 40px;">
      <el-card :body-style="{display: 'flex'}" v-for="(item,index) in topList" :key="index"
               style="width: 30%;height: 120px;" :style="{marginLeft : index != 0 ? '20px' : ''}">
        <img style="margin-left: 40px;" :src="require(`../../assets/board/kb-${index + 1}.png`)" width="90">
        <div style="display: flex;flex-direction: column;margin-left: 40px;">
          <span style="margin-top: 10px;">{{ item.name }}</span>
          <span style="margin-top: 20px;font-size: 18px;font-weight: bold;">{{ item.num }}</span>
        </div>
      </el-card>
    </div>
    <el-card
        style="border-top-left-radius: 10px;border-top-right-radius: 10px;margin-top: 15px;text-align: left;padding-bottom: 20px;"
        class="diyBack">
      <div style="margin-top: 10px;margin-left: 40px;display: flex;justify-content: space-between;">
        <span style="font-size: 18px;font-weight: bold;">交易统计</span>
        <div style="display: flex;align-items: center;padding-right: 100px;">
          <span style="color: #9c9eaf;font-size: 14px;padding-right: 10px;">统计时间</span>
<!--          <el-date-picker-->
<!--              size="mini"-->
<!--              v-model="searchData"-->
<!--              type="daterange"-->
<!--              align="right"-->
<!--              unlink-panels-->
<!--              range-separator="至"-->
<!--              start-placeholder="开始日期"-->
<!--              @change="homeTotalPriceInfo"-->
<!--              end-placeholder="结束日期"-->
<!--              :picker-options="pickerOptions">-->
<!--          </el-date-picker>-->
          <el-date-picker
              size="mini"
              style="width: 230px;"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="searchData"
              type="datetimerange"
              align="right"
              @change="homeTotalPriceInfo"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </div>
      <div
          style="margin-top: 10px;border-top: 1px solid #dbe2f0;padding-top: 10px;padding-left: 40px;display: flex;justify-content: space-between;">
        <div style="display: flex;align-items: center;">
          <div style="display: flex;flex-direction: column;">
            <div>
              <div style="display: flex;align-items: center;">
                <img src="../../assets/board/xdyh.png" alt="">
                <span style="font-weight: bold;">交易趋势图</span>
              </div>
            </div>
            <div style="display:flex;flex-direction: column;text-align: center;">
              <span style="margin-top: 10px;">今日交易额</span>
              <span
                  style="font-size: 25px;font-weight: bold;margin-top: 15px;">{{
                  homeInfo.zxt ? homeInfo.zxt[homeInfo.zxt.length - 1].value : 0
                }}</span>
              <span
                  style="color: #8b8d9f;font-size: 14px;margin-top: 15px;">较昨日  {{
                  homeInfo.zxt ? homeInfo.zxt[homeInfo.zxt.length - 1].value != 0 ? (homeInfo.zxt[homeInfo.zxt.length - 1].value - homeInfo.zxt[homeInfo.zxt.length - 2].value) / homeInfo.zxt[homeInfo.zxt.length - 1].value * 100 : 0 : 0
                }}%</span>
            </div>
          </div>
          <div style="margin-left: 30px;">
            <div id="xdyhEs" style="width: 600px;height: 250px;"></div>
          </div>
        </div>
        <div style="display: flex;align-items: center;">
          <div style="display: flex;flex-direction: column;">
            <div>
              <div style="display: flex;align-items: center;">
                <img src="../../assets/board/xzyh.png" alt="">
                <span style="font-weight: bold;">交易趋势图</span>
              </div>
            </div>
            <div style="display:flex;flex-direction: column;text-align: center;">
              <span style="margin-top: 10px;">今日交易笔数</span>
              <span
                  style="font-size: 25px;font-weight: bold;margin-top: 15px;">{{
                  homeInfo.zxt ? homeInfo.zxt[homeInfo.zxt.length - 1].value2 : 0
                }}</span>
              <span
                  style="color: #8b8d9f;font-size: 14px;margin-top: 15px;">较昨日  {{
                  homeInfo.zxt ? homeInfo.zxt[homeInfo.zxt.length - 1].value2 != 0 ? (homeInfo.zxt[homeInfo.zxt.length - 1].value2 - homeInfo.zxt[homeInfo.zxt.length - 2].value2) / homeInfo.zxt[homeInfo.zxt.length - 1].value2 * 100 : 0 : 0
                }}%</span>
            </div>
          </div>
          <div style="margin-left: 30px;">
            <div id="xzyhEs" style="width: 600px;height: 250px;"></div>
          </div>
        </div>
      </div>
      <div style="padding-left: 40px;">
        <span style="font-weight: bold;">支付方式数据比例</span>
        <div style="display: flex;margin-top: 25px;">
          <div style="width: 31%;background-color: #f5f8ff;height: 110px;padding: 10px;">
            <span style="margin-left: 10px;margin-top: 10px;font-size: 14px;">支付方式分析</span>
            <div style="display: flex;margin-left: 110px;margin-top: 10px;">
              <div style="margin-left: 20px; display: flex;">
                <el-progress :color="'#5157f7'" :show-text="false" :width="50" type="circle"
                             :percentage="62.6"></el-progress>
                <div style="display: flex;flex-direction: column;margin-left: 10px;">
                  <span style="color: #a0a3b2;font-size: 14px;">支付宝</span>
                  <span style="font-size: 18px;margin-top: 6px;">{{
                      (homeInfo.zfbOrderCount / (homeInfo.zfbOrderCount + homeInfo.wxOrderCount) * 100).toFixed(2)
                    }}%</span>
                </div>
              </div>
              <div style="margin-left: 20px;display: flex;">
                <el-progress :color="'#6598fe'" :show-text="false" :width="50" type="circle"
                             :percentage="17.2"></el-progress>
                <div style="display: flex;flex-direction: column;margin-left: 10px;">
                  <span style="color: #a0a3b2;font-size: 14px;">微信</span>
                  <span style="font-size: 18px;margin-top: 6px;">{{
                      (homeInfo.wxOrderCount / (homeInfo.zfbOrderCount + homeInfo.wxOrderCount) * 100).toFixed(2)
                    }}%</span>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 31%;background-color: #f5f8ff;height: 110px;margin-left: 20px; padding: 10px;">
            <span style="margin-left: 10px;margin-top: 10px;font-size: 14px;">成功率分析</span>
            <div style="display: flex;margin-left: 10px;margin-top: 10px;justify-content: center;">
              <div style="margin-left: 20px; display: flex;">
                <el-progress :color="'#4fcb7d'" :show-text="false" :width="50" type="circle"
                             :percentage="62.6"></el-progress>
                <div style="display: flex;flex-direction: column;margin-left: 10px;">
                  <span style="color: #a0a3b2;font-size: 14px;">支付宝</span>
                  <span style="font-size: 18px;margin-top: 6px;">{{
                      ((homeInfo.zfbSuccessOrderCount / homeInfo.orderCount) * 100).toFixed(2)
                    }}%</span>
                </div>
              </div>
              <div style="margin-left: 50px;display: flex;">
                <el-progress :color="'#6598fd'" :show-text="false" :width="50" type="circle"
                             :percentage="17.2"></el-progress>
                <div style="display: flex;flex-direction: column;margin-left: 10px;">
                  <span style="color: #a0a3b2;font-size: 14px;"> 微信</span>
                  <span style="font-size: 18px;margin-top: 6px;">{{
                      ((homeInfo.wxSuccessOrderCount / homeInfo.orderCount) * 100).toFixed(2)
                    }}%</span>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 31%;background-color: #f5f8ff;height: 110px;margin-left: 20px; padding: 10px;">
            <span style="margin-left: 10px;margin-top: 10px;font-size: 14px;">金额分析</span>
            <div style="display: flex;margin-left: 10px;margin-top: 10px;justify-content: center;">
              <div style="margin-left: 20px; display: flex;">
                <el-progress :color="'#4fcb7d'" :show-text="false" :width="50" type="circle"
                             :percentage="62.6"></el-progress>
                <div style="display: flex;flex-direction: column;margin-left: 10px;">
                  <span style="color: #a0a3b2;font-size: 14px;">支付宝</span>
                  <span style="font-size: 18px;margin-top: 6px;">{{ homeInfo.zfbSuccessOrderPrice }}元</span>
                </div>
              </div>
              <div style="margin-left: 50px;display: flex;">
                <el-progress :color="'#6598fd'" :show-text="false" :width="50" type="circle"
                             :percentage="17.2"></el-progress>
                <div style="display: flex;flex-direction: column;margin-left: 10px;">
                  <span style="color: #a0a3b2;font-size: 14px;">微信</span>
                  <span style="font-size: 18px;margin-top: 6px;">{{ homeInfo.wxSuccessOrderPrice }}元</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card
        style="border-top-left-radius: 10px;border-top-right-radius: 10px;margin-top: 15px;text-align: left;padding-bottom: 20px;"
        class="diyBack">
      <div style="margin-top: 10px;margin-left: 40px;display: flex;justify-content: space-between;">
        <span style="font-size: 18px;font-weight: bold;">用户分布</span>
        <span style="font-size: 18px;font-weight: bold;margin-right: 41%;">销售额渠道占比</span>
      </div>
      <div
          style="margin-top: 10px;border-top: 1px solid #dbe2f0;padding-top: 10px;padding-left: 40px;display: flex;justify-content: space-between;">
        <div style="display: flex;align-items: center;">
          <div style="margin-top: 20px;display: flex;flex-direction: column;">
            <div>
              <el-tag size="mini" color="#5158f8" style="width: 23px;height: 15px;"></el-tag>
              <el-tag size="mini" color="#6d71f6" style="width: 23px;height: 15px;margin-left: 10px;"></el-tag>
              <el-tag size="mini" color="#838bf8" style="width: 23px;height: 15px;margin-left: 10px;"></el-tag>
              <el-tag size="mini" color="#b1b8fc" style="width: 23px;height: 15px;margin-left: 10px;"></el-tag>
              <el-tag size="mini" color="#ccd3fb" style="width: 23px;height: 15px;margin-left: 10px;"></el-tag>
              <el-tag size="mini" color="#e1e9fd" style="width: 23px;height: 15px;margin-left: 10px;"></el-tag>
              <el-tag size="mini" color="#efefef" style="width: 23px;height: 15px;margin-left: 10px;"></el-tag>
            </div>
            <span style="font-size: 14px;margin-left: 4px;">高</span>
            <div id="yhfbEs" style="width: 700px;height: 400px;"></div>
          </div>
          <div>
            <div id="xselbzbEs" style="width: 400px;height: 400px;"></div>
          </div>
          <div>
            <el-row>
              <el-col style="display: flex;align-items: center;text-align: left;padding-bottom: 20px;" v-for="(item,index) in homeInfo.zxt2">
                <div style="width: 10px;height: 10px;border-radius: 50%;background-color: #5157f7;"></div>
                <div style="display: flex;justify-content: space-between;align-items: center;width: 250px;">
                  <div>
                    <span style="font-size: 14px;margin-left: 10px;">{{ item.tenantId == 3 ? '优胜' : item.tenantId == 5 ? '文慧付' : item.tenantId == 6 ? '汇付' : '汇付' }}</span>
                    <span style="color: #e3e3e3;margin-left: 10px;">|</span>
                    <span style="font-size: 14px;color: #9092a1;margin-left: 10px;">{{((item.money / getSum()) * 100).toFixed(2)}}%</span>
                  </div>
                  <span style="font-size: 14px;margin-left: 40px;">￥{{item.money}}元</span>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import china from '@/utils/china.json'
import {homeTotalPrice} from "@/api/order";

export default {
  name: "index",
  data() {
    return {
      searchData: '',
      homeInfo: {},
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      topList: [
        {name: "总交易额(元)", num: 0},
        {name: "交易笔数(笔)", num: 0},
        {name: "退款金额(元)", num: 0},
        {name: "退款笔数(笔)", num: 0},
      ]
    }
  },
  mounted() {
    this.initYhfbEs();
    this.homeTotalPriceInfo();
  },
  methods: {
    getSum(){
      let a = 0;
      this.homeInfo.zxt2.forEach(x =>{
        a += x.money
      })
      return a;
    },
    homeTotalPriceInfo() {
      console.log(this.searchData)
      let param = {
        mchId: this.$store.getters.userInfo.id.startsWith("M") ? this.$store.getters.userInfo.id : null,
        aid: this.$store.getters.userInfo.id.startsWith("A") ? this.$store.getters.userInfo.id : null
      }
      if (this.searchData){
        param.startTime = this.searchData[0]
        param.endTime = this.searchData[1]
      }
      homeTotalPrice(param).then(res => {
        res.data.zxt.reverse()
        this.homeInfo = res.data
        this.topList[0].num = this.homeInfo.totalMoney
        this.topList[1].num = this.homeInfo.orderCount
        this.topList[2].num = this.homeInfo.returnOrderMoney
        this.topList[3].num = this.homeInfo.returnOrderCount
        this.initXdyhEs();
        this.initXzyhEs();
        this.initXseEs();
      })
    },
    initXseEs() {
      let bgColor = '#fff';
      let title = '销售额';
      let color = ['#ff5d42', '#5157f7', '#6599fe', '#37cbcb', '#4fcb7f', '#f9cc13', '#ff4272'];
      let echartData = [];
      this.homeInfo.zxt2.forEach(x =>{
        echartData.push({
          name: x.tenantId == 3 ? '优胜' : x.tenantId == 5 ? '文慧付' : x.tenantId = 6 ? '汇付' : '',
          value: x.money
        })
      })
      let formatNumber = function (num) {
        let reg = /(?=(\B)(\d{3})+$)/g;
        return num.toString().replace(reg, ',');
      }
      let total = echartData.reduce((a, b) => {
        return a + b.value * 1
      }, 0);
      let init = echarts.init(document.getElementById('xselbzbEs'));
      init.setOption({
        backgroundColor: bgColor,
        color: color,
        tooltip: {
            trigger: 'item'
        },
        title: [{
          text: '{name|' + title + '}\n{val|' + '￥' + formatNumber(total) + '}',
          top: 'center',
          left: 'center',
          textStyle: {
            rich: {
              name: {
                fontSize: 14,
                fontWeight: 'normal',
                color: '#666666',
                padding: [10, 0]
              },
              val: {
                fontSize: 32,
                fontWeight: 'bold',
                color: '#333333',
              }
            }
          }
        }],
        series: [{
          type: 'pie',
          selectedMode: 'single',
          radius: ['45%', '60%'],
          center: ['50%', '50%'],
          data: echartData,
          hoverAnimation: true,
          itemStyle: {
            normal: {
              borderColor: bgColor,
              borderWidth: 2
            }
          },
          labelLine: {
            normal: {
              length: 20,
              length2: 120,
              lineStyle: {
                color: '#e6e6e6'
              }
            }
          },
          label: {
            show: false

          },
        }]
      })
    },
    initYhfbEs() {
      let outname = ["南海诸岛", '北京', '天津', '上海', '重庆', '河北', '河南', '云南', '辽宁', '黑龙江', '湖南', '安徽', '山东', '新疆', '江苏', '浙江', '江西', '湖北', '广西', '甘肃', '山西', '内蒙古', '陕西', '吉林', '福建', '贵州', '广东', '青海', '西藏', '四川', '宁夏', '海南', '台湾', '香港', '澳门'];
      let outvalue = [0, 524, 13, 140, 75, 13, 83, 11, 19, 15, 69, 260, 39, 4, 31, 104, 36, 1052, 33, 347, 9, 157, 22, 4, 18, 5, 6398, 41, 0, 484, 404, 22, 3, 5, 225];
      let outdata = [];
      for (let i = 0; i < outname.length; i++) {
        outdata.push({
          name: outname[i],
          value: outvalue[i]
        })
      }
      let init = echarts.init(document.getElementById('yhfbEs'));
      echarts.registerMap('china', china);
      init.setOption({
        tooltip: {
          show: true,
          formatter: function (params) {
            return '&nbsp;&nbsp;' + params.name + '&nbsp;&nbsp;&nbsp;' + params.value + '人&nbsp;&nbsp;';
          }
        },
        visualMap: {
          show: false,
          type: 'piecewise',
          left: '15',
          bottom: '15',
          itemWidth: 27,
          itemHeight: 15,
          textStyle: {
            color: '#333333',
            fontSize: 14,
          },
          pieces: [{
            min: 500,
            label: '>500',
          }, {
            max: 500,
            min: 200,
            label: '200-500',
          }, {
            max: 200,
            min: 0,
            label: '<200',
          }, {
            value: 0,
            label: '无数据',
          },],
          inRange: {
            color: ['#5258f8', '#6d71f6', '#838bf8', '#b1b7fb', '#ccd3fb', '#e1e9fd', '#efefef']
          },
          outOfRange: {
            color: ['#999999']
          }
        },
        geo: {
          map: 'china',
          show: true,
          roam: false,
          label: {
            emphasis: {
              show: false
            }
          },
          itemStyle: {
            normal: {
              borderColor: 'rgba(0,63,140,0.2)',
              shadowColor: 'rgba(0,63,140,0.2)',
              shadowOffsetY: 20,
              shadowBlur: 30
            }
          }
        },
        series: [{
          type: 'map',
          map: 'china',
          aspectScale: 0.75,
          //zoom:1.1,
          label: {
            normal: {
              show: true,
              color: '#ffffff'
            },
            emphasis: {
              show: false,
            }
          },
          itemStyle: {
            normal: {
              areaColor: '#B2CAE0',
              borderColor: '#fff',
              borderWidth: 1,
            },
            emphasis: {
              areaColor: '#FFAE00',
            }
          },
          data: outdata
        }]
      })
    },
    initXzyhEs() {
      let init = echarts.init(document.getElementById('xzyhEs'));
      init.setOption({
        backgroundColor: '#fff',
        title: {
          text: ''
        },
        tooltip: {
          formatter: `{c}`,
          textStyle: {
            color: '#fff'
          },
          padding: [9, 16],
          trigger: 'axis',
          axisPointer: {
            z: 0,
            type: 'line',
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 1,
                  color: '#D5D9FB'
                },
                  {
                    offset: 0,
                    color: '#FED6F2'
                  }
                ]
              },
              opacity: 1,
              width: 20
            }
          }
        },
        xAxis: {
          type: 'category',
          data:  this.homeInfo.zxt ? this.homeInfo.zxt.map(x => x.label) : [],
          // 刻度是否显示
          axisTick: {
            alignWithLabel: true,
            show: false
          },
          // 轴线是否显示
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#CCCCCC',
            fontSize: 10
          }

        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#CCCCCC',
            fontSize: 10
          }
        },
        series: [{
          type: 'line',
          smooth: true,
          showSymbol: true,
          data: this.homeInfo.zxt ? this.homeInfo.zxt.map(x => x.value2) : [],
          itemStyle: {
            normal: {
              borderWidth: 20,
              //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位
              color: new echarts.graphic.LinearGradient(
                  1,
                  0,
                  0,
                  0,
                  [{
                    offset: 0,
                    color: '#fe5d40'
                  },
                    {
                      offset: 0.6,
                      color: '#fd826e'
                    },
                    {
                      offset: 1,
                      color: '#f5e1db'
                    }
                  ]
              ),
              borderColor: '#5056f7'
            }
          },
          lineStyle: {
            width: 3,
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowOffsetY: 10,
            shadowBlur: 10
          },
          areaStyle: {
            opacity: 1,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: '#fff2f2'
              },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, .5)'
                }
              ],
              global: false
            }
          }
        }, {
          z: -1,
          barWidth: 20,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          type: 'bar',
          showBackground: true,
          backgroundStyle: {
            color: '#F9F9F9'
          }
        }]
      })
    },
    initXdyhEs() {
      let init = echarts.init(document.getElementById('xdyhEs'));
      init.setOption({
        backgroundColor: '#fff',
        title: {
          text: ''
        },
        tooltip: {
          formatter: `{a}`,
          textStyle: {
            color: '#fff'
          },
          padding: [9, 16],
          trigger: 'axis',
          axisPointer: {
            z: 0,
            type: 'line',
            lineStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 1,
                  color: '#D5D9FB'
                },
                  {
                    offset: 0,
                    color: '#FED6F2'
                  }
                ]
              },
              opacity: 1,
              width: 20
            }
          }
        },
        xAxis: {
          type: 'category',
          data: this.homeInfo.zxt ? this.homeInfo.zxt.map(x => x.label) : [],
          // 刻度是否显示
          axisTick: {
            show: false,
            alignWithLabel: true
          },
          // 轴线是否显示
          axisLine: {
            show: true
          },
          axisLabel: {
            color: '#CCCCCC',
            fontSize: 10
          }

        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#CCCCCC',
            fontSize: 10
          }
        },
        series: [{
          type: 'line',
          name: 'value',
          smooth: true,
          showSymbol: true,
          data: this.homeInfo.zxt ? this.homeInfo.zxt.map(x => x.value) : [],
          itemStyle: {
            normal: {
              borderWidth: 20,
              //4个参数用于配置渐变色的起止位置, 这4个参数依次对应右/下/左/上四个方位
              color: new echarts.graphic.LinearGradient(
                  1,
                  0,
                  0,
                  0,
                  [{
                    offset: 0,
                    color: '#5056f9'
                  },
                    {
                      offset: 0.6,
                      color: '#7480f5'
                    },
                    {
                      offset: 1,
                      color: '#e6ecfd'
                    }
                  ]
              ),
              borderColor: '#5056f7'
            }
          },
          tooltip: {
            formatter: function (value) {
              return value + ' °C';
            }
          },
          lineStyle: {
            width: 3,
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowOffsetY: 10,
            shadowBlur: 10
          },
          areaStyle: {
            opacity: 1,
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: '#dbe7fc'
              },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, .5)'
                }
              ],
              global: false
            }
          }
        }, {
          z: -1,
          name: 'value',
          barWidth: 20,
          data:  [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          type: 'bar',
          showBackground: false,
          backgroundStyle: {
            color: '#F9F9F9'
          }
        }]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.diyBack {
  background: #ffffff;

  /deep/ .el-card__body {
    padding: 0;
  }
}

* {
  font-family: 'PingFang SC';
}
</style>