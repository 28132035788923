<template>
  <div class="shande-box" :ref="ref">
      <div class="shande-box-icon">
        <slot name="icon"></slot>
      </div>
      <div class="shande-box-body">
          <template v-if="resver">
             <div class="shande-box-label">
                {{title}}
            </div>
            <div>
              <slot ></slot>
            </div>
          </template>
          <template v-else>
            <div>
              <slot ></slot>
            </div>
            <div class="shande-box-label">
                {{title}}
            </div>
          </template>
          
      </div>
  </div>
</template>

<script>
import autoResize from '../autoResize'
import { deepMerge,deepClone } from '../util'

export default {
  name: 'RegisterBox',
  mixins: [autoResize],
  props: {
    title: {
      type: String,
      default: '清输入标题'
    },
    resver: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ref: 'shande-box',
      border: ['left-top', 'right-top', 'left-bottom', 'right-bottom'],
      defaultColor: ['#4fd2dd', '#235fa7'],
      mergedColor: []
    }
  },
  watch: {
    color () {
      const { mergeColor } = this
      mergeColor()
    }
  },
  methods: {
    mergeColor () {
      const { color, defaultColor } = this
      this.mergedColor =  deepMerge(deepClone(defaultColor, true), color || {})
    }
  },
  mounted () {
    const { mergeColor } = this
    mergeColor()
  }
}
</script>

<style lang="scss">
    .shande-box {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        .shande-box-label {
            color: #fff;
            opacity: 0.5;
            font-size: 12px;
            font-weight: 400;
        }

    }
	
</style>