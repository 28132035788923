<template>
  <div>
    <Nav/>
    <div>
      <router-view/>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/Nav";
export default {
  name: "Content",
  components: {Nav}
}
</script>

<style scoped>

</style>