<template>
  <div style="display: flex;justify-content: center;flex-direction: column;align-items: center">
    <svg-icon icon-class="403" style="width: 500px;height: 500px"></svg-icon>
    <span style="margin-top: 30px;font-size: 30px;">暂无权限访问页面</span>
  </div>
</template>

<script>
export default {
  name: "payTg"
}
</script>

<style scoped>

</style>