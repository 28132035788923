<template>
  <div style="margin-top: 20px;">
    <el-card v-if="!addFlag" shadow="never">
      <el-form :model="pieceForm" ref="pieceForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="" prop="name">
          <el-select clearable size="small" v-model="pieceForm.aId" placeholder="请选择代理商" style="">
            <el-option v-for="(item,index) in agentList" :key="index" :label="item.agentName"
                       :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="name">
          <el-input clearable size="small" placeholder="商户号" style="width: 200px;margin-left: 20px;"
                    v-model="pieceForm.mId"></el-input>
        </el-form-item>
        <el-form-item prop="date">
          <el-date-picker
              style="margin-left: 60px;margin-top: 7px;margin-left: 20px;"
              size="small"
              v-model="pieceForm.date"
              type="datetimerange"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              align="right">
          </el-date-picker>
        </el-form-item>
        <br/>
        <el-form-item label="" prop="name">
          <el-input clearable size="small" placeholder="联系人手机号" style="width: 200px;margin-left: 20px;"
                    v-model="pieceForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="" prop="name">
          <el-input clearable size="small" placeholder="商户联系人" style="width: 200px;margin-left: 20px;"
                    v-model="pieceForm.name"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="initPieceList">查询</el-button>
        <el-button type="default" icon="el-icon-refresh-right" size="mini" @click="restQuery">重置</el-button>
      </div>
      <el-divider></el-divider>
<!--      <el-button type="primary" icon="el-icon-plus" size="mini" @click="addPiece" class="blink-button">-->
<!--        发起进件-->
<!--      </el-button>-->
      <div style="margin-top: 15px;">
        <el-table
            height="705"
            v-loading="loading"
            element-loading-text="拼命加载中..."
            :header-cell-style="{backgroundColor: '#fafafa'}"
            :data="tableData"
            :cell-style="{backgroundColor: '#ffffff'}"
            style="width: 100%">
          <el-table-column
              width="150"
              prop="mname">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">所属代理商</span>
            </template>
            <template slot-scope="{row}">
              <div style="display: flex;flex-direction: column;">
                <span style="color: #000000;font-weight: 800;">{{ row.agentName }}</span>
                <span style="color: #b2b2b2;font-size: 12px;">{{ row.agentId }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              width="150"
              prop="mid">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">所属应用</span>
            </template>
            <template slot-scope="{row}">
              <div style="display: flex;flex-direction: column;">
                <span style="color: #000000;font-weight: 800;">{{ row.mname }}</span>
                <span style="color: #b2b2b2;font-size: 12px;">{{ row.mchId }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              width="150"
              prop="mid">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">所属商户</span>
            </template>
            <template slot-scope="{row}">
              <div style="display: flex;flex-direction: column;">
                <span style="color: #000000;font-weight: 800;">{{ row.mchFor }}</span>
                <span style="color: #b2b2b2;font-size: 12px;">{{ row.id }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="mphone">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">商户类型</span>
            </template>
            <template slot-scope="{row}">
              <el-tag size="mini" effect="dark" type="info" v-if="row.incomType == 1">小微</el-tag>
              <div v-else>
                <el-tag size="mini" effect="dark" type="primary" v-if="row.aptitude == 0">个体</el-tag>
                <el-tag size="mini" effect="dark" type="success" v-else>企业</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="userName">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">商户联系人</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="uid">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">商户状态</span>
            </template>
            <template slot-scope="{row}">
              <el-tag size="mini" effect="dark" type="primary" v-if="row.status == 0">正常</el-tag>
              <el-tag size="mini" effect="dark" type="warning" v-if="row.status == 1">禁用</el-tag>
              <el-tag size="mini" effect="dark" type="error" v-if="row.status == 2">停用</el-tag>
            </template>
          </el-table-column>
          <!--          <el-table-column-->
          <!--              prop="uid">-->
          <!--            <template slot="header" slot-scope="scope">-->
          <!--              <span style="font-weight: normal">流水号</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column
              prop="uid">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">进件状态</span>
            </template>
            <template slot-scope="{row}">
              <el-tag size="mini" v-if="row.incomStatus == -1">草稿</el-tag>
              <el-tag size="mini" type="primary" v-if="row.incomStatus == 0">待审核</el-tag>
              <el-tag size="mini" effect="plain" type="warning" v-if="row.incomStatus == 1">审核中</el-tag>
              <el-tag size="mini" effect="plain" type="error" v-if="row.incomStatus == 2">进件成功</el-tag>
              <el-tag size="mini" effect="plain" type="error" v-if="row.incomStatus == 3">审核失败</el-tag>
            </template>
          </el-table-column>
          <el-table-column
              prop="uid">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">备注</span>
            </template>
            <template slot-scope="{row}">
              <span style="margin-left: 5px;">{{ row.remark ? row.remark : '/' }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="createTime">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">创建日期</span>
            </template>
            <template slot-scope="{row}">
              <span style="margin-left: 5px;">{{ row.createTime.replace("T", " ") }}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="230"
              align="center">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal;">操作</span>
            </template>
            <template slot-scope="{row}">
                <el-button type="text" @click="addPiece2(row)">查看资料</el-button>
            </template>
          </el-table-column>
        </el-table>
        <pagination
            :total="total"
            :pageNum.sync="pageNum"
            :limit.sync="pageSize"
            @pagination="initPieceList()"
        />
      </div>
    </el-card>
    <ViewPiece :form-value="parentValue" v-else></ViewPiece>
  </div>
</template>

<script>
import Nav from "@/components/Nav";
import {agentList} from "@/api/user";
import {pieceList} from "@/api/mch";
import ViewPiece from "@/views/mch/components/ViewPiece";
import {initData} from "@/api/app";
import Pagination from "@/components/Pagination";
export default {
  name: "inputPiece",
  components: {
    Nav,
    ViewPiece,
    Pagination
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      agentList: [],
      parentValue:{},
      loading: false,
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      addFlag: false,
      pieceForm: {
        aId: '',
        mId: '',
        mchStatus: '',
        pieceStatus: '',
        name: '',
        phone: '',
        date: '',
        startTime: '',
        endTime: ''
      }
    }
  },
  mounted() {
    this.initAgentList();
    this.initPieceList();
    this.initCategory();
  },
  methods: {
    restQuery(){
      this.pieceForm = {
        aId: '',
        mId: '',
        mchStatus: '',
        pieceStatus: '',
        name: '',
        phone: '',
        date: '',
        startTime: '',
        endTime: ''
      }
      this.initPieceList()
    },
    initCategory() {
      initData().then(res => {
        if (res.success) {
          if (!localStorage.getItem("initData")) {
            localStorage.setItem("initData", JSON.stringify(res.data))
          }
        }
      })
    },
    handleSizeChange() {

    },
    changeType() {
      this.addFlag = false;
     this.initPieceList()
    },
    addPiece() {
      this.addFlag = true;
      this.parentValue = {};
    },
    addPiece2(row) {
      this.addFlag = true;
      this.parentValue = row;
    },
    initAgentList() {
      let param = {
        pageNum: 1,
        pageSize: 1000
      }
      agentList(param).then(res => {
        if (res.success) {
          this.agentList = res.data.list
        }
      })
    },
    initPieceList() {
      this.loading = true;
      let param = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        name: this.pieceForm.name || null,
        agentId: this.$store.getters.userInfo.userName === 'admin' ? null : this.pieceForm.aId || this.$store.getters.userInfo.id,
        status: 0
      }
      pieceList(param).then(res => {
        if (res.success) {
          this.loading = false;
          this.total = res.data.total
          this.tableData = res.data.list;
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.drawer-content {
  padding-bottom: 40px;
  max-height: calc(100vh - 100px); /* Adjust this value as needed */
  overflow-y: auto;
}

.drawer-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 17%;
}

.demo-ruleForm2 {
  display: flex;
  flex-flow: wrap;
}

.demo-ruleForm2 .el-form-item {
  display: flex;
  margin: 20px;
  width: 280px;
  height: 45px;
  flex-direction: column;
  text-align-last: start;
}

/deep/ .demo-ruleForm2 .el-form-item .el-form-item__content {
  margin-left: 0px !important;
}

/deep/ .demo-ruleForm2 .el-form-item .el-form-item__label {
  height: 30px !important;
  width: 148px !important;
}

.demo-ruleForm {
  display: flex;
  flex-flow: wrap;
  width: 90%;
}

/deep/ .demo-ruleForm .el-form-item__content {
  margin-left: 0px !important;
}

/deep/ .el-divider--horizontal {
  margin-top: 10px;
}

.my-autocomplete {

li {
  line-height: normal;

.name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.addr {
  font-size: 12px;
  color: #b4b4b4;
}

.highlighted .addr {
  color: #ddd;
}

}
}

.blink-button:hover {
  background-image: linear-gradient(to right, rgb(250, 82, 82), rgb(250, 82, 82) 16.65%, rgb(190, 75, 219) 16.65%, rgb(190, 75, 219) 33.3%, rgb(76, 110, 245) 33.3%, rgb(76, 110, 245) 49.95%, rgb(64, 192, 87) 49.95%, rgb(64, 192, 87) 66.6%, rgb(250, 176, 5) 66.6%, rgb(250, 176, 5) 83.25%, rgb(253, 126, 20) 83.25%, rgb(253, 126, 20) 100%, rgb(250, 82, 82) 100%);
  animation: var(--timing) linear dance6123 infinite;
  transform: scale(1.1) translateY(-1px);
}

.blink-button {
  --width: 100px;
  --timing: 2s;
  border: 0;
  width: var(--width);
  color: #fff;
  transition: all 0.2s;
}

@keyframes dance6123 {
  to {
    background-position: var(--width);
  }
}
/deep/.el-table__body-wrapper{
  overflow-y: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }
}
</style>