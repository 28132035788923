<template>
  <div class="border-box" :ref="ref">

    <div class="border-box-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import autoResize from './autoResize'

export default {
  name: 'Box',
  mixins: [autoResize],
  props: {
    color: {
      type: Array,
      default: () => ([])
    },
    backgroundColor: {
      type: String,
      default: 'transparent'
    }
  },
  data () {
    return {
      ref: 'border-box',
      border: ['left-top', 'right-top', 'left-bottom', 'right-bottom'],
      defaultColor: ['#4fd2dd', '#235fa7'],
      mergedColor: []
    }
  },
  watch: {
    color () {
      const { mergeColor } = this
      mergeColor()
    }
  },
  methods: {
    mergeColor () {
      const { color, defaultColor } = this
      this.mergedColor = [...defaultColor, ...color]
    }
  },
  mounted () {
    const { mergeColor } = this
    mergeColor()
  }
}
</script>

<style lang="scss">
.border-box {
  position: relative;
  height: 100%;
  background: #010A57;
  box-shadow: 0px 0 20px #ffffff6b inset;
  margin: 5px;

  .border-box-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
</style>