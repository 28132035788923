var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:_vm.ref,staticClass:"dv-scroll-board"},[(_vm.header.length && _vm.mergedConfig)?_c('div',{staticClass:"header",style:(`background-color: ${_vm.mergedConfig.headerBGC};`)},_vm._l((_vm.header),function(headerItem,i){return _c('div',{key:`${headerItem}${i}`,staticClass:"header-item",style:(`
        height: ${_vm.mergedConfig.headerHeight}px;
        line-height: ${_vm.mergedConfig.headerHeight}px;
        width: ${_vm.widths[i]}px;
      `),attrs:{"align":_vm.aligns[i]},domProps:{"innerHTML":_vm._s(headerItem)}})}),0):_vm._e(),(_vm.mergedConfig)?_c('div',{staticClass:"rows",style:(`height: ${_vm.height - (_vm.header.length ? _vm.mergedConfig.headerHeight : 0)}px;`)},_vm._l((_vm.rows),function(row,ri){return _c('div',{key:`${row.toString()}${row.scroll}`,staticClass:"row-item",style:(`
        ${_vm.heights[ri] > 0 ? '' : 'display: none;'}
        height: ${_vm.heights[ri]}px;
        line-height: ${_vm.heights[ri]}px;
        background-color: ${_vm.mergedConfig[row.rowIndex % 2 === 0 ? 'evenRowBGC' : 'oddRowBGC']};
      `)},_vm._l((row.ceils),function(ceil,ci){return _c('div',{key:`${ceil}${ri}${ci}`,staticClass:"ceil",style:(`width: ${_vm.widths[ci]}px;`),attrs:{"align":_vm.aligns[ci]},domProps:{"innerHTML":_vm._s(ceil)},on:{"click":function($event){return _vm.emitEvent('click', ri, ci, row, ceil)},"mouseenter":function($event){return _vm.handleHover(true, ri, ci, row, ceil)},"mouseleave":function($event){return _vm.handleHover(false)}}})}),0)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }