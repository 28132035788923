<template>
  <div class="kk-box" :ref="ref">
    <div style="text-align: center; color: #fff">
      还有
      <span :class="'numBc' + day[0]">
        <!--  {{day[0]}} -->
      </span>
      <span :class="'numBc' + day[1]">
        <!-- {{day[1]}} -->
      </span>
      天
      <span :class="'numBc' + hour[0]">
        <!-- {{hour[0]}} -->
      </span>
      <span :class="'numBc' + hour[1]">
        <!--  {{hour[1]}} -->
      </span>
      时
      <span :class="'numBc' + minute[0]">
        <!-- {{minute[0]}} -->
      </span>
      <span :class="'numBc' + minute[1]">
        <!-- {{minute[1]}} -->
      </span>
      分
      <span :class="'numBc' + second[0]">
        <!-- {{second[0]}} -->
      </span>
      <span :class="'numBc' + second[1]">
        <!-- {{secondnumBc6[1]}} -->
      </span>
      秒
    </div>
  </div>
</template>

<script>
import autoResize from '../autoResize'
import { deepMerge, deepClone } from '../util'

import moment, { isMoment } from 'moment'

const nextMonthStart = moment().add(1, 'months').startOf('month')

export default {
  name: 'RegisterBox',
  mixins: [autoResize],
  props: {
    target: {
      type: Object,
      default: ()=> (nextMonthStart),
    },
  },
  data() {
    return {
      ref: 'border-box',
      border: ['left-top', 'right-top', 'left-bottom', 'right-bottom'],
      defaultColor: ['#4fd2dd', '#235fa7'],
      mergedColor: [],
      day: ['1', '2'],
      hour: ['1', '2'],
      minute: ['1', '2'],
      second: ['1', '2'],
    }
  },
  watch: {
    color() {
      const { mergeColor } = this
      mergeColor()
    },
  },
  methods: {
    timeDiffer() {
      if (!this.target) {
        console.error('days com can‘t set taget time is null')
      }
      let currentTime = moment()
      let difDays = this.target.diff(currentTime, 'day').toString().padStart(2, '0')
      let difHours = (this.target.diff(currentTime, 'hour') % 24).toString().padStart(2, '0')
      let difMinutes = (this.target.diff(currentTime, 'minute') % 60).toString().padStart(2, '0')
      let difSeconds = (this.target.diff(currentTime, 'second') % 60).toString().padStart(2, '0')
      this.day = difDays
      this.hour = difHours
      this.minute = difMinutes
      this.second = difSeconds
      this.$emit('suplyData', difDays, currentTime.daysInMonth(), currentTime.format('MM'), currentTime.format('YYYY年MM月DD日'), currentTime.format('HH:mm:ss'), currentTime.format('dddd'))
    },
    mergeColor() {
      const { color, defaultColor } = this
      this.mergedColor = deepMerge(deepClone(defaultColor, true), color || {})
    },
  },
  mounted() {
    const { mergeColor, timeDiffer } = this
    mergeColor()
    setInterval(timeDiffer, 1000)
  },
}
</script>

<style lang="scss">
.time-box {
  width: 47px;
  display: inline-block;
  height: 50px;
  margin: 5px;
  border: 1px solid;
  border-image: linear-gradient(180deg, #1363dc, #0f4281) 10 10;
  background: linear-gradient(0deg, #170565 0%, #2a0188 100%);
}
.numBc0,
.numBc1,
.numBc2,
.numBc3,
.numBc4,
.numBc5,
.numBc6,
.numBc7,
.numBc8,
.numBc9 {
  // margin: 0 1px;
  width: 37px;
  display: inline-block;
  height: 44px;
  background: url(https://image.mobduos.com/upload/image/manager/2021/01/26/5968db8b-65be-4231-be9f-f491460fdfad.png);
  background-clip: border-box;
  background-size: cover;
  background-repeat: no-repeat;
}
.numBc0 {
  background-position-x: 0px;
}
.numBc1 {
  background-position-x: -52px;
}
.numBc2 {
  background-position-x: -104px;
}
.numBc3 {
  background-position-x: -156px;
}
.numBc4 {
  background-position-x: -208px;
}
.numBc5 {
  background-position-x: -260px;
}
.numBc6 {
  background-position-x: -312px;
}
.numBc7 {
  background-position-x: -364px;
}
.numBc8 {
  background-position-x: -416px;
}
.numBc9 {
  background-position-x: -468px;
}
</style>