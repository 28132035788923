import http from '@/libs/axios';

let pro = process.env.VUE_APP_BASE_API

/**
 * 新增应用
 * @param data
 * @returns {*}
 */
export function addApp(data){
    return http({
        url: `${pro}/v1/app/add`,
        method: 'POST',
        requestBody: true,
        data
    })
}

/**
 * 查询应用列表
 * @param data
 * @returns {*}
 */
export function appList(data){
    return http({
        url: `${pro}/v1/app/list`,
        method: 'POST',
        requestBody: true,
        data
    })
}

/**
 * 加载城市银行类目数据
 * @returns {*}
 */
export function initData(){
    return http({
        url: `${pro}/v1/app/initData`,
        method: 'GET'
    })
}