import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Board from "@/views/board/index";
import BigScreen from "@/views/bigscreen/index";
import Purse from "@/views/account/purse";
import Agent from "@/views/agent/index";
import Settings from "@/views/settings/index";
import User from "@/views/user/index";
import Vip from "@/views/vip/index";
import Content from "@/components/Content";
import Login from "@/views/user/login2";
import InputPieceSh from "@/views/mch/InputPieceSh";
import AuthPay from "@/views/user/authPay";
import H5Piece from "@/views/mch/h5_piece";
import Pay from "@/views/user/pay";
import WxPay from "@/views/user/wxPay";
import HfAddPiece from "@/views/mch/components/HfAddPiece";
import ChannelManager from '@/views/agent/TenantSettings'
import DriverManager from "@/views/mch/driverManager";
import PayTg from "@/views/order/payTg";

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'index',
        meta: {
            title: '首页',
            raking: 1,
            auth: ['A','M'],
            icon: 'el-icon-shouyexuanzhong'
        },
        component: Board
    },
    {
        path: '/pay',
        name: 'pay',
        hidden: true,
        meta: {
            title: '支付'
        },
        component: Pay
    },
    {
        path: '/wxPay',
        name: 'wxPay',
        hidden: true,
        meta: {
            title: '微信支付'
        },
        component: WxPay
    },
    {
        path: '/login',
        name: 'login',
        hidden: true,
        meta: {
            title: '登录'
        },
        component: Login
    },
    {
        path: '/h5-piece',
        name: 'h5-piece',
        hidden: true,
        meta: {
            title: '移动端进件'
        },
        component: H5Piece
    },
    {
        path: '/mch',
        name: 'mch',
        component: Content,
        meta: {
            title: '商户管理',
            raking: 3,
            auth: ['A','M'],
            icon: 'el-icon-hhq-tuiguangshang'
        },
        children: [
            {
                path: 'mchList',
                name: 'mchList',
                meta: {
                    auth: ['A','M'],
                    title: '商户列表'
                },
                component: () => import('@/views/mch/index.vue')
            },
            {
                path: 'inputPiece',
                name: 'inputPiece',
                meta: {
                    auth: ['A','M'],
                    title: '进件管理'
                },
                component: () => import('@/views/mch/inputPiece.vue')
            },
            {
                path: 'integral',
                name: 'integral',
                meta: {
                    auth: ['A','M'],
                    title: '轮询管理'
                },
                component: () => import('@/views/integral/index.vue')
            },
        ]
    },
    {
        path: '/order',
        name: 'order',
        meta: {
            title: '订单管理',
            raking: 4,
            auth: ['A','M'],
            icon: 'el-icon-dingdan'
        },
        component: Content,
        children: [
            {
                path: 'orderList',
                name: 'orderList',
                meta: {
                    auth: ['A','M'],
                    title: '订单列表'
                },
                component: () => import('@/views/order/index.vue')
            },
            {
                path: 'separate',
                name: 'separate',
                meta: {
                    auth: ['A'],
                    title: '分账管理'
                },
                component: () => import('@/views/order/separate.vue')
            },
            {
                path: 'settleList',
                name: 'settleList',
                meta: {
                    auth: ['A','M'],
                    title: '结算列表'
                },
                component: () => import('@/views/order/settleList.vue')
            }
        ]
    },
    {
        path: '/account',
        name: 'account',
        component: Purse,
        meta: {
            title: '账户管理',
            auth: ['A','M'],
            raking: 6,
            icon: 'el-icon-zhanghaoxinxi'
        },
        children: [
            {
                path: 'purse',
                name: 'purse',
                meta: {
                    auth: ['A','M'],
                    title: '钱包'
                },
                component: () => import('@/views/account/purse.vue')
            }
        ]
    },
    {
        path: '/authPay',
        name: 'authPay',
        hidden: true,
        meta: {
            title: '111'
        },
        component: AuthPay
    },
    {
        path: '/agent',
        name: 'agent',
        meta: {
            title: '代理商管理',
            raking: 2,
            auth: ['A'],
            icon: 'el-icon-jibenxinxi'
        },
        component: Content,
        children: [
            {
                path: 'agentList',
                name: 'agentList',
                meta: {
                    auth: ['A'],
                    title: '代理商列表'
                },
                component: () => import('@/views/agent/index.vue')
            },
            {
                path: 'rateTemplate',
                name: 'rateTemplate',
                meta: {
                    auth: ['A'],
                    isNew: true,
                    title: '费率配置'
                },
                component: () => import('@/views/agent/RateTemplate.vue')
            },
            // {
            //     path: 'tenantSettings',
            //     name: 'tenantSettings',
            //     meta: {
            //         auth: ['A'],
            //         isNew: true,
            //         title: '渠道配置'
            //     },
            //     component: () => import('@/views/agent/TenantSettings.vue')
            // }
        ]
    },
    {
        path: '/settings',
        name: 'settings',
        hidden: true,
        meta: {
            title: '3333'
        },
        component: Settings
    },
    {
        path: '/user',
        name: 'user',
        hidden: true,
        meta: {
            title: '用户管理'
        },
        component: User
    },
    {
        path: '/vip',
        name: 'vip',
        hidden: true,
        meta: {
            title: '1111'
        },
        component: Vip
    },
    {
        path: '/pieceManager',
        name: 'pieceManager',
        meta: {
            title: '审核管理',
            raking: 5,
            auth: ['A'],
            icon: 'el-icon-shenhe q'
        },
        component: InputPieceSh
    },
    {
        path: '/hfAddPiece',
        name: 'hfAddPiece',
        hidden: true,
        meta: {
            title: '1111'
        },
        component: HfAddPiece
    },
    {
        path: '/channelManager',
        name: 'channelManager',
        meta: {
            title: '通道配置',
            raking: 7,
            auth: ['A'],
            isNew: true,
            icon: 'el-icon-zijinzhifutongdao'
        },
        component: ChannelManager
    },
    {
        path: '/driverManager',
        name: 'driverManager',
        meta: {
            title: '终端配置',
            raking: 8,
            auth: ['A','M'],
            icon: 'el-icon-duozhongduantouzi'
        },
        component: DriverManager
    },
    {
        path: '/payTg',
        name: 'payTg',
        meta: {
            title: '支付托管',
            raking: 9,
            auth: ['A'],
            icon: 'el-icon-ewsdanxingwebtuoguan'
        },
        component: PayTg
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    }
})
let menus = [
    {index: "/", title: "数据看板", icon: "el-icon-data-line", hidden: ["A"]},
    {index: "/account/purse", title: "账户中心", icon: "el-icon-reading", hidden:["A"]},
    {index: "/agent/agentList", title: "代理商管理", icon: "el-icon-collection", hidden:["A"]},
    {index: "/mch/mchList", title: "商户管理", icon: "iconfont el-icon-jichupeizhi", hidden:["A"]},
    {index: "/mch/appStorge", title: "商户管理", icon: "iconfont el-icon-jichupeizhi", hidden:["A"]},
    {index: "/mch/inputPiece", title: "商户管理", icon: "iconfont el-icon-jichupeizhi", hidden:["A"]},
    {index: "/mch/store", title: "商户管理", icon: "iconfont el-icon-jichupeizhi", hidden:["A"]},
    {index: "/order/orderList", title: "订单管理", icon: "iconfont el-icon-jifen", hidden: ["A","M"]},
    {index: "/order/separate", title: "订单管理", icon: "iconfont el-icon-jifen", hidden: ["A","M"]},
    {index: "/integral", title: "轮询管理", icon: "iconfont el-icon-quanyi", hidden:["A"]},
    {index: "/pieceManager", title: "进件审核", icon: "iconfont el-icon-quanyi", hidden:["A"]},
    {index: "/vip", title: "设备管理", icon: "iconfont el-icon-shouye", hidden:["A"]},
    {index: "/settings", title: "系统管理", icon: "iconfont el-icon-peizhi", hidden:["A"]},
    {index: "/hfAddPiece", title: "系统管理", icon: "iconfont el-icon-peizhi", hidden:["A"]},
]

router.beforeEach((to, from, next) => {
    if (to.path == '/login' || to.path == '/pay' || to.path == '/authPay' || to.path == '/h5-piece' || to.path == '/wxPay') {
        next()
    } else {
        if (store.getters.token) {
            // let hidden = menus.filter(x => x.index === to.path)[0].hidden.indexOf(store.getters.userInfo.id.substring(0,1)) !== -1;
            // console.log(hidden,"-=----",to.path,store.getters.userInfo.id)
            // if (store.getters.userInfo.id.substring(0,1) == "M"){
            //     next("/order/list")
            // }else{
                next()
            // }
            // if (hidden) {
            //     next()
            // } else {
            //     next("/order/list")
            // }
        } else {
            next("/login")
        }
    }
})

export default router
