<template>
  <div style="max-width: 248px;">
    <el-card shadow="always" class="box-card">
      <div style="display: flex; justify-content: center; align-items: center;margin-top: 20px">
        <img src="../../assets/hss.png" width="35" style="border-radius: 8px">
        <span style="font-family: 'PingFang SC';font-size: 14px;margin-left: 10px;font-weight: bold">汇扫扫</span>
      </div>
      <div style="margin-top: 40px">
        <el-menu
            @select="getActiveMenuItem"
            :router="true"
            :default-active="getActiveMenuItem()"
            style="background-color: #ffffff"
            class="el-menu-vertical-demo">
          <div v-for="(item,index) in routeList">
            <el-menu-item :index="item.path" v-if="!item.children">
              <i :class="`icon iconfont ${item.meta.icon}`" style="width: 20px;height: 20px;color: #fc445d"
                 :style="{fontSize: item.meta.icon == 'el-icon-shouyexuanzhong' ? '22px' : '',marginLeft: item.meta.icon == 'el-icon-shouyexuanzhong' ? '-2px' : ''}"></i>
              <span slot="title" style="margin-left: 20px;">
                <el-badge v-if="item.meta.isNew" value="新">{{ item.meta.title }}</el-badge>
                <span v-else>{{ item.meta.title }}</span>
              </span>
            </el-menu-item>
            <el-submenu :index="item.path" v-else>
              <template slot="title" style="display: flex;">
                <i :class="`icon iconfont ${item.meta.icon}`" style="width: 20px;height: 20px;color: #fc445d"></i>
                <span slot="title" style="margin-left: 20px;">{{ item.meta.title }}</span>
              </template>
              <el-menu-item-group v-if="item.children">
                <el-menu-item v-if="" :route="item.path + '/' + children.path"
                              v-for="(children,index) in item.children.filter(x => x.meta.auth.indexOf($store.getters.userInfo.id.substr(0,1)) != -1)"
                              :index="item.path + '/' + children.path" :key="index">
                  <el-badge v-if="children.meta.isNew" value="新">{{ children.meta.title }}</el-badge>
                  <span v-else>{{ children.meta.title }}</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </div>
        </el-menu>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "LeftMenu",
  data() {
    return {
      routeList: [],
      host: ["hss.shizhipay.com", "localhost:9000"].includes(window.location.host),
      defaultActive: this.$route.fullPath,
      menus: [
        {
          index: "/",
          title: "数据看板",
          icon: "el-icon-data-line",
          hidden: this.$store.getters.userInfo.id.toString().startsWith("A")
        },
        {
          index: "/account/purse",
          title: "账户中心",
          icon: "el-icon-reading",
          hidden: this.$store.getters.userInfo.id.toString().startsWith("A")
        },
        {
          index: "/agent/agentList",
          title: "代理商管理",
          icon: "el-icon-collection",
          hidden: this.$store.getters.userInfo.id.toString().startsWith("A")
        },
        {
          index: "/mch/mchList",
          title: "商户管理",
          icon: "iconfont el-icon-jichupeizhi",
          hidden: this.$store.getters.userInfo.id.toString().startsWith("A")
        },
        // {index: "/pieceManager",title: "进件审核",icon: "iconfont el-icon-quanyi",hidden: this.$store.getters.userInfo.id.toString().startsWith("A") && this.$store.getters.userInfo.userName === 'admin'},
        {
          index: "/pieceManager",
          title: "进件审核",
          icon: "iconfont el-icon-quanyi",
          hidden: this.$store.getters.userInfo.id.toString().startsWith("A")
        },
        {index: "/order/orderList", title: "订单管理", icon: "iconfont el-icon-jifen", hidden: true},
        {
          index: "/integral",
          title: "轮询管理",
          icon: "iconfont el-icon-quanyi",
          hidden: this.$store.getters.userInfo.id.toString().startsWith("A")
        },
        {
          index: "/vip",
          title: "设备管理",
          icon: "iconfont el-icon-shouye",
          hidden: this.$store.getters.userInfo.id.toString().startsWith("A")
        },
        {
          index: "/settings",
          title: "系统管理",
          icon: "iconfont el-icon-peizhi",
          hidden: this.$store.getters.userInfo.id.toString().startsWith("A")
        },
      ],
      menuItems: [
        {index: "/board", route: "/board"},
        {index: "/mch/mchList", route: "/mch/mchList"},
        {index: "/mch/appStorge", route: "/mch/appStorge"},
        {index: "/mch/inputPiece", route: "/mch/inputPiece"},
        {index: "/mch/store", route: "/mch/store"},
        {index: "/mch/integral", route: "/mch/integral"},
        {index: "/mch/channelManager", route: "/mch/channelManager"},
        {index: "/mch/driverManager", route: "/mch/driverManager"},
        {index: "/order/payTg", route: "/order/payTg"},
        {index: "/agent/agentList", route: "/agent/agentList"},
        {index: "/agent/rateTemplate", route: "/agent/rateTemplate"},
        {index: "/integral", route: "/integral"},
        {index: "/pieceManager", route: "/pieceManager"},
        {index: "/order/orderList", route: "/order/orderList"},
        {index: "/order/separate", route: "/order/separate"},
        {index: "/order/settleList", route: "/order/settleList"},
        {index: "/question", route: "/question"},
        {index: "/settings", route: "/settings"},
        {index: "/user", route: "/user"},
        {index: "/vip", route: "/vip"},
      ]
    }
  },
  watch: {
    $route(e) {
      this.defaultActive = e.fullPath
      this.getActiveMenuItem()
    },
  },
  mounted() {
    this.sort(this.$router.options.routes)
  },
  methods: {
    sort(list) {
      list = list.filter(x => !x.hidden)
      for (let i = 0; i < list.length; i++) {
        for (let u = i + 1; u < list.length; u++) {
          if (parseFloat(list[i]['meta']["raking"]) > parseFloat(list[u]['meta']["raking"])) {
            let num = [];
            num = list[i];
            list[i] = list[u];
            list[u] = num;
          }
        }
      }
      this.routeList = list.filter(x => x.meta.auth.indexOf(this.$store.getters.userInfo.id.substr(0, 1)) != -1);
      console.log(this.routeList)
    },
    getActiveMenuItem() {
      for (const item of this.menuItems) {
        if (this.$route.fullPath === item.route) {
          console.log(this.$route.fullPath, item.index, this.defaultActive)
          return item.index;
        }
      }
      return this.host ? '/' : '/order/orderList';
    }
  }
}
</script>

<style lang="scss" scoped>
.menuItem-d {
  padding-left: 8px;
  border-radius: 8px;
  display: flex;
  width: 200px;
}

.menuItem {
  display: flex;
  height: 35px;
  margin-top: 15px;
  line-height: 35px;
}

/deep/ .el-menu {
  border: 0px;
}

.box-card {
  height: 1050px;
  border: 0px;
}

/deep/ .el-card .el-card__body {
  padding: 0;
}

/deep/ .el-menu-item {
  background-color: #ffffff;
}

/deep/ .el-menu-item.is-active {
  background-color: #ffffff;

  .menuItem-d {
    background-color: #788afc;
    box-shadow: 0 0 10px #788afc;
    color: #ffffff;

    i {
      color: #ffffff;
    }
  }
}

/deep/ .el-menu-item:hover {
  background-color: #ffffff;

  .menuItem-d {
    background-color: #788afc;
    color: #ffffff;

    i {
      color: #ffffff;
    }
  }
}

/deep/ .el-badge__content {
  top: 15px !important;
  right: 0px !important;
}
</style>