<template>
  <div>
    <el-card shadow="never">
      <div style="margin-top: 20px;">
        <el-button type="primary" icon="el-icon-plus" size="mini"
                   class="blink-button" @click="addDrawerFlag=true">新建
        </el-button>
        <el-table
            height="785"
            v-loading="loading"
            element-loading-text="拼命加载中..."
            :header-cell-style="{backgroundColor: '#fafafa'}"
            :data="tableData"
            :cell-style="{backgroundColor: '#ffffff'}"
            style="width: 100%;margin-top: 20px;">
          <el-table-column
              prop="agentName">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">所属代理商</span>
            </template>
            <template slot-scope="{row}">
              <div style="display: flex;flex-direction: column;">
                <span style="color: #000000;font-weight: 800;">{{ row.agentName }}</span>
                <span style="color: #b2b2b2;font-size: 12px;">{{ row.agentId }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="tenantId">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">渠道类型</span>
            </template>
            <template slot-scope="{row}">
              <el-tag size="mini" type="warning" effect="plain">{{ row.tenantName }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column
              width="150"
              :show-overflow-tooltip="true"
              prop="privateKey">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">渠道私钥</span>
            </template>
            <template slot-scope="{row}">
              <el-popover
                  ref="popover1"
                  placement="bottom-start"
                  width="500"
                  trigger="hover">
                  密钥：{{row.privateKey}}
              </el-popover>
              <span v-popover:popover1 style="cursor: pointer">{{row.privateKey}}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="150"
              :show-overflow-tooltip="true"
              prop="privateKey">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">渠道公钥</span>
            </template>
            <template slot-scope="{row}">
              <el-popover
                  ref="popover2"
                  placement="bottom-start"
                  width="500"
                  trigger="hover">
                共钥：{{row.publicKey}}
              </el-popover>
              <span v-popover:popover2 style="cursor: pointer">{{row.publicKey}}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="150"
              prop="createTime">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">发起方商户号</span>
            </template>
            <template slot-scope="{row}">
              <span style="margin-left: 5px;">{{ row.certId }}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="150"
              prop="createTime">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">代理商编号</span>
            </template>
            <template slot-scope="{row}">
              <span style="margin-left: 5px;">{{ row.partnerId || '/' }}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="170"
              prop="createTime">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">小程序appId</span>
            </template>
            <template slot-scope="{row}">
              <span style="margin-left: 5px;">{{ row.miniAppId }}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="150"
              :show-overflow-tooltip="true"
              prop="miniAppIdKey">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">小程序密钥</span>
            </template>
            <template slot-scope="{row}">
              <el-popover
                  ref="popover3"
                  placement="bottom-start"
                  width="500"
                  trigger="hover">
                密钥：{{row.miniAppIdKey}}
              </el-popover>
              <span v-popover:popover3 style="cursor: pointer">{{row.miniAppIdKey}}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="150"
              :show-overflow-tooltip="true"
              prop="createTime">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">密钥编码(针对易生)</span>
            </template>
            <template slot-scope="{row}">
              <span style="margin-left: 5px;">{{ row.signKey || '/' }}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="150"
              :show-overflow-tooltip="true"
              prop="createTime">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">渠道编号(针对易生)</span>
            </template>
            <template slot-scope="{row}">
              <span style="margin-left: 5px;">{{ row.clientCode || '/' }}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="100"
              align="center"
              prop="createTime">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">创建日期</span>
            </template>
            <template slot-scope="{row}">
              <span style="margin-left: 5px;">{{ row.createTime.replace("T", " ") }}</span>
            </template>
          </el-table-column>
<!--          <el-table-column-->
<!--              align="center">-->
<!--            <template slot="header" slot-scope="scope">-->
<!--              <span style="font-weight: normal;">操作</span>-->
<!--            </template>-->
<!--            <template slot-scope="{row}">-->
<!--              <el-button style="margin-left: 20px;" type="text" @click="update(row)">修改</el-button>-->
<!--              <el-popconfirm-->
<!--                  title="确定删除吗？"-->
<!--                  @confirm="addAgentTenantDel(row.id)"-->
<!--              >-->
<!--                <el-button slot="reference" style="margin-left: 20px;" type="text">删除</el-button>-->
<!--              </el-popconfirm>-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table>
        <pagination
            :total="total"
            :pageNum.sync="pageNum"
            :limit.sync="pageSize"
            @pagination="initData()"
        />
      </div>
    </el-card>

    <el-dialog
        title="渠道配置"
        :modal="false"
        :visible.sync="addDrawerFlag"
        width="50%">
      <div style="display:flex;flex-direction: column;">
        <div style="display:flex;align-items: center">
          <span style="width: 140px;">进件渠道:</span>
          <el-select v-model="agentTenant.tenantId">
            <el-option v-for="(i,index) in tenantList" :key="index" :label="i.tenantName" :value="i.id"></el-option>
          </el-select>
        </div>
        <div style="display:flex;align-items: center;margin-top: 20px;" v-if="agentTenant.tenantId !== 5">
          <span style="width: 140px;">渠道私钥:</span>
          <el-input v-if="agentTenant.tenantId === 1" v-model="agentTenant.privateKey" maxlength="4"
                    placeholder="请输入私钥文件地址">
          </el-input>
          <el-input
              v-else
              type="textarea"
              :autosize="{ minRows: 7, maxRows: 9}"
              placeholder="请输入私钥"
              v-model="agentTenant.privateKey">
          </el-input>
        </div>
        <div style="display:flex;align-items: center;margin-top: 20px;" v-if="agentTenant.tenantId !== 5">
          <span style="width: 140px;margin-left: 5px;">渠道公钥:</span>
          <el-input v-if="agentTenant.tenantId === 1" v-model="agentTenant.publicKey" maxlength="4"
                    placeholder="请输入公钥文件地址">
          </el-input>
          <el-input
              v-else
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 6}"
              placeholder="请输入公钥"
              v-model="agentTenant.publicKey">
          </el-input>
        </div>
        <div style="display:flex;align-items: center;margin-top: 20px">
          <span style="width: 140px;">发起方商户号:</span>
          <el-input v-model="agentTenant.certId" placeholder="发起方商户号">
          </el-input>
        </div>
        <div v-if="agentTenant.tenantId !== 5">
          <div style="display:flex;align-items: center;margin-top: 20px" v-if="agentTenant.tenantId !== 4">
            <span style="width: 140px;">代理商编号:</span>
            <el-input v-model="agentTenant.partnerId" placeholder="代理商编号">
            </el-input>
          </div>
          <div style="display:flex;align-items: center;margin-top: 20px">
            <span style="width: 140px;">小程序appid:</span>
            <el-input v-model="agentTenant.miniAppId" placeholder="小程序appid">
            </el-input>
          </div>
          <div style="display:flex;align-items: center;margin-top: 20px">
            <span style="width: 140px;">小程序密钥:</span>
            <el-input v-model="agentTenant.miniAppIdKey" placeholder="小程序密钥">
            </el-input>
          </div>
        </div>
        <div style="display:flex;align-items: center;margin-top: 20px" v-if="agentTenant.tenantId === 3 || agentTenant.tenantId === 5">
          <span style="width: 140px;">渠道加密钥:</span>
          <el-input v-model="agentTenant.signKey" placeholder="渠道加密钥">
          </el-input>
        </div>
        <div style="display:flex;align-items: center;margin-top: 20px" v-if="agentTenant.tenantId === 3">
          <span style="width: 140px;">渠道编号:</span>
          <el-input v-model="agentTenant.clientCode" placeholder="渠道编号">
          </el-input>
        </div>
        <div style="display:flex;margin-top: 20px;justify-content: center">
          <el-button type="primary" @click="insertRateTemp">确定</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
        title="修改渠道配置"
        :modal="false"
        :visible.sync="uploadDrawerFlag"
        width="50%">
      <div style="display:flex;flex-direction: column;">
        <div style="display:flex;align-items: center">
          <span style="width: 140px;">进件渠道:</span>
          <el-select v-model="agentTenant.tenantId">
            <el-option v-for="(i,index) in tenantList" :key="index" :label="i.tenantName" :value="i.id"></el-option>
          </el-select>
        </div>
        <div style="display:flex;align-items: center;margin-top: 20px;">
          <span style="width: 140px;">渠道私钥:</span>
          <el-input v-if="agentTenant.tenantId === 1" v-model="agentTenant.privateKey" maxlength="4"
                    placeholder="请输入私钥文件地址">
          </el-input>
          <el-input
              v-else
              type="textarea"
              :autosize="{ minRows: 7, maxRows: 9}"
              placeholder="请输入私钥"
              v-model="agentTenant.privateKey">
          </el-input>
        </div>
        <div style="display:flex;align-items: center;margin-top: 20px;">
          <span style="width: 140px;margin-left: 5px;">渠道公钥:</span>
          <el-input v-if="agentTenant.tenantId === 1" v-model="agentTenant.publicKey" maxlength="4"
                    placeholder="请输入公钥文件地址">
          </el-input>
          <el-input
              v-else
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 6}"
              placeholder="请输入公钥"
              v-model="agentTenant.publicKey">
          </el-input>
        </div>
        <div style="display:flex;align-items: center;margin-top: 20px">
          <span style="width: 140px;">发起方商户号:</span>
          <el-input v-model="agentTenant.certId" placeholder="发起方商户号">
          </el-input>
        </div>
        <div style="display:flex;align-items: center;margin-top: 20px" v-if="agentTenant.tenantId !== 4">
          <span style="width: 140px;">代理商编号:</span>
          <el-input v-model="agentTenant.partnerId" placeholder="代理商编号">
          </el-input>
        </div>
        <div style="display:flex;align-items: center;margin-top: 20px">
          <span style="width: 140px;">小程序appid:</span>
          <el-input v-model="agentTenant.miniAppId" placeholder="小程序appid">
          </el-input>
        </div>
        <div style="display:flex;align-items: center;margin-top: 20px">
          <span style="width: 140px;">小程序密钥:</span>
          <el-input v-model="agentTenant.miniAppIdKey" placeholder="小程序密钥">
          </el-input>
        </div>
        <div style="display:flex;align-items: center;margin-top: 20px" v-if="agentTenant.tenantId === 3">
          <span style="width: 140px;">渠道加密钥:</span>
          <el-input v-model="agentTenant.signKey" placeholder="渠道加密钥">
          </el-input>
        </div>
        <div style="display:flex;align-items: center;margin-top: 20px" v-if="agentTenant.tenantId === 3">
          <span style="width: 140px;">渠道编号:</span>
          <el-input v-model="agentTenant.clientCode" placeholder="渠道编号">
          </el-input>
        </div>
        <div style="display:flex;margin-top: 20px;justify-content: center">
          <el-button type="primary" @click="insertRateTemp">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/index.vue";
import {addRateTemplate, rateTempList} from "@/api/mch";
import {addAgentTenant, addAgentTenantDel, getAgentTenantList, getTenantList} from "@/api/tenant";

export default {
  name: "RateTemplate",
  components: {Pagination},
  data() {
    return {
      tenantList: [],
      addDrawerFlag: false,
      uploadDrawerFlag: false,
      loading: false,
      tableData: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      agentTenant: {
        id: null,
        tenantId: '',
        agentId: this.$store.getters.userInfo.id,
        privateKey: '',
        publicKey: '',
        certId: '',
        partnerId: '',
        miniAppId: '',
        miniAppIdKey: '',
        signKey: '',
        clientCode: ''
      }
    }
  },
  mounted() {
    this.initData()
    this.getTenantLists()
  },
  methods: {
    addAgentTenantDel(id){
      addAgentTenantDel(id).then(res =>{
        if (res.success){
          this.$message.success("成功")
          this.initData()
        }
      })
    },
    getTenantLists() {
      getTenantList().then(res => {
        if (res.success) {
          this.tenantList = res.data
        }
      })
    },
    update(row) {
      this.agentTenant = row;
      this.uploadDrawerFlag = true;
    },
    insertRateTemp() {
      if (this.agentTenant.tenantId === 5){
        if (!this.agentTenant.signKey || !this.agentTenant.certId){
          this.$message.warning("内容填写的不完整！")
          return;
        }
      }else{
        if (!this.agentTenant.tenantId || !this.agentTenant.privateKey || !this.agentTenant.publicKey || !this.agentTenant.certId || !this.agentTenant.miniAppId || !this.agentTenant.miniAppIdKey) {
          this.$message.warning("内容填写的不完整！")
          return;
        }
        if (this.agentTenant.tenantId === 1 || this.agentTenant.tenantId === 2) {
          if (!this.agentTenant.partnerId) {
            this.$message.warning("内容填写的不完整！")
            return;
          }
        }
        if (this.agentTenant.tenantId === 3) {
          if (!this.agentTenant.signKey || !this.agentTenant.clientCode) {
            this.$message.warning("内容填写的不完整！")
            return;
          }
        }
      }
      let param = {
        id: this.agentTenant.id || null,
        tenantId: this.agentTenant.tenantId || null,
        agentId: this.$store.getters.userInfo.id,
        privateKey: this.agentTenant.privateKey || null,
        publicKey: this.agentTenant.publicKey || null,
        certId: this.agentTenant.certId || null,
        partnerId: this.agentTenant.partnerId || null,
        miniAppId: this.agentTenant.miniAppId || null,
        miniAppIdKey: this.agentTenant.miniAppIdKey || null,
        signKey: this.agentTenant.signKey || null,
        clientCode: this.agentTenant.clientCode || null
      }
      addAgentTenant(param).then(res => {
        if (res.data) {
          this.addDrawerFlag = false;
          this.uploadDrawerFlag = false;
          this.addRateTemp = {
            id: null,
            tenantId: null,
            agentId: this.$store.getters.userInfo.id,
            privateKey: null,
            publicKey: null,
            certId: null,
            partnerId: null,
            miniAppId: null,
            miniAppIdKey: null,
            signKey: null,
            clientCode: null
          };
          this.$message.success("成功")
          this.initData()
        }
      })
    },
    initData() {
      this.loading = true;
      getAgentTenantList(this.$store.getters.userInfo.id).then(res => {
        if (res.success) {
          this.loading = false;
          this.tableData = res.data
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>