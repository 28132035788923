<template>
  <div class="kk-box" :ref="ref">
      <div style="text-align: center;color: #fff">
          ￥
          <template v-for="(item, index) in surplus">
             <span class="gmvNum" :key="index">
                {{item}}
              </span>
          </template>
      </div>
  </div>
</template>

<script>
import autoResize from '../autoResize'
import { deepMerge,deepClone } from '../util'



export default {
  name: 'RegisterBox',
  mixins: [autoResize],
  props: {
    surplusData: {
      type: Number,
      default: 8889888
    }
  },
  data () {
    return {
      ref: 'border-box',
      border: ['left-top', 'right-top', 'left-bottom', 'right-bottom'],
      defaultColor: ['#4fd2dd', '#235fa7'],
      mergedColor: [],
      surplus: "000000".split('')
    }
  },
  watch: {
    surplusData (v, k) {
      this.surplus = this.surplusData.toString().split('')
    }
  },
  methods: {

    timeDiffer(){
        if(!this.target) {
            console.error("days com can‘t set taget time is null")
        }
        let currentTime = moment();
        let difDays = this.target.diff(currentTime, 'day').toString().padStart(2, '0');
        let difHours = (this.target.diff(currentTime, 'hour')% 24).toString().padStart(2, '0');
        let difMinutes = (this.target.diff(currentTime, 'minute')% 60 + 1).toString().padStart(2, '0');
        let difSeconds = (this.target.diff(currentTime, 'second')%60).toString().padStart(2, '0');
        this.day = difDays;
        this.hour = difHours;
        this.minute = difMinutes;
        this.second = difSeconds;

    },
    mergeColor () {
      const { color, defaultColor } = this
      this.mergedColor = deepMerge(deepClone(defaultColor, true), color || {})
    }
  },
  mounted () {
    const { mergeColor,timeDiffer } = this
    mergeColor();
    // setInterval(timeDiffer, 1000)
  }
}
</script>

<style lang="scss">
    .time-box {
        width: 47px;
        display: inline-block;
        height: 50px;
        margin: 5px;
        border: 1px solid;
        border-image: linear-gradient(180deg, #1363DC, #0F4281) 10 10;
        background: linear-gradient(0deg, #170565 0%, #2A0188 100%);
    }
    .gmvNum {
      display: inline-block;
      width: 60px;
      height: 60px;
      line-height: 60px;
      font-size: 42px;
      font-weight: 600;
      margin-left: 5px;
      background: url(https://image.mobduos.com/upload/image/manager/2021/01/25/33798b32-5694-4720-bd4b-1316453504c6.png);
      background-size: contain;

    }
    
</style>