<template>
  <div>
    <el-card shadow="never" style="border: 0px;">
      <span style="font-size: 12px;font-weight: bold">佣金钱包</span>
      <el-divider/>
      <el-row style="margin-left: 10vw;margin-top: 30px;">
        <el-col :span="6" style="display:flex;flex-direction: column;">
          <span style="font-size: 12px;color: #817d81;">钱包余额（元）</span>
          <span style="font-size: 28px;font-weight: 500;color: #d60304;margin-top: 10px;">0</span>
        </el-col>
        <el-col :span="6" style="display:flex;flex-direction: column;">
          <span style="font-size: 12px;color: #817d81;">
            在途利润（元）
              <el-tooltip class="item" effect="dark" content="当前已经产生的利润，次月15号可进行提现操作" placement="top-start">
                <i class="el-icon-question" style="font-size: 16px;"></i>
              </el-tooltip>
          </span>
          <span style="font-size: 28px;font-weight: 500;margin-top: 10px;">{{ moneyInfo.sy || 0 }}</span>
        </el-col>
        <el-col :span="6" style="display:flex;flex-direction: column;">
          <span style="font-size: 12px;color: #817d81;">
            可提现金额（元）
              <el-tooltip class="item" effect="dark" content="已到达次月15号后可进行提现的佣金" placement="top-start">
                <i class="el-icon-question" style="font-size: 16px;"></i>
              </el-tooltip>
          </span>
          <span style="font-size: 28px;font-weight: 500;margin-top: 10px;">{{ moneyInfo.sy || 0 }}</span>
        </el-col>
        <el-col :span="4" style="display:flex;flex-direction: column;">
          <span style="font-size: 12px;color: #817d81;">
            提现中金额（元）
              <el-tooltip class="item" effect="dark" content="正在提现中的金额" placement="top-start">
                <i class="el-icon-question" style="font-size: 16px;"></i>
              </el-tooltip>
          </span>
          <span style="font-size: 28px;font-weight: 500;margin-top: 10px;">0.00</span>
        </el-col>
        <el-col :span="1">
          <el-button style="background-color: #d60304;color: #ffffff;border: 0px;" size="mini">提现</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card shadow="never" style="border: 0px;margin-top: 20px">
      <el-form :model="purseForm" ref="mchForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="" prop="name">
          <el-select size="small" v-model="purseForm.date" placeholder="状态" style="">
            <el-option label="全部时间" :value="0"></el-option>
            <el-option label="今天" :value="1"></el-option>
            <el-option label="近7天" :value="7"></el-option>
            <el-option label="近30天" :value="30"></el-option>
            <el-option label="近90天" :value="90"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="name">
          <el-input clearable size="small" placeholder="提现单号" style="width: 200px;margin-left: 20px;"
                    v-model="purseForm.withdrawNo"></el-input>
        </el-form-item>
        <el-form-item label="" prop="name">
          <el-select size="small" v-model="purseForm.withdrawStatus" placeholder="提现状态" style="margin-left: 20px;">
            <el-option label="全部" :value="-1"></el-option>
            <el-option label="审核中" :value="0"></el-option>
            <el-option label="审核失败" :value="1"></el-option>
            <el-option label="结算中" :value="2"></el-option>
            <el-option label="结算成功" :value="3"></el-option>
            <el-option label="结算失败" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 40px;">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
          <el-button type="default" icon="el-icon-refresh-right" size="mini" @click="queryRest">重置</el-button>
        </el-form-item>
      </el-form>
      <el-divider/>
      <div style="margin-top: 20px;">
        <el-button type="primary" icon="el-icon-plus" size="mini"
                   class="blink-button">发起提现
        </el-button>
        <el-table
            v-loading="loading"
            element-loading-text="拼命加载中..."
            :header-cell-style="{backgroundColor: '#fafafa'}"
            :data="tableData"
            :cell-style="{backgroundColor: '#ffffff'}"
            style="width: 100%;margin-top: 20px;">
          <el-table-column
              prop="withdrawNo">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">提现单号</span>
            </template>
            <template slot-scope="{row}">
              <span style="font-weight: bolder;">{{ row.withdrawNo }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="price">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">申请金额</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="rate">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">手续费</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="inPrice">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">入账金额</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="status">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">提现状态</span>
            </template>
            <template slot-scope="{row}">
              <el-tag size="small" type="success">
                {{ row.status == 0 ? '审核中' : row.status == 1 ? '审核失败' : row.status == 2 ? '结算中' : row.status == 3 ? '结算成功' : '结算失败' }}
              </el-tag>
            </template>
          </el-table-column>

          <el-table-column
              prop="createTime">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">发起时间</span>
            </template>
            <template slot-scope="{row}">
              <span style="margin-left: 5px;">{{ row.createTime.replace("T", " ") }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal;">操作</span>
            </template>
            <template slot-scope="{row}">
              <el-button style="margin-left: 20px;" type="text">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            background
            style="margin-top: 20px;padding-bottom: 20px;float: right;"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageSize"
            :current-page="pageNum"
            layout="total, prev, pager, next,sizes"
            :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import Nav from "@/components/Nav";
import {getMoney} from "@/api/user";
export default {
  name: "purse",
  components:{
    Nav
  },
  data() {
    return {
      moneyInfo: {},
      loading: false,
      pageSize: 10,
      pageNum: 1,
      total: 0,
      tableData: [
        // {
        //   withdrawNo: 1,
        //   price: 100,
        //   rate: 0,
        //   inPrice: 100,
        //   status: 3,
        //   createTime: '2023-09-01T10:24:00'
        // }
      ],
      purseForm: {
        date: 0,
        withdrawNo: '',
        withdrawStatus: 0,
      }
    }
  },
  mounted() {
    this.getMoney()
  },
  methods: {
    getMoney(){
      getMoney(this.$store.getters.userInfo.id).then(res =>{
        if (res.success){
          this.moneyInfo = res.data
        }
      })
    },
    handleSizeChange(){

    },
    handleCurrentChange(){

    },
    query() {

    },
    queryRest() {
      this.apurseForm = {
        date: 0,
        withdrawNo: '',
        withdrawStatus: 0,
      }
    }
  }
}
</script>

<style scoped>
/deep/ .el-divider--horizontal {
  margin: 8px 0px;
}

.demo-ruleForm {
  display: flex;
}

/deep/ .demo-ruleForm .el-form-item__content {
  margin-left: 0px !important;
}

.blink-button:hover {
  background-image: linear-gradient(to right, rgb(250, 82, 82), rgb(250, 82, 82) 16.65%, rgb(190, 75, 219) 16.65%, rgb(190, 75, 219) 33.3%, rgb(76, 110, 245) 33.3%, rgb(76, 110, 245) 49.95%, rgb(64, 192, 87) 49.95%, rgb(64, 192, 87) 66.6%, rgb(250, 176, 5) 66.6%, rgb(250, 176, 5) 83.25%, rgb(253, 126, 20) 83.25%, rgb(253, 126, 20) 100%, rgb(250, 82, 82) 100%);
  animation: var(--timing) linear dance6123 infinite;
  transform: scale(1.1) translateY(-1px);
}

.blink-button {
  --width: 90px;
  --timing: 2s;
  border: 0;
  width: var(--width);
  color: #fff;
  transition: all 0.2s;
}

@keyframes dance6123 {
  to {
    background-position: var(--width);
  }
}
</style>