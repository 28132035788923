import http from '@/libs/axios';

let pro = process.env.VUE_APP_BASE_API

/**
 * 查询订单列表
 * @param data
 * @returns {*}
 */
export function orderList(data){
    return http({
        url: `${pro}/v1/order/list`,
        method: 'POST',
        requestBody: true,
        data
    })
}

export function settleList(data){
    return http({
        url: `${pro}/v1/settle/list`,
        method: 'POST',
        requestBody: true,
        data
    })
}

/**
 * 查询交易的金额
 * @param data
 * @returns {*}
 */
export function totalPrice(query){
    return http({
        url: `${pro}/v1/order/totalPrice`,
        method: 'GET',
        params: query
    })
}

/**
 * 支付宝支付
 * @param query
 * @returns {*}
 */
export function alipay(query){
    return http({
        url: `${pro}/v1/public/ewmpay`,
        method: 'GET',
        params: query
    })
}

/**
 * 支付宝支付
 * @param query
 * @returns {*}
 */
export function alipay2(data){
    return http({
        url: `${pro}/v1/public/alipay`,
        method: 'POST',
        requestBody: true,
        data
    })
}

/**
 * 修改轮询
 * @param query
 * @returns {*}
 */
export function updateLx(query){
    return http({
        url: `${pro}/v1/piece/updateLx`,
        method: 'GET',
        params: query
    })
}

/**
 * 微信支付
 * @param query
 * @returns {*}
 */
export function wxpay(data){
    return http({
        url: `${pro}/v1/public/wxpay`,
        method: 'POST',
        requestBody: true,
        data
    })
}

/**
 * 查询首页
 * @param query
 * @returns {*}
 */
export function homeTotalPrice(data){
    return http({
        url: `${pro}/v1/order/homeTotalPrice`,
        method: 'POST',
        requestBody: true,
        data
    })
}

/**
 * 查询分账列表
 * @param data
 */
export function divisionList(data){
    return http({
        url: `${pro}/v1/division/list`,
        method: 'POST',
        requestBody: true,
        data
    })
}