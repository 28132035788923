<template>
  <div>

  </div>
</template>

<script>
import {aliPayCode} from "@/api/user";
import { Toast} from 'vant';
export default {
  name: "authPay",
  data(){
    return{
      loading: undefined
    }
  },
  created() {
    let _this = this;
    Toast.loading({
      message: '正在支付...',
      icon: 'http://shizhi-image.oss-cn-beijing.aliyuncs.com/image/2023-09-08/83f8d7ff-3baa-44a5-99a8-db59f1f1a886.png',
      duration: 0,
      forbidClick: true,
    });
    if (_this.$route.query.zfbOrder && _this.$route.query.money){
      if (window.AlipayJSBridge) {
        ap.getAuthCode({
          appId: '2021004115698026',
          scopes: ['auth_base'],
        }, function (res) {
          let param = {
            userCode: _this.$route.query.zfbOrder,
            money: _this.$route.query.money,
            orderNo: _this.$route.query.orderNo || null,
            extra_common_param: _this.$route.query.notify_url || null,
            authCode: res.authCode
          }
          aliPayCode(param).then(res2 => {
            if (res2.success) {
              Toast.clear()
              _this.tradePay(JSON.parse(res2.data.jsapi_pay_info).tradeNO)
            }
          })
        });
      }
    }
  },
  methods:{
    ready(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener('AlipayJSBridgeReady', callback, false);
      }
    },
    tradePay(tradeNO) {
      this.ready(function () {
        // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
        AlipayJSBridge.call("tradePay", {
          tradeNO: tradeNO
        }, function (data) {
          if ("9000" == data.resultCode) {
            log("支付成功");
          }
        });
      });
    }
  }
}
</script>

<style scoped>

</style>