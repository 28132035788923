<template>
    <div>配置管理</div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>

</style>