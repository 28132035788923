/*
 * @Description: What's this for
 * @Autor: WangYuan
 * @Date: 2021-03-25 10:25:23
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-12-15 17:17:02
 */
import store from '@/store'
import Cookies from 'js-cookie'
const TokenKey = 'token'
const UserInfoKey = 'user-info'
const ProjectKey = 'mall-project'

export function getAuthToken() {
    return Cookies.get(TokenKey)
}

export function setAuthToken(token) {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let expiresTime = new Date(new Date(year, month, day).getTime() + 24 * 60 * 60 * 1000 - 1);
    return Cookies.set(TokenKey, token, {
        expires: expiresTime,
    })
}

export function removeAuthToken() {
    return Cookies.remove(TokenKey)
}

export function setAuthUserInfo(userInfo) {
    return localStorage.setItem(UserInfoKey, JSON.stringify(userInfo))
}

export function getAuthUserInfo() {
    let userInfoStr = localStorage.getItem(UserInfoKey)
    return userInfoStr ? JSON.parse(localStorage.getItem(UserInfoKey)) : null
}