<template>
  <div class="big-screen-container-box">
    <div id="big-screen-container" :ref="ref" class="app-main">
      <div class="top-header" ref="top-header">
        <h1 class="center-title" ref="center-title">汇扫扫交易指挥中心</h1>
<!--        <div class="left-weather">-->
<!--          <a-select :value="appId" @change="handleChangeApp" id="app-change" style="width: 100px">-->
<!--            <a-select-option :value="6">知虾</a-select-option>-->
<!--            &lt;!&ndash; <a-select-option :value="3">Lazada</a-select-option> &ndash;&gt;-->
<!--          </a-select>-->
<!--        </div>-->
        <div class="right-weather">
          <div style="margin-right: 20px">
            <div>{{ date }}</div>
            <div>{{ week }}&nbsp;&nbsp;{{ time }}</div>
          </div>
          <div>
            <iframe
              allowtransparency="true"
              frameborder="0"
              width="93"
              height="36"
              scrolling="no"
              src="//tianqi.2345.com/plugin/widget/index.htm?s=3&amp;z=3&amp;t=1&amp;v=0&amp;d=3&amp;bd=0&amp;k=&amp;f=ffffff&amp;ltf=ffffff&amp;htf=ffffff&amp;q=1&amp;e=1&amp;a=1&amp;c=54511&amp;w=180&amp;h=36&amp;align=center"
            ></iframe>
          </div>
        </div>
      </div>
      <div class="screen-body" ref="screen-body">
        <!-- 中部主体内容 -->
        <div class="body-central" ref="body-central">
          <div class="central-left-container">
            <box class="register-box" ref="box-1">
              <register-box title="注册统计">
                <div class="register-total" style="height: 40px; padding: 5px">
                  <icon-shande-box title="本月注册量" style="flex: 1">
                    <div slot="icon" style="padding: 10px 5px">
                      <svg-icon style="font-size: 22px; line-height: 40px; color: #eee" icon-class="iconbenyuezhuce1" />
                    </div>
                    <div style="color: #fff; font-size: 12px; font-weight: bold">{{ thousandConvert(monthRegNum) }}</div>
                  </icon-shande-box>
                  <div style="display: flex">
                    <el-divider
                      style="align-self: center; height: 80%; background: #13205d; margin-right: 2px"
                      type="vertical"
                    />
                  </div>
                  <icon-shande-box title="今日注册量" style="flex: 1">
                    <div slot="icon" style="padding: 10px 5px">
                      <svg-icon
                        style="font-size: 22px; line-height: 40px; color: #eee"
                        icon-class="iconjinrizhuceliang1"
                      />
                    </div>
                    <div style="color: #fff; font-size: 12px; font-weight: bold">{{ thousandConvert(curDayRegNum) }}</div>
                  </icon-shande-box>
                  <div style="display: flex">
                    <el-divider
                      style="align-self: center; height: 80%; background: #13205d; margin-right: 2px"
                      type="vertical"
                    />
                  </div>
                  <icon-shande-box title="本月转化率" style="flex: 1">
                    <div slot="icon" style="padding: 10px 5px">
                      <svg-icon style="font-size: 22px; line-height: 40px; color: #eee" icon-class="iconbenyuezhuanhua" />
                    </div>
                    <div style="color: #fff; font-size: 12px; font-weight: bold">{{ monthCovertRatio }}%</div>
                  </icon-shande-box>
                  <div style="display: flex">
                    <el-divider
                      style="align-self: center; height: 80%; background: #13205d; margin-right: 2px"
                      type="vertical"
                    />
                  </div>
                  <icon-shande-box title="今日转化率" style="flex: 1">
                    <div slot="icon" style="padding: 10px 5px">
                      <svg-icon style="font-size: 22px; line-height: 40px; color: #eee" icon-class="iconjinrizhuanhua" />
                    </div>
                    <div style="color: #fff; font-size: 12px; font-weight: bold">{{ curDayConvertRatio }}%</div>
                  </icon-shande-box>
                </div>
                <charts :option="registerOption" :ref="refCharts[0]" height="200px" key="1" />
              </register-box>
            </box>
            <box class="register-ok-box" ref="box-2">
              <register-box title="注册完成度">
                <div style="display: flex">
                  <div style="flex: 1; display: flex">
                    <el-progress
                      style="margin: 0 auto; padding: 20px"
                      class="progress"
                      type="circle"
                      :percentage="regTargetFinishRatio > 100 ? 100 : regTargetFinishRatio"
                      strokeColor="#3064FB"
                      :width="140*blscle"
                      :strokeWidth="9"
                    >
                      <template #format="">
                        <div style="font-size: 24px; color: #fff">{{ regTargetFinishRatio }}%</div>
                        <div style="font-size: 12; color: #fff; opacity: 0.5">已完成</div>
                      </template>
                    </el-progress>
                  </div>
                  <div style="width: 150px; color: #fff; text-align: center; display: flex; flex-direction: column">
                    <div style="flex: 1">
                      <div style="font-size: 12px; opacity: 0.5">用户注册目标</div>
                      <div style="font-size: 18px; font-weight: bold">{{ regTarget }}</div>
                    </div>
                    <div style="display: flex; width: 50%; margin: 0 auto">
                      <el-divider style="background: #13205d; margin: 0 0 10px 0" />
                    </div>
                    <div style="flex: 1">
                      <div style="font-size: 12px; opacity: 0.5">距离目标差距</div>
                      <div style="font-size: 18px; font-weight: bold">{{ surplusRegTarget }}</div>
                    </div>
                    <div style="display: flex; width: 50%; margin: 0 auto">
                      <el-divider style="background: #13205d; margin: 0 0 10px 0" />
                    </div>
                    <div style="flex: 1">
                      <div style="font-size: 12px; opacity: 0.5">每日需完成</div>
                      <div style="font-size: 18px; font-weight: bold">{{ dayNeedFinishTarget }}</div>
                    </div>
                  </div>
                </div>
              </register-box>
            </box>
          </div>
          <div class="central-center-container">
            <box class="center-box" ref="box-7" style="background: linear-gradient(0deg, #010948 0%, #240066 100%)">
              <register-box :title="`距离${month}月目标达成`" fontSize="20" style="height: 240px" ref="box-3">
                <div class="header-target">
                  <div class="remaining-display">
                    <days-com @suplyData="suplyData" />
                    <box-header style="margin-top: 30px" title="目标值剩余" />
                    <gmv-com :surplus-data="surplusData" />
                  </div>
                </div>
                <div
                  style="
                    background: url(https://image.mobduos.com/upload/image/manager/2021/01/29/196c391a-57e1-4f87-a39f-5f71eeaeb4f4.png);
                    background-repeat: no-repeat;
                    /* background-color: #fff; */
                    background-position-x: 100px;
                  "
                >
                  <div style="display: flex; width: 400px; margin: 0 auto; padding: 10px 0" ref="radio-box">
                    <div style="flex: 1; display: flex">
                      <el-progress
                        class="progress"
                        type="circle"
                        :percentage="targetFinishRatio > 100 ? 100 : targetFinishRatio"
                        strokeColor="#3064FB"
                        :width="circlewidth"
                        :strokeWidth="9"
                      >
                        <template #format="">
                          <svg-icon
                            style="font-size: 22px; line-height: 40px; color: #eee"
                            icon-class="iconmubiaozhiwancheng"
                          />
                        </template>
                      </el-progress>
                      <div class="progress-info">
                        <div class="progress-title">目标值完成率</div>
                        <div class="progress-label">{{ targetFinishRatio }}%</div>
                      </div>
                    </div>
                    <div style="flex: 1; display: flex">
                      <el-progress
                        class="progress"
                        type="circle"
                        :percentage="regConvertRatio > 100 ? 100 : regConvertRatio"
                        strokeColor="#34B77E"
                        :width="circlewidth"
                        :strokeWidth="9"
                      >
                        <template #format="">
                          <svg-icon
                            style="font-size: 22px; line-height: 40px; color: #eee"
                            icon-class="icondangqianzhucezhuanhuashuai"
                          />
                        </template>
                      </el-progress>
                      <div class="progress-info">
                        <div class="progress-title">当前注册转化率</div>
                        <div class="progress-label">{{ regConvertRatio }}%</div>
                      </div>
                    </div>
                    <div style="flex: 1; display: flex">
                      <el-progress
                        class="progress"
                        type="circle"
                        :percentage="dayRatio"
                        strokeColor="#B443F5"
                        :width="circlewidth"
                        :strokeWidth="9"
                      >
                        <template #format="">
                          <svg-icon
                            style="font-size: 22px; line-height: 40px; color: #eee"
                            icon-class="iconshijianjindu"
                          />
                        </template>
                      </el-progress>
                      <div class="progress-info">
                        <div class="progress-title">时间进度</div>
                        <div class="progress-label">{{ dayRatio }}%</div>
                      </div>
                    </div>
                  </div>
                  <div class="all-process" ref="process">
                    <div class="cg-progcess-left">
                      <div class="top-info">
                        <div class="progress-info">
                          <div class="progress-label">￥{{ curDayFinishGmv }}</div>
                          <div class="progress-title">今日完成额度</div>
                        </div>
                      </div>
                      <div class="bottom-info">
                        <div class="progress-info">
                          <div class="progress-label">{{ dayFinishTargetGmv }}</div>
                          <div class="progress-title">距目标每日需完成</div>
                        </div>
                      </div>
                    </div>
                    <div class="cg-progcess">
                      <div class="btn-process" ref="btn-process">
                        <div class="cg-icon">
                          <svg-icon
                            style="font-size: 22px; line-height: 40px; color: #eee"
                            :icon-class="
                              curDayFinishGmv > dayFinishTargetGmv ? 'iconjulimubiaoyujing_3' : 'iconjulimubiaoyujing_2'
                            "
                          />
                        </div>
                        <div class="cg-right-icon">
                          <svg-icon
                            style="font-size: 22px; line-height: 40px; color: #eee"
                            :icon-class="
                              curDayFinishReg > dayFinishTargetReg ? 'iconjulimubiaoyujing_3' : 'iconjulimubiaoyujing_2'
                            "
                          />
                        </div>
                        <div class="cg-tetxt">距离目标</div>
                        <div class="cg-tetxt">每日预警</div>
                      </div>
                    </div>
                    <div class="cg-progcess-right">
                      <div class="top-info">
                        <div class="progress-info">
                          <div class="progress-label">{{ curDayFinishReg }}</div>
                          <div class="progress-title">今日完成注册</div>
                        </div>
                      </div>
                      <div class="bottom-info">
                        <div class="progress-info">
                          <div class="progress-label">{{ dayFinishTargetReg }}</div>
                          <div class="progress-title">距目标每日需完成</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </register-box>
            </box>
          </div>
          <div class="central-right-container">
            <box class="real-time-box" ref="box-4">
              <register-box title="实时销量">
                <div class="register-total" style="height: 40px; padding: 5px; display: flex">
                  <icon-shande-box title="本月总销售额" style="display: flex; flex: 1" :resver="true">
                    <div slot="icon" style="padding: 10px">
                      <svg-icon
                        style="font-size: 22px; line-height: 40px; color: #eee"
                        icon-class="iconbenyuezongxiaoshoue"
                      />
                    </div>
                    <div style="color: #fff; font-size: 12px; font-weight: bold">￥{{ monthTotalGmv }}</div>
                  </icon-shande-box>
                  <div style="display: flex">
                    <el-divider style="align-self: center; height: 80%; background: #13205d" type="vertical" />
                  </div>
                  <icon-shande-box title="今日销售额" style="display: flex; flex: 1" :resver="true">
                    <div slot="icon" style="padding: 10px">
                      <svg-icon style="font-size: 22px; line-height: 40px; color: #eee" icon-class="iconjinrixiaoshoue" />
                    </div>
                    <div style="color: #fff; font-size: 12px; font-weight: bold">￥{{ curDayGmv }}</div>
                  </icon-shande-box>
                  <div style="display: flex">
                    <el-divider style="align-self: center; height: 80%; background: #13205d" type="vertical" />
                  </div>
                  <icon-shande-box title="较昨日流水" style="display: flex; flex: 1" :resver="true">
                    <div slot="icon" style="padding: 10px">
                      <svg-icon style="font-size: 22px; line-height: 40px; color: #eee" icon-class="iconzuoriliushui" />
                    </div>
                    <div
                      :style="{
                        color: comparePreDayGmv > 0 ? '#5DFEA1' : '#FE5D64',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }"
                    >
                      {{ comparePreDayGmv }}%
                    </div>
                  </icon-shande-box>
                </div>
                <charts id="chart2" :ref="refCharts[1]" :option="registerOption2" height="170px" key="2" />
              </register-box>
            </box>
            <box class="order-total-box" ref="box-5">
              <register-box title="订单统计">
                <div class="register-total" style="height: 40px; padding: 5px">
                  <icon-shande-box title="总订单" style="display: flex; flex: 1" :resver="true">
                    <div slot="icon" style="padding: 10px">
                      <svg-icon style="font-size: 22px; line-height: 40px; color: #eee" icon-class="iconzongdingdan" />
                    </div>
                    <div style="color: #fff; font-size: 12px; font-weight: bold">
                      {{ thousandConvert(monthTotalOrderNum) }}
                    </div>
                  </icon-shande-box>
                  <div style="display: flex">
                    <el-divider style="align-self: center; height: 80%; background: #13205d; margin: 0" type="vertical" />
                  </div>
                  <icon-shande-box title="今日订单" style="display: flex; flex: 1" :resver="true">
                    <div slot="icon" style="padding: 10px">
                      <svg-icon style="font-size: 22px; line-height: 40px; color: #eee" icon-class="iconjinridingdan" />
                    </div>
                    <div style="color: #fff; font-size: 12px; font-weight: bold">{{ curDayOrderNum }}</div>
                  </icon-shande-box>
                  <div style="display: flex">
                    <el-divider style="align-self: center; height: 80%; background: #13205d; margin: 0" type="vertical" />
                  </div>
                  <icon-shande-box title="较昨日订单" style="display: flex; flex: 1.1" :resver="true">
                    <div slot="icon" style="padding: 10px">
                      <svg-icon style="font-size: 22px; line-height: 40px; color: #eee" icon-class="iconzuoriliushui" />
                    </div>
                    <div
                      :style="{
                        color: comparePreDayOrderNum > 0 ? '#5DFEA1' : '#FE5D64',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }"
                    >
                      {{ comparePreDayOrderNum }}
                    </div>
                  </icon-shande-box>
                  <div style="display: flex">
                    <el-divider style="align-self: center; height: 80%; background: #13205d; margin: 0" type="vertical" />
                  </div>
                  <icon-shande-box title="客单价" style="display: flex; flex: 1" :resver="true">
                    <div slot="icon" style="padding: 10px">
                      <svg-icon style="font-size: 22px; line-height: 40px; color: #eee" icon-class="iconkedanjia" />
                    </div>
                    <div style="color: #fff; font-size: 12px; font-weight: bold">￥{{ customerSinglePrice }}</div>
                  </icon-shande-box>
                </div>
                <charts id="chart4" :ref="refCharts[2]" :option="orderOption1" :height="`${chart4Height}px`" key="4" />
              </register-box>
            </box>
          </div>
        </div>
        <!-- 下部部主体内容 -->
        <div class="bottom-contral" ref="bottom-contral">
          <div class="bottom-central-left-container">
            <box class="user-order-box" ref="box-8">
              <div style="display: flex">
                <register-box title="用户订单比例" style="flex: 1">
                  <div style="display: flex">
                    <div style="flex: 1">
                      <div style="display: flex">
                        <div class="header-tip">
                          <div class="newsper" style="font-size: 12px; opacity: 0.49; color: #fff">产生新订单用户数</div>
                          <div style="color: #fff; font-weight: bold">{{ thousandConvert(newUserOrderNum) }}</div>
                        </div>
                        <div style="display: flex">
                          <el-divider style="align-self: center; height: 80%; background: #13205d" type="vertical" />
                        </div>
                        <div class="header-tip">
                          <div class="newsper" style="font-size: 12px; opacity: 0.49; color: #fff">产生订单老用户数</div>
                          <div style="color: #fff; font-weight: bold">{{ thousandConvert(oldUserOrderNum) }}</div>
                        </div>
                      </div>
                      <div>
                        <charts id="chart6" :option="orderRatioOption" height="190px" key="1" />
                      </div>
                    </div>
                    <div style="flex: 1">
                      <div style="display: flex">
                        <div class="header-tip">
                          <div class="newsper" style="font-size: 12px; opacity: 0.49; color: #fff">新用户销售金额</div>
                          <div style="color: #fff; font-weight: bold">{{ thousandConvert(newUserGmv) }}</div>
                        </div>
                        <div style="display: flex">
                          <el-divider style="align-self: center; height: 80%; background: #13205d" type="vertical" />
                        </div>
                        <div class="header-tip">
                          <div class="newsper" style="font-size: 12px; opacity: 0.49; color: #fff">老用户销售金额</div>
                          <div style="color: #fff; font-weight: bold">{{ thousandConvert(oldUserGmv) }}</div>
                        </div>
                      </div>
                      <div>
                        <charts id="chart7" :option="gmvRatioOption" height="190px" key="1" />
                      </div>
                    </div>
                  </div>
                </register-box>
                <register-box title="新老用户数每日趋势" style="flex: 1">
                  <div>
                    <charts id="chart9" :ref="refCharts[3]" :option="userOption1" :height="`${chart9Height}px`" key="9" />
                  </div>
                </register-box>
              </div>
            </box>
          </div>
          <div class="bottom-central-right-container">
            <box class="order-come-box" ref="box-9">
              <register-box title="订单来了">
                <scroll-board :config="config" ref="scroll" style="width: 100%; height: 220px" />
              </register-box>
            </box>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>

<script>
import js from './mixin'
import register from './register-mixin'
import realGmv from './realGmv-mixin'
import cirque from './cirque-mixin'
import orderMixin from './order-mixin'
import usersMixin from './users-mixin'
import orderRatioOption from './orderRatio-mixin'
import gmvRatioOption from './gmvRatio-mixin'
import autoResize from './autoResize'
import Box from './box.vue'
import RegisterBox from './template/RegisterBox.vue'
import IconShandeBox from './template/IconShandeBox.vue'
import scrollBoard from './template/scrollBoard.vue'
import daysCom from './template/daysCom.vue'
import gmvCom from './template/gmvCom.vue'
import boxHeader from './template/box-header.vue'
import { thousandConvert } from './util'

export default {
  name: 'index',
  components: { Box, RegisterBox, IconShandeBox, scrollBoard, daysCom, gmvCom, boxHeader },
  mixins: [autoResize, js, register, realGmv, cirque, orderMixin, orderRatioOption, gmvRatioOption, usersMixin],
  data() {
    let nowDay = new Date()
    let nowTime = nowDay.getTime()
    let month = nowDay.getMonth() + 1;
    let timeList = []
    for (let i=0; i<32; i++) {
      let time = this.getTimeText(nowTime)
      timeList.unshift(time)
      if (new Date(time).getMonth() + 1 != month) {
        break;
      } else  {
        nowTime = nowTime - 86400 * 1000
      }
    }
    return {
      ref: 'big-screen-container',
      surplusData: 8889888,
      circlewidth: 60,
      dayRatio: 0,
      month: 0,
      appId: 6,
      date: '',
      week: '',
      time: '',
      config: {
        index: true,
        align: 'center',
        columnWidth: [30],
        waitTime: '5000',
        oddRowBGC: 'transparent',
        evenRowBGC: 'transparent',
        data: [],
      },
      refCharts: ['ehcats1', 'ehcats2', 'ehcats3', 'ehcats4'],
      chart9Height: 190,
      chart4Height: 190,
      scrollBoardHeight: 220,
      urls: {
        getRegisterComplete: '/screen/getRegisterComplete',
        getRegisterStatistical: '/screen/getRegisterStatistical',
        getGmvStatistical: '/screen/getGmvStatistical',
        getOldAndNewUserRate: '/screen/getOldAndNewUserRate',
        getOrder: '/screen/getOrder',
      },
      regTarget: 0,
      surplusRegTarget: 0,
      dayNeedFinishTarget: 0,
      regTargetFinishRatio: 0,
      surplusGmv: 0,
      targetFinishRatio: 0,
      regConvertRatio: 0,
      curDayFinishGmv: 0,
      dayFinishTargetGmv: 0,
      curDayFinishReg: 0,
      dayFinishTargetReg: 0,
      newUserOrderNum: 0,
      oldUserOrderNum: 0,
      newUserOrderRatio: 0,
      oldUserOrderRatio: 0,
      newUserGmv: 0,
      oldUserGmv: 0,
      newUserGmvRatio: 0,
      oldUserGmvRatio: 0,
      newOldUserDayOrderInfoList: [],
      params: {
        tenantId: '1',
        appName: 'shopee',
      },
      timeList: timeList,
      lastDays: '',
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    setInterval(() => {
      this.loadData()
    }, 300000)
  },
  watch: {
    lastDays(n,o) {
      if (o!=='') {
        this.loadData()
      }
    }
  },
  methods: {
    getTimeText(time) {
      let date = new Date(time)
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return year + "-" + month.toString().padStart(2, '0') + "-" + day.toString().padStart(2, '0')
    },
    getRegisterAndGMVCompleteFunc() {
      axios.all([getAction(this.urls.getRegisterComplete, this.params), getAction(this.urls.getRegisterStatistical, this.params), getAction(this.urls.getGmvStatistical, this.params), getAction(this.urls.getOldAndNewUserRate, this.params)]).then(axios.spread((res1, res2, res3, res4) => {
        this.loadRegisterData(res2)
        this.loadGmvData(res3)
        this.loadOrderOption1Data(res3)
        if (res1.result || res1.code == 200) {
          this.regTarget = res1.completeDto.registerTarget || 0
          this.surplusRegTarget = this.regTarget - this.monthRegNum < 0 ? 0 : this.regTarget - this.monthRegNum
          this.dayNeedFinishTarget = res1.completeDto.registerTargetByDay || 0
          this.regTargetFinishRatio = Number((this.monthRegNum/this.regTarget * 100).toFixed(2))
          this.surplusData = res1.completeDto.gmvTarget - this.monthTotalGmv < 0 ? 0 : Math.ceil(res1.completeDto.gmvTarget - this.monthTotalGmv)
          this.targetFinishRatio = Number((this.monthTotalGmv/res1.completeDto.gmvTarget * 100).toFixed(2))
          this.curDayFinishGmv = this.curDayGmv
          let nowTime = new Date()
          let year = nowTime.getFullYear();
          let month = nowTime.getMonth() + 1;
          let nextMonth = month + 1 > 12 ? 1 : (month + 1)
          let nextYear = month + 1 > 12 ? (year + 1) : year
          let nextMonthTime = new Date(nextYear + '-' + nextMonth + '-01 00:00:00')
          let surplusDay = Math.ceil((nextMonthTime.getTime() - nowTime.getTime())/86400/1000)
          this.dayFinishTargetGmv = !surplusDay ? 0 : Math.ceil(this.surplusData/surplusDay)
          this.curDayFinishReg = this.curDayRegNum
          this.dayFinishTargetReg = !surplusDay ? 0 : Math.ceil(this.surplusRegTarget/surplusDay)
        }
        if (res4.result || res4.code == 200) {
          this.newUserOrderNum = res4.oldAndNewUserDto.newUserCount || 0
          this.oldUserOrderNum = res4.oldAndNewUserDto.oldUserCount || 0
          this.newUserGmv = res4.oldAndNewUserDto.newUserPrice || 0
          this.oldUserGmv = res4.oldAndNewUserDto.oldUserPrice || 0
          this.newOldUserDayOrderInfoList = res4.oldAndNewUserDto.userList || []
          this.regConvertRatio = this.monthRegNum ? Number((res4.oldAndNewUserDto.newUserCount/this.monthRegNum * 100).toFixed(2)) : 0
          this.loadGmvRatioOption()
          this.loadOrderRatio()
          this.loadUser()
        }
      }))
    },
    thousandConvert,
    loadData() {
      this.getRegisterAndGMVCompleteFunc()
      this.loadOrderHistoryData()
    },
    suplyData(lastDays, allDays, month, date, time, week) {
      this.lastDays = lastDays;
      this.month = parseInt(month)
      this.dayRatio = parseInt(((allDays - lastDays) / allDays) * 100)
      this.date = date
      this.time = time
      this.week = week
    },
    afterAutoResizeMixinInit() {
      const { initConfig, setAppScale } = this
      initConfig()
      setAppScale()
      this.ready = true
    },
    initConfig() {
      const { dom, refCharts } = this
      const { width, height } = screen
      this.allWidth = width
      this.allHeight = height
      dom.style.width = `${width}px`
      dom.style.height = `${height}px`
      this.blscle = this.allHeight / 864
      let layoutDoms = [
        'top-header',
        'bottom-contral',
        'body-central',
        'box-1',
        'box-2',
        'box-3',
        'box-4',
        'box-5',
        'box-6',
        'box-7',
        'box-8',
        'box-9',
        'btn-process',
      ]
      let btnProcess = this.$refs['btn-process']
      // this.$refs['btn-process'].style.transform = `scale(${this.blscle})`;
      this.$refs['process'].style.marginTop = 20 * this.blscle + 'px'
      this.$refs['center-title'].style.lineHeight = this.$refs['top-header'].offsetHeight * this.blscle + 'px'
      this.$refs['radio-box'].style.width = 400 * this.blscle + 'px'
      this.$refs['radio-box'].style.padding = [20 * this.blscle + 'px', 0].join(' ')
      btnProcess.style.borderWidth = ['5px', 170 * this.blscle + 'px'].join(' ')
      layoutDoms.forEach((fkye) => {
        if (this.$refs[fkye]) {
          if (this.$refs[fkye].$el) {
            this.$refs[fkye].$el.style.height = this.$refs[fkye].$el.offsetHeight * this.blscle + 'px'
          } else {
            this.$refs[fkye].style.height = this.$refs[fkye].offsetHeight * this.blscle + 'px'
          }
        }
      })
      this.chart9Height = this.chart9Height * this.blscle
      this.chart4Height = this.chart4Height * this.blscle
      refCharts.forEach((key) => {
        if (this.$refs[key]) {
          this.$refs[key].$el.style.height = this.$refs[key].$el.offsetHeight * this.blscle + 'px'
          // console.log(this.$refs[key].$el.offsetHeight)
        }
      })
      this.$refs['scroll'].onResizeWidth(this.$refs['box-9'].$el.offsetWidth)
    },
    setAppScale() {
      const { allWidth, dom, refCharts } = this
      const currentWidth = document.body.clientWidth
      dom.style.transform = `scale(${currentWidth / allWidth})`

      refCharts.forEach((key) => {
        if (this.$refs[key]) {
          this.$refs[key].chart.resize()
        }
      })
    },
    handleChangeApp(val) {
      // this.appId = val
      // this.loadData()
    },
    loadOrderHistoryData() {
      getAction(this.urls.getOrder, this.params)
        .then((res) => {
          if (res.success || res.code == 200) {
            let config = {
              index: true,
              align: 'center',
              columnWidth: [30],
              waitTime: '5000',
              oddRowBGC: 'transparent',
              evenRowBGC: 'transparent',
              data: [],
            }
            config.data = (res.orderList || []).map((v) => {
              return [
                v.packageName,
                '<div style="text-align: right;">￥' + v.price + '</div>',
                '<div style="opacity: 0.5;text-align:right">' + (v.payTime||'').replace('T', ' ').split(' ')[0] + '</div>',
              ]
            })
            this.config = config
          }
        })
        .finally(() => {})
    },
    onResize() {
      const { setAppScale } = this
      setAppScale()
    },
  },
}
</script>
<style lang="scss" scoped>
body,
html {
  padding: 0;
  margin: 0;
  overflow-y: auto !important;
  overflow-x: auto !important;
}
h1 {
  font-size: 2em;
}
.big-screen-container-box {
  width: 100vw;
  height: 100vh;
  background-color: #0d1149;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  overflow-y: auto !important;
  &::-webkit-scrollbar{
      width:0px;
      height:0px;
      display: none;
    }
}
#big-screen-container {
  background-color: #0d1149;
  background-image: url(https://image.mobduos.com/upload/image/manager/2021/01/22/d53c65aa-5806-41ed-a1e3-1353a94c4915.png);
  background-size: 100% 100%;
  -webkit-box-shadow: 0 0 3px #00f;
  box-shadow: 0 0 3px #00f;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  -webkit-transform-origin: left top;
  transform-origin: left top;
  z-index: 999;
  padding: 0 10px;
}

.top-header {
  position: relative;
  height: 62px;
  .center-title {
    color: #fff;
    text-align: center;
  }
  .left-weather {
    position: absolute;
    bottom: 7px;
    left: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #fff;
    align-items: center;
  }
  .right-weather {
    position: absolute;
    bottom: 0;
    right: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    color: #fff;
    opacity: 0.5;
    font-size: 12px;
    font-weight: 400;
  }
}

.screen-body {
  .body-central {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    .central-left-container,
    .central-center-container,
    .central-right-container {
      flex: 1;
      .register-box {
        height: 280px;
      }
      .register-ok-box {
        height: 240px;
      }
    }
    .central-center-container {
      flex: 1.5;
      .center-box {
        height: 525px;
      }
    }
    .central-right-container {
      .real-time-box {
        height: 250px;
      }
      .order-total-box {
        height: 270px;
      }
    }
  }
  .bottom-contral {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: flex-start;
    .bottom-central-left-container,
    .bottom-central-right-container {
      .user-order-box,
      .order-come-box {
        height: 250px;
      }
    }
    .bottom-central-left-container {
      flex: 2;
    }
    .bottom-central-right-container {
      flex: 1;
    }
  }
  .register-total {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    .sande-box {
      color: #fff;
      opacity: 0.5;
      font-size: 12px;
    }
  }
}
.header-target {
  .remaining-display {
    position: relative;
  }
  .remaining-display::before,
  .remaining-display::after {
    position: absolute;
    top: 50%;
    margin-top: -81px;
    display: block;
    content: '';
    left: 15px;
    width: 65px;
    height: 162px;
    background: url(https://image.mobduos.com/upload/image/manager/2021/01/23/bac5c2d4-75e4-4049-9650-9fa1641e81c4.png)
      no-repeat;
    background-size: cover;
  }
  .remaining-display::after {
    transform: rotateY(180deg);
    left: auto;
    right: 15px;
  }
}
.header-tip {
  text-align: center;
  flex: 1;
}
#app-change {
  /deep/ .ant-select-selection {
    background: #10165f;
    border: 1px solid #0e3d9d;
    color: #3db8ff;
  }
  /deep/ .ant-select-arrow {
    color: #3db8ff;
  }
}

/deep/ .progress > .ant-progress-inner > .ant-progress-circle {
  transform: rotateY(180deg);
}
/deep/ .progress > .ant-progress-inner > .ant-progress-circle > .ant-progress-circle-trail {
  stroke: #7974748a !important;
  opacity: 0.7;
}

.progress-info {
  display: flex;
  flex: 1;
  color: #fff;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .progress-title {
    word-break: keep-all;
    text-indent: 5px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    width: 100%;
    opacity: 0.4;
  }
  .progress-label {
    text-indent: 5px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    width: 100%;
    font-weight: bold;
  }
}

.all-process {
  margin-top: 20px;
  display: flex;
  .cg-progcess {
    position: relative;

    .btn-process {
      position: relative;
      border: 150px solid;
      border-image: url(https://image.mobduos.com/upload/image/manager/2021/01/25/9758f483-4a92-4088-a03f-a6ceb421cfaa.png);
      border-width: 5px 170px;
      border-image-slice: 0 202;
      height: 150px;
      width: 70px;
      margin: 0 auto;
      display: flex;
      text-align: center;
      box-sizing: content-box;
      justify-content: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      font-size: 16px;
      color: #fff;
      .cg-tetxt {
        opacity: 0.5;
      }
      .cg-icon,
      .cg-right-icon {
        display: inline-block;
        position: absolute;
        width: 30px;
        top: 50%;
        left: -35px;
        // background: #fff;
        height: 30px;
        margin-top: -10px;
      }
      .cg-right-icon {
        left: auto;
        right: -35px;
      }
    }
  }
  .cg-progcess-left,
  .cg-progcess-right {
    position: relative;
    flex: 1;
    .top-info {
      position: relative;
      top: 0;
    }
    .bottom-info {
      position: absolute;
      bottom: 0;
    }
  }
  .cg-progcess-left {
    text-align: right;
    padding-right: 5px;
    .bottom-info {
      text-align: right;
      width: 100%;
      padding-right: 5px;
    }
  }
}
</style>
