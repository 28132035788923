var chartOption1 = {
    grid: {
        bottom: 40
    },
    tooltip: {
        trigger: 'axis',
        axisPointer: {            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        },
        position: function(point, params, dom, rect, size) {
            dom.style.transform = "translateZ(0)";
        },
        formatter: function (params) {
            let str = params[0].name + '<br/>';
            params.forEach(item => {
                if (item.seriesName == '每日销售额') {
                    str += item.marker + item.seriesName + ': ' + salesConvert(item.data) + '<br/>';
                } else if (item.seriesName == "较昨日流水") {
                    str += item.marker + item.seriesName + ': ' + item.data + '%';
                }
            });
            return str;
        }
    },
    legend: {
        right: 0,
        top: 10,
        itemWidth: 15,  // 设置宽度
        itemHeight: 10, // 设置高度
        textStyle: {
            color: "#ffffff82",
            fontSize: 10
        },
        data: [
            {
                name:'每日销售额',
            },
            {
                icon:'image://https://image.mobduos.com/upload/image/manager/2021/01/29/52cf30c8-c713-45c8-acc9-9dddf7b0fdf4.png',//格式为'image://+icon文件地址'，其中image::后的//不能省略
                name:'较昨日流水',
                textStyle:{
                    fontSize:12,
                    fontWeight:'bolder',
                    
                },
            }
        ]
        //#5C99FE
    },
    xAxis: {
        type: 'category',
        data: ['12-01',
            '12-02',
            '12-03',
            '12-04',
            '12-05',
            '12-06',
            '12-07',
            '12-08',
            '12-09',
            '12-10',
            '12-11',
            '12-12',
            '12-13',
            '12-14',
            '12-15',
            '12-16',
            '12-17',
            '12-18',
            '12-19',
            '12-20',
            '12-21',
            '12-22',
            '12-23',
            '12-24',
            '12-25',
            '12-26',
            '12-27',
            '12-28',
            '12-29',
            '12-30'
        ],
        name: '日期',
        axisLine: {
            lineStyle: '#141E6CFF'
        },
        axisLabel: {
            color: '#ffffff82',
        },

    },
    yAxis: [
        {
            type: 'value',
            namep: '每日销售额',
            min: 0,
            axisLabel: {
                color: '#ffffff82',
                formatter: function (value, index) {
                    return salesConvert(value);
                },
                fontStyle: 'oblique'
            },
            axisTick: {
                show: false
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: ['#141E6C'],
                    width: 1,
                    type: 'solid'
                }
            }
        },
        {
            type: 'value',
            namep: '较昨日流水',
            min: 'dataMin',
            axisLabel: {
                color: '#ffffff82',
                formatter: '{value}%',
                fontStyle: 'oblique'
            },
            axisTick: {
                show: false
            },
            splitLine: {
                //横分隔线不展示
                show: false
            }
        }
    ],
    series: [

        {
            name: '每日销售额',
            data: [],
            type: 'bar',
            areaStyle: {},
            smooth: true,
            color: '#5ee1c6',
            yAxisIndex: 0,
            barWidth: 8,
            itemStyle: {
                color: "#37E8FA"
            }
        },
        {
            name: '较昨日流水',
            data: [],
            type: 'line',
            smooth: true,
            color: '#5C99FE',
            yAxisIndex: 1,
            areaStyle: {
                opacity: 0.8,
                color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                        offset: 0, color: '#058BF3' // 0% 处的颜色
                    }, {
                        offset: 1, color: 'transparent' // 100% 处的颜色
                    }],
                    globalCoord: false // 缺省为 false
                }

            },
        }
    ]
}
import { salesConvert, accAdd } from './util'


export default {
    data() {
        return {
            registerOption2: {},
            gmvOptionUrl: '/business-screen/gmvPanelDataStatistic',
            monthTotalGmv: 0,
            curDayGmv: 0,
            comparePreDayGmv: 0
        }
    },
    watch: {

    },
    methods: {
        loadGmvData(res) {
            if (res.success || res.code == 200) {
                let gmvList = JSON.parse(JSON.stringify(res.gmvList || []))
                let _gmvList = []
                for (let item of this.timeList) {
                    let obj = {
                        time: item,
                        gmv: 0,
                        orderCount: 0,
                    }
                    for (let i in gmvList) {
                        if (gmvList[i].time == item) {
                            obj.gmv =  gmvList[i].gmv 
                            obj.orderCount =  gmvList[i].orderCount
                            gmvList.splice(i,1)
                        }
                    }
                    _gmvList.push(obj)
                }
                gmvList = _gmvList
                this.monthTotalGmv = gmvList.slice(1).reduce((s,v) => accAdd(s, Number(v.gmv || 0)), 0);
                this.curDayGmv = gmvList.slice(-1)[0] ? (gmvList.slice(-1)[0].gmv || 0) : 0;
                let lastGmv = gmvList.slice(-2, -1)[0] ? (gmvList.slice(-2, -1)[0].gmv || 0) : 0;
                this.comparePreDayGmv = lastGmv ? ((this.curDayGmv - lastGmv)/lastGmv * 100).toFixed(2) : 0;
                if (gmvList.length) {
                    chartOption1.xAxis.data = gmvList.slice(1).map(a => a.time.replace(/^\d{4}-/, ''));
                    chartOption1.series[0].data = gmvList.slice(1).map(a => a.gmv);
                    chartOption1.series[1].data = gmvList.map((a, i) => i==0 ? 0 : (gmvList[i-1].gmv ? ((a.gmv - gmvList[i-1].gmv)/gmvList[i-1].gmv).toFixed(2) : 0)).slice(1);
                    if(gmvList.length - 1 > 15){
                        chartOption1.dataZoom = {
                            type: 'slider',
                            realtime: false, //拖动滚动条时是否动态的更新图表数据
                            height: 6,//滚动条高度
                            // start: 70,//滚动条开始位置（共100等份）
                            // end: 100,//结束位置（共100等份）
                            startValue: gmvList.length - 16,
                            endValue: gmvList.length - 2,
                            bottom: 0,
                            // minSpan: 30,
                            // maxSpan: 30,
                            fillerColor: '#314B79FF',
                            backgroundColor: '#091D6FFF',
                            borderColor: 'rgba(255, 255, 255, 0)'
                        }
                    }
                    this.registerOption2 = chartOption1;
                }
                
            }
        }
    }
}
