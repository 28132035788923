<template>
  <div style="display: flex;justify-content: space-between;">
    <div style="display: flex;text-align: center">
      <div v-for="(item,index) in list":key="index" v-if="$store.getters.userInfo.id.startsWith('M')" style="width: 158px;height: 35px;line-height: 35px;cursor: pointer;font-size: 12px;border-radius: 6px;"
           :style="{marginLeft: index != 0 ? '15px' : '0px',background: item.path == currentPath ? '#788afc' : '#ffffff',boxShadow: item.path == currentPath ? '0 0 10px #788afc' : '0 0 0px #788afc',color: item.path == currentPath ? '#ffffff': '#000000'}"  @click="goToRoute(item)">
        <span>{{item.meta.title}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      list: [],
      currentPath: this.$route.path
    }
  },
  methods:{
    goToRoute(path){
      this.$router.push({
        path: path.path
      })
    }
  },
  watch:{
    currentPath(){
      let  newRoute = this.$router.getRoutes().filter(x => x.path.indexOf(this.$route.path.split("/")[1]) != -1).filter(x => x.parent != undefined);
      this.list = newRoute;
      // 使用 afterEach 钩子监听路由切换完成事件
      this.$router.afterEach((to, from) => {
        this.currentPath = to.path; // 更新 currentPath
      });
    }
  },
  mounted() {
    let  newRoute = this.$router.getRoutes().filter(x => x.path.indexOf(this.$route.path.split("/")[1]) != -1).filter(x => x.parent != undefined);
    this.list = newRoute;
    // 使用 afterEach 钩子监听路由切换完成事件
    this.$router.afterEach((to, from) => {
      this.currentPath = to.path; // 更新 currentPath
    });
  }
}
</script>

<style scoped>

</style>
