<template>
  <div class="haeder" :ref="ref">
    <h2 class="header-title" :style="{'fontSize': fontSize + 'px'}">{{title}}</h2>
  </div>
</template>

<script>
import autoResize from '../autoResize';
import { deepMerge,deepClone } from '../util'

export default {
  name: 'BoxHeader',
  mixins: [autoResize],
  props: {
    title: {
      type: String,
      default: '清输入标题'
    },
    fontSize: {
        type: String,
        default: "18"
    },
    backgroundColor: {
      type: String,
      default: 'transparent'
    }
  },
  data () {
    return {
      ref: 'border-box',
      border: ['left-top', 'right-top', 'left-bottom', 'right-bottom'],
      defaultColor: ['#4fd2dd', '#235fa7'],
      mergedColor: []
    }
  },
  watch: {
    color () {
      const { mergeColor } = this
      mergeColor()
    }
  },
  methods: {
    mergeColor () {
      const { color, defaultColor } = this
      this.mergedColor =  deepMerge(deepClone(defaultColor, true), color || {})
    },
    afterAutoResizeMixinInit(){
      let { dom, blscle } = this;
      let heightSize = dom.offsetHeight *  blscle;
       dom.style.height = heightSize + 'px';
       dom.style.lineHeight = heightSize + 'px';
    }
  },
  mounted () {
    const { mergeColor } = this
    mergeColor()
  }
}
</script>

<style lang="scss">
.haeder {
    text-align: center;
    .header-title{
        font-size: 18px;
        position: relative;
        display: inline-block;
        color: #3DB8FFFF;
    }
    .header-title:before {
        position: absolute;
        top: 50%;
        left: -57px;
        margin-top: -3px;
        display: block;
        content: '';
        width: 52px;
        height: 6px;
        background-image: url(https://image.mobduos.com/upload/image/manager/2021/01/23/d24f4388-c89c-459e-af5d-d7db4c290c73.png);
    }
    .header-title:after {
        position: absolute;
        top: 50%;
        right: -57px;
        margin-top: -3px;
        display: block;
        content: '';
        width: 52px;
        height: 6px;
        transform:rotateY(180deg);
        background-image: url(https://image.mobduos.com/upload/image/manager/2021/01/23/d24f4388-c89c-459e-af5d-d7db4c290c73.png);
    }
}
</style>