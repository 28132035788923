var chartOption1 = {

    title: {
        show: true,
        text: '50%\t\n已完成',
        x: 'center',
        y: 'center',
        textStyle: {
            fontSize: '15',
            color: 'white',
            fontWeight: 'normal'
        }
    },
    tooltip: {
        trigger: 'item',
        formatter: "{d}%",
        show: false
    },
    legend: {
        orient: 'vertical',
        x: 'left',
        show: false
    },
    series: {
        name: '',
        type: 'pie',
        radius: ['65%', '85%'],
        avoidLabelOverlap: true,
        hoverAnimation: false,
        label: {
            normal: {
                show: false,
                position: 'center'
            },
            emphasis: {
                show: false
            }
        },
        labelLine: {
            normal: {
                show: false
            }
        },
        data: [{
                value: 80,
                name: ''
            },
            {
                value: 100 - 20,
                name: ''
            }
        ]
    }

};
export default {
    data() {
        return {
            cirqueOption2: chartOption1
        }
    },
    watch: {

    },
    methods: {

    }
}