import http from '@/libs/axios';

let pro = process.env.VUE_APP_BASE_API

/**
 * 代理商登录
 * @param data
 * @returns {*}
 */
export function adminLogin(data){
    return http({
        url: `${pro}/v1/user/agentLogin`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 发送短信验证码
 * @param query
 * @returns {*}
 */
export function sendSms(query){
    return http({
        url: `${pro}/v1/user/sendMessage`,
        method: 'get',
        params: query
    })
}

/**
 * 新增代理商
 * @param data
 * @returns {*}
 */
export function addAgent(data){
    return http({
        url: `${pro}/v1/user/addAgent`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 查询代理商列表
 * @param data
 * @returns {*}
 */
export function agentList(data){
    return http({
        url: `${pro}/v1/user/list`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * 删除代理商
 * @param id
 * @returns {*}
 */
export function delAgent(id){
    return http({
        url: `${pro}/v1/user/delAgent?id=${id}`,
        method: 'post'
    })
}

/**
 * 删除代理商
 * @param id
 * @returns {*}
 */
export function aliPayCode(data){
    return http({
        url: `${pro}/v1/public/apliayCode`,
        method: 'post',
        requestBody: true,
        data
    })
}

/**
 * ocr识别
 * @param url
 * @param type
 * @returns {*}
 */
export function uploadFileOcr(url,type){
    return http({
        url: `${pro}/v1/upload?url=${url}&type=${type}`,
        method: 'post'
    })
}

/**
 * 获取类目
 * @param url
 * @param type
 * @returns {*}
 */
export function getMcc(type){
    return http({
        url: `${pro}/v1/public/getMcc?type=${type}`,
        method: 'get'
    })
}

/**
 * 获取所有银行
 * @param url
 * @param type
 * @returns {*}
 */
export function getBrankName(city){
    return http({
        url: `${pro}/v1/public/getBrankNameList?city=${city}`,
        method: 'get'
    })
}
/**
 * 获取指定城市的指定银行的支行
 * @param url
 * @param type
 * @returns {*}
 */
export function getSubBrankList(city,brankName){
    return http({
        url: `${pro}/v1/public/getSubBrankList?city=${city}&brankName=${brankName}`,
        method: 'get'
    })
}

/**
 * 获取代理商利润
 * @param url
 * @param type
 * @returns {*}
 */
export function getMoney(id){
    return http({
        url: `${pro}/v1/user/getMoney?id=${id}`,
        method: 'get'
    })
}