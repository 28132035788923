export function randomExtend(minNum, maxNum) {
    if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10)
    } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
    }
}

export function debounce(delay, callback) {
    let lastTime

    return function () {
        clearTimeout(lastTime)

        const [that, args] = [this, arguments]

        lastTime = setTimeout(() => {
            callback.apply(that, args)
        }, delay)
    }
}

export function observerDomResize(dom, callback) {
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver

    const observer = new MutationObserver(callback)

    observer.observe(dom, {
        attributes: true,
        attributeFilter: ['style'],
        attributeOldValue: true
    })

    return observer
}

export function getPointDistance(pointOne, pointTwo) {
    const minusX = Math.abs(pointOne[0] - pointTwo[0])

    const minusY = Math.abs(pointOne[1] - pointTwo[1])

    return Math.sqrt(minusX * minusX + minusY * minusY)
}

export function uuid(hasHyphen) {
    return (hasHyphen ? 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx' : 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx').replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0
        const v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}

/**
 * 深拷贝
 * @param {*} target 要深拷贝的值
 */
// eslint-disable-next-line
export function deepClone (obj) {
    const cache = arguments.length > 1 && 
    arguments[1] !== undefined 
        && typeof obj !== 'object' ? arguments[1] : new Map([])
    if (obj === null || typeof obj !== 'object') return obj
  
    if (cache.has(obj)) return cache.get(obj)
  
    // eslint-disable-next-line
    const clone = Array.isArray(obj) ? [] : {}
    cache.set(obj, clone)
  
    // @ts-ignore
    Object.keys(obj).forEach(key => (clone[key] = deepClone(obj[key], cache)))
  
    return clone
  }
  
/**
 * 深度合并
 * @param {*} target 
 * @param {*} merged 
 */
export function deepMerge(target, merged) {
    for (var key in merged) {
        if (target[key] && typeof target[key] === 'object') {
            deepMerge(target[key], merged[key])
            continue
        }

        if (typeof merged[key] === 'object') {
            target[key] = deepClone(merged[key], true)
            continue
        }
        target[key] = merged[key]
    }
    return target
}


export function salesConvert(sales) {
    sales = Number(sales);
	if (sales == 0) {
		return sales + '';
	} else
	if (sales > 1 && sales < 10000) {
		return sales + '';
	} else {
		return (sales / 10000).toFixed(2) + '万';
	}
}


export function thousandConvert(num) {
    if(!num || num == 0){
        return 0;
    }
    return num.toString().replace(/\d+/, function (n) { // 先提取整数部分
        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) { // 对整数部分添加分隔符
            return $1 + ",";
        });
    });
}

export function accAdd(arg1,arg2){ // 浮点数加法
    var r1,r2,m; 
    try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0} 
    try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0} 
    m=Math.pow(10,Math.max(r1,r2)) 
    return (arg1*m+arg2*m)/m 
}