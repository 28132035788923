<template>
  <div :style="{background: (path !== '/login' && path !== '/pay' && path !== '/authPay' && path !== '/h5-piece' && path !== '/wxPay') ? '#f4f7fd' : '#ffffff'}" ref="containerS">
    <div id="app" v-if="path !== '/login' && path !== '/pay' && path !== '/authPay' && path !== '/h5-piece' && path !== '/wxPay'">
      <el-container>
        <el-aside width="200px" style="max-height: 1050px;">
          <left-menu></left-menu>
        </el-aside>
        <el-container style="width: 100%;">
          <el-header style="padding: 0px;">
            <Header/>
          </el-header>
          <el-main style="padding-top: 0px;">
            <el-main style="padding: 0px;">
              <router-view/>
            </el-main>
          </el-main>
        </el-container>
      </el-container>
    </div>
    <div id="app2" v-else>
      <router-view/>
    </div>
  </div>
</template>

<script>
import LeftMenu from "@/views/components/LeftMenu";
import Header from "@/views/components/Header";
import autofit from "autofit.js"
export default {
  name: 'App',
  components:{
    LeftMenu,
    Header
  },
  data(){
    return{
      path: this.$route.path
    }
  },
  watch:{
    $route(to,from){
      this.$nextTick(function(){
        let userInfo = this.$store.getters.userInfo;
        // watermark.set(userInfo ? userInfo.name : "", this.$refs.containerS);
      })
    }
  },
  mounted(){
    autofit.init(
        {
          dh: 1080,
          dw: 1920,
          el: "#app",
          resize: true
        },
        false
    )
    this.$nextTick(function(){
      let userInfo = this.$store.getters.userInfo;
      // watermark.set(userInfo ? userInfo.name : "", this.$refs.containerS);
    })
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f4f7fd;
  margin: 0 auto;
}
#app2 {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0 auto;
}

</style>
