<template>
  <el-card shadow="never" :body-style="{padding: '0px',height: '40px',lineHeight: '40px'}"
           style="border: 0px;border-left: 1px solid #f4f7fd">
    <el-popover
        placement="bottom-end"
        width="250"
        trigger="hover">
      <div style="padding: 10px;">
        <div style="display: flex;justify-content: space-between;align-items: center;height: 10px;">
          <div style="display: flex;align-items: center;">
            <el-avatar size="small" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
            <span style="margin-left: 5px;font-size: 12px;">{{ $store.state.userInfo.name }}</span>
          </div>
          <span @click="out" style="font-size: 12px;color: #969799;cursor: pointer;">退出</span>
        </div>
        <div style=" width: 100%;height: 1px;margin-top:20px;background: #f7f7fa;"></div>
        <div style="display: flex;justify-content: space-between;font-size: 12px;margin-top: 15px;">
          <span style="cursor: pointer;" class="ht">
            <img src="../../assets/header/qb.png" style="width: 20px;height: 20px;">
            <span>钱包</span>
          </span>
          <span style="cursor: pointer;" class="ht">
            <img src="../../assets/header/sjtj.png" style="width: 15px;height: 15px;">
            <span style="margin-top: 5px;margin-left: 3px;">数据统计</span>
          </span>
        </div>
        <div style="display: flex;justify-content: space-between;font-size: 12px;margin-top: 10px;">
          <span style="cursor: pointer;" class="ht">
            <img src="../../assets/header/flpz.png" style="width: 15px;height: 15px;">
            <span style="margin-top: 2px;margin-left: 3px;">费率配置</span>
          </span>
          <span style="cursor: pointer;" class="ht">
            <img src="../../assets/header/qbls.png" style="width: 15px;height: 15px;">
            <span style="margin-top: 4px;margin-left: 3px;">钱包流水</span>
          </span>
        </div>
        <div style=" width: 100%;height: 1px;margin-top:20px;background: #f7f7fa;"></div>
        <div style="display: flex;justify-content: space-between;font-size: 12px;margin-top: 15px;">
          <span style="cursor: pointer;" class="ht">
            <img src="../../assets/header/grxx.png" style="width: 15px;height: 15px;">
            <span style="margin-top: 1px;margin-left: 3px;">个人信息</span>
          </span>
          <span style="cursor: pointer;" class="ht">
            <img src="../../assets/header/xtsz.png" style="width: 15px;height: 15px;">
            <span style="margin-top: 1px;margin-left: 3px;">系统设置</span>
          </span>
        </div>
      </div>
      <div slot="reference" style="display:flex;align-items: center;float:right; margin-right: 20px;cursor: pointer;">
        <el-avatar size="small" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
        <span style="margin-left: 5px;font-size: 14px;">{{ $store.state.userInfo.name }}</span>
      </div>
    </el-popover>
  </el-card>
</template>
<script>
import {mapMutations} from "vuex";

export default {
  name: "Header",
  methods:{
    ...mapMutations(["logout"]),
    out(){
      this.logout();
      location.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-popover{
  top: 100px;
}
.ht{
  display: flex;
  align-items: center;
}
.ht:hover{
    color: #788afc;
}
</style>