<template>
  <div>
    <el-card shadow="never">
      <el-form :model="agentForm" ref="mchForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="" prop="name">
          <el-input clearable size="small" placeholder="代理商号" style="width: 200px;margin-left: 20px;"
                    v-model="agentForm.agentNo"></el-input>
        </el-form-item>
        <el-form-item label="" prop="name">
          <el-input clearable size="small" placeholder="代理商名称" style="width: 200px;margin-left: 20px;"
                    v-model="agentForm.agentName">
          </el-input>
        </el-form-item>
        <el-form-item label="" prop="name">
          <el-input clearable size="small" placeholder="代理商登录名称" style="width: 200px;margin-left: 20px;"
                    v-model="agentForm.agentLoginName"></el-input>
        </el-form-item>
        <el-form-item label="" prop="name">
          <el-select size="small" v-model="agentForm.agentStatus" placeholder="提现状态"
                     style="margin-left: 20px;">
            <el-option label="全部" :value="-1"></el-option>
            <el-option label="禁用" :value="1"></el-option>
            <el-option label="启用" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="margin-left: 40px;">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="initData">查询</el-button>
          <el-button type="default" icon="el-icon-refresh-right" size="mini" @click="queryRest">重置</el-button>
        </el-form-item>
      </el-form>
      <el-divider/>
      <div style="margin-top: 20px;">
        <el-button type="primary" icon="el-icon-plus" size="mini"
                   class="blink-button" @click="addDrawerFlag=true">新建
        </el-button>
        <el-table
            height="697"
            v-loading="loading"
            element-loading-text="拼命加载中..."
            :header-cell-style="{backgroundColor: '#fafafa'}"
            :data="tableData"
            :cell-style="{backgroundColor: '#ffffff'}"
            style="width: 100%;margin-top: 20px;">
          <el-table-column type="expand">
            <template slot-scope="props">
              <el-form label-position="left" inline class="demo-table-expand">
                <el-form-item label="代理商号:">
                  <span>{{ props.row.id }}</span>
                </el-form-item>
                <el-form-item label="代理商名称:">
                  <span>{{ props.row.agentName }}</span>
                </el-form-item>
                <el-form-item label="登录名:">
                  <span>{{ props.row.loginName }}</span>
                </el-form-item>
                <el-form-item label="代理商简称:">
                  <span>{{ props.row.agentFor }}</span>
                </el-form-item>
                <el-form-item label="联系人姓名:">
                  <span>{{ props.row.name }}</span>
                </el-form-item>
                <el-form-item label="联系人手机号:">
                  <span>{{ props.row.phone }}</span>
                </el-form-item>
                <el-form-item label="状态:">
                  <el-tag size="small" :type="props.row.status == 0 ? 'success' : 'danger'">
                    {{ props.row.status == 0 ? '启用' : '禁用' }}
                  </el-tag>
                </el-form-item>
              </el-form>
            </template>
          </el-table-column>
          <el-table-column
              prop="agentName">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">代理商名称</span>
            </template>
            <template slot-scope="{row}">
              <span style="font-weight: bolder;color: #788afc;">{{ row.agentName }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="id">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">代理商号</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="rate">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">代理商费率</span>
            </template>
            <template slot-scope="{row}">
              <span>{{ row.rate}}{{row.rate ? '%' : ''}}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="status">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">状态</span>
            </template>
            <template slot-scope="{row}">
              <div style="display: flex;">
                <el-badge is-dot></el-badge>
                <span style="margin-left: 5px;">{{ row.status == 0 ? '启用' : '禁用' }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
              prop="createTime">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal">创建日期</span>
            </template>
            <template slot-scope="{row}">
              <span style="margin-left: 5px;">{{ row.createTime.replace("T", " ") }}</span>
            </template>
          </el-table-column>
          <el-table-column
              align="center">
            <template slot="header" slot-scope="scope">
              <span style="font-weight: normal;">操作</span>
            </template>
            <template slot-scope="{row}">
              <el-button style="margin-left: 20px;" type="text" @click="update(row)">修改</el-button>
              <el-popconfirm
                  title="确定删除吗？"
                  @confirm="delAgent(row.id)"
              >
                <el-button  slot="reference" style="margin-left: 20px;" type="text">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <pagination
            :total="total"
            :pageNum.sync="pageNum"
            :limit.sync="pageSize"
            @pagination="initData()"
        />
      </div>
    </el-card>

    <!--  新增/修改商户弹窗  -->
    <el-drawer
        :title="!isUpdate ? '新增代理商' : '修改代理商'"
        size="40%"
        :modal="false"
        :wrapperClosable="false"
        :close-on-press-escape="false"
        :visible.sync="addDrawerFlag"
        direction="rtl">
      <div class="drawer-content">
        <el-form :model="addAgentForm" :rules="addAgentFormRules" ref="addAgentForm" label-width="100px"
                 class="demo-ruleForm2">
          <el-form-item label="代理商名称" prop="agentName">
            <el-input clearable placeholder="商户名称" size="mini" v-model="addAgentForm.agentName"></el-input>
          </el-form-item>
          <el-form-item label="登录名" prop="loginName">
            <el-input clearable placeholder="登录名" size="mini" :disabled="isUpdate" v-model="addAgentForm.loginName"></el-input>
          </el-form-item>
          <el-form-item label="代理商简称" prop="agentFor">
            <el-input clearable placeholder="商户简称" size="mini" v-model="addAgentForm.agentFor"></el-input>
          </el-form-item>
          <el-form-item label="联系人姓名" prop="name">
            <el-input clearable placeholder="联系人姓名" size="mini" v-model="addAgentForm.name"></el-input>
          </el-form-item>
          <el-form-item label="联系人邮箱" prop="email">
            <el-input clearable placeholder="联系人邮箱" size="mini" v-model="addAgentForm.email"></el-input>
          </el-form-item>
          <el-form-item label="联系人手机号" prop="phone">
            <el-input clearable placeholder="联系人手机号" size="mini" v-model="addAgentForm.phone"></el-input>
          </el-form-item>
          <el-form-item label="费率设置" prop="rate">
            <el-input clearable placeholder="费率设置" size="mini" v-model="addAgentForm.rate">
              <template slot="suffix">
                <span style="color: #000000">%</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="费率设置" prop="rate" style="opacity: 0;">
            <el-input clearable placeholder="联系人手机号" size="mini" v-model="addAgentForm.rate"></el-input>
          </el-form-item>
          <el-form-item prop="mchLevel">
            <template slot="label">
              <span>状态</span>
            </template>
            <el-radio-group v-model="addAgentForm.status">
              <el-radio :label="0">启用</el-radio>
              <el-radio :label="1">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否允许发展下级" prop="status">
            <el-radio-group v-model="addAgentForm.isNext">
              <el-radio :label="0">是</el-radio>
              <el-radio :label="1">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="密码设置" prop="status">
            <el-radio-group v-model="passwordSettingsFlag">
              <el-radio :label="0">默认密码</el-radio>
              <el-radio :label="1">自定义密码</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="passwordSettingsFlag == 1" label="登录密码" prop="status">
            <el-input clearable placeholder="请输入登录密码" size="mini" v-model="addAgentForm.password"></el-input>
            <div>
              <el-button icon="el-icon-postcard" size="small" type="primary"
                         @click="addAgentForm.password = randomPassword(6)">随机生成密码
              </el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: center;margin-top: 8%;padding-bottom: 10px;" class="drawer-footer">
        <el-button style="width: 100px" icon="el-icon-check" size="small" type="primary"
                   @click="addAgent('addAgentForm')">
          保存
        </el-button>
        <el-button style="width: 100px" icon="el-icon-close" size="small" @click="closeDrawer">取消</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {addAgent, agentList, delAgent} from "@/api/user";
import Pagination from "@/components/Pagination/index.vue";

export default {
  name: "index",
  components: {Pagination},
  data() {
    return {
      isUpdate: false,
      addDrawerFlag: false,
      loading: false,
      pageSize: 10,
      pageNum: 1,
      passwordSettingsFlag: 0,
      total: 0,
      addAgentFormRules: {
        agentName: [
          {required: true, message: '请输入代理商名称', trigger: 'blur'}
        ],
        loginName: [
          {required: true, message: '请输入登录名', trigger: 'blur'}
        ],
        agentFor: [
          {required: true, message: '请输入代理商简称', trigger: 'blur'}
        ],
        name: [
          {required: true, message: '请输入联系人姓名', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入联系人手机号', trigger: 'blur'}
        ],
        rate: [
          {required: true, message: '请输入代理商费率', trigger: 'blur'}
        ]
      },
      addAgentForm: {
        agentName: '', // 代理商名称
        loginName: '', // 登录名称
        rate: '', // 费率设置
        agentFor: '', // 代理商简称
        name: '', // 联系人姓名
        phone: '', // 联系人手机号
        parentId: this.$store.getters.userInfo.id, // 上级代理商id
        status: 0, // 状态  0 启用 1禁用
        password: '123456', // 密码
        isNext: 0 // 0 是  1 否
      },
      tableData: [],
      agentForm: {
        agentNo: '',
        agentName: '',
        agentLoginName: '',
        agentStatus: -1
      }
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.initData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.initData();
    },
    update(obj){
      this.isUpdate = true;
      this.addDrawerFlag = true;
      this.addAgentForm = obj;
    },
    delAgent(id){
      delAgent(id).then(res =>{
        if (res.success){
          this.$message.success("删除成功")
          this.initData()
        }else{
          this.$message.success(res.errorMessage)
        }
      })
    },
    initData() {
      let param = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        id: this.agentForm.agentNo || null,
        agentName: this.agentForm.agentName || null,
        loginName: this.agentForm.agentLoginName || null,
        status: this.agentForm.agentStatus === -1 ? null : this.agentForm.agentStatus

      }
      this.loading = true;
      agentList(param).then(res => {
        if (res.success) {
          this.loading = false;
          this.tableData = res.data.list;
          this.total = res.data.total
        }
      })
    },
    closeDrawer() {
      this.addDrawerFlag = false;
      if (this.isUpdate){
        this.isUpdate = false;
      }
      this.addAgentForm = {
        agentName: '', // 代理商名称
        loginName: '', // 登录名称
        rate: '', // 费率设置
        agentFor: '', // 代理商简称
        name: '', // 联系人姓名
        phone: '', // 联系人手机号
        parentId: this.$store.getters.userInfo.id, // 上级代理商id
        status: 0, // 状态  0 启用 1禁用
        password: '123456', // 密码
        isNext: 0 // 0 是  1 否
      }
    },
    addAgent(formName) {
      if (formName == 'addAgentForm') {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            addAgent(this.addAgentForm).then(res => {
              this.$message.success(!this.isUpdate ? "新增成功" : "修改成功")
              this.addDrawerFlag = false;
              if (this.isUpdate){
                this.isUpdate = false;
              }
              this.initData();
            })
          }
        })
      }
    },
    randomPassword(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    },
    queryRest() {
      this.agentForm = {
        agentNo: '',
        agentName: '',
        agentLoginName: '',
        agentStatus: -1
      }
      this.initData();
    }
  }
}
</script>

<style scoped>
.blink-button:hover {
  background-image: linear-gradient(to right, rgb(250, 82, 82), rgb(250, 82, 82) 16.65%, rgb(190, 75, 219) 16.65%, rgb(190, 75, 219) 33.3%, rgb(76, 110, 245) 33.3%, rgb(76, 110, 245) 49.95%, rgb(64, 192, 87) 49.95%, rgb(64, 192, 87) 66.6%, rgb(250, 176, 5) 66.6%, rgb(250, 176, 5) 83.25%, rgb(253, 126, 20) 83.25%, rgb(253, 126, 20) 100%, rgb(250, 82, 82) 100%);
  animation: var(--timing) linear dance6123 infinite;
  transform: scale(1.1) translateY(-1px);
}

.blink-button {
  --width: 73px;
  --timing: 2s;
  border: 0;
  width: var(--width);
  color: #fff;
  transition: all 0.2s;
}

@keyframes dance6123 {
  to {
    background-position: var(--width);
  }
}

.drawer-content {
  padding-bottom: 40px;
  max-height: calc(100vh - 100px); /* Adjust this value as needed */
  overflow-y: auto;
}

.drawer-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 17%;
}

.demo-ruleForm2 {
  display: flex;
  flex-flow: wrap;
}

.demo-ruleForm2 .el-form-item {
  display: flex;
  margin: 20px;
  width: 280px;
  height: 45px;
  flex-direction: column;
  text-align-last: start;
}

/deep/ .demo-ruleForm2 .el-form-item .el-form-item__content {
  margin-left: 0px !important;
}

/deep/ .demo-ruleForm2 .el-form-item .el-form-item__label {
  height: 30px !important;
  width: 128px !important;
}

.demo-ruleForm {
  display: flex;
}

/deep/ .demo-ruleForm .el-form-item__content {
  margin-left: 0px !important;
}
</style>