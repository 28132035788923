<template>
  <div>
  </div>
</template>

<script>


export default {
  name: "wxPay",
  data(){
    return{

    }
  },
  created() {
    if (this.$route.query.url){
      window.location.href = this.$route.query.url
    }
  }
}
</script>

<style lang="scss" scoped>
</style>